import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  LinearProgress,
  useTheme,
} from '@mui/material';
import { Science as ScienceIcon } from '@mui/icons-material';

interface NeurochemicalsTableProps {
  data: {
    serotonin: number;
    dopamine: number;
    norepinephrine: number;
    gaba: number;
    glutamate: number;
    acetylcholine: number;
    endorphins: number;
  };
}

const NeurochemicalsTable: React.FC<NeurochemicalsTableProps> = ({ data }) => {
  const theme = useTheme();

  const neurochemicals = [
    { name: 'Serotonin', value: data.serotonin, description: 'Mood, sleep, and appetite regulation' },
    { name: 'Dopamine', value: data.dopamine, description: 'Reward, motivation, and pleasure' },
    { name: 'Norepinephrine', value: data.norepinephrine, description: 'Alertness and energy' },
    { name: 'GABA', value: data.gaba, description: 'Calming and anxiety reduction' },
    { name: 'Glutamate', value: data.glutamate, description: 'Learning and memory' },
    { name: 'Acetylcholine', value: data.acetylcholine, description: 'Memory and muscle function' },
    { name: 'Endorphins', value: data.endorphins, description: 'Pain relief and well-being' },
  ];

  return (
    <Card sx={{ height: '100%', minHeight: 480 }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          color: theme.palette.text.primary 
        }}>
          <ScienceIcon color="primary" />
          Neurochemicals
        </Typography>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Neurochemical</TableCell>
                <TableCell>Level</TableCell>
                <TableCell>Function</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {neurochemicals.map((item) => (
                <TableRow key={item.name}>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <LinearProgress
                        variant="determinate"
                        value={item.value}
                        sx={{
                          width: 100,
                          height: 8,
                          borderRadius: 4,
                          backgroundColor: theme.palette.grey[200],
                          '& .MuiLinearProgress-bar': {
                            borderRadius: 4,
                          },
                        }}
                      />
                      <Typography variant="body2">
                        {item.value}%
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2" color="text.secondary">
                      {item.description}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </Card>
  );
};

export default NeurochemicalsTable;

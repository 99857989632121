import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
  onSnapshot,
  getDoc,
} from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { Notification, NotificationType } from '../types/notification';

const NOTIFICATIONS_COLLECTION = 'notifications';

export const notificationService = {
  // Send a new notification to a user
  async sendNotification(
    userId: string,
    adminId: string,
    title: string,
    message: string,
    type: NotificationType,
    link?: string
  ): Promise<string> {
    try {
      // First verify that the user exists
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (!userDoc.exists()) {
        console.error('User not found:', userId);
        throw new Error('User not found');
      }

      // Then verify that the admin has proper permissions
      const adminRef = doc(db, 'users', adminId);
      const adminDoc = await getDoc(adminRef);
      
      if (!adminDoc.exists()) {
        console.error('Admin not found:', adminId);
        throw new Error('Admin not found');
      }
      
      const adminData = adminDoc.data();
      if (adminData.role !== 'admin') {
        console.error('User does not have admin privileges:', adminId);
        throw new Error('Unauthorized: Admin privileges required');
      }

      // Create notification data object, only including link if it has a value
      const notificationData = {
        userId,
        adminId,
        title,
        message,
        type,
        createdAt: Timestamp.fromDate(new Date()),
        isRead: false,
        ...(link ? { link } : {}),  // Only include link if it exists
      };

      console.log('Creating notification with data:', notificationData);
      const docRef = await addDoc(
        collection(db, NOTIFICATIONS_COLLECTION),
        notificationData
      );
      console.log('Notification created successfully with ID:', docRef.id);

      return docRef.id;
    } catch (error) {
      console.error('Error sending notification:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to send notification: ${error.message}`);
      } else {
        throw new Error('Failed to send notification: Unknown error');
      }
    }
  },

  // Get all notifications for a user
  async getUserNotifications(userId: string): Promise<Notification[]> {
    try {
      const q = query(
        collection(db, NOTIFICATIONS_COLLECTION),
        where('userId', '==', userId),
        orderBy('createdAt', 'desc')
      );

      const querySnapshot = await getDocs(q);
      return querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt.toDate(),
          readAt: data.readAt ? data.readAt.toDate() : undefined,
        } as Notification;
      });
    } catch (error) {
      console.error('Error getting user notifications:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to get user notifications: ${error.message}`);
      } else {
        throw new Error('Failed to get user notifications: Unknown error');
      }
    }
  },

  // Mark a notification as read
  async markAsRead(notificationId: string): Promise<void> {
    try {
      const notificationRef = doc(db, NOTIFICATIONS_COLLECTION, notificationId);
      await updateDoc(notificationRef, {
        isRead: true,
        readAt: Timestamp.fromDate(new Date()),
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
      if (error instanceof Error) {
        throw new Error(`Failed to mark notification as read: ${error.message}`);
      } else {
        throw new Error('Failed to mark notification as read: Unknown error');
      }
    }
  },

  // Subscribe to user notifications (real-time updates)
  subscribeToUserNotifications(
    userId: string,
    callback: (notifications: Notification[]) => void
  ) {
    console.log('Setting up notification subscription for user:', userId);
    
    const q = query(
      collection(db, NOTIFICATIONS_COLLECTION),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    return onSnapshot(
      q,
      (querySnapshot) => {
        console.log('Received notification update, document count:', querySnapshot.size);
        
        const notifications = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          console.log('Processing notification document:', doc.id, data);
          
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt.toDate(),
            readAt: data.readAt ? data.readAt.toDate() : undefined,
          } as Notification;
        });
        
        console.log('Processed notifications:', notifications);
        callback(notifications);
      },
      (error) => {
        console.error('Error in notification subscription:', error);
      }
    );
  },
};

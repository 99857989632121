import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../../firebase/firebaseConfig';
import { UserHealthData } from '../../types/healthData';

interface AddUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const AddUserDialog: React.FC<AddUserDialogProps> = ({ open, onClose, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState<'user' | 'admin'>('user');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      // Create user in Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Create user document in Firestore
      const timestamp = new Date().toISOString();
      const userData: UserHealthData = {
        userId: user.uid,
        email,
        name,
        createdAt: timestamp,
        lastLogin: timestamp,
        updatedAt: timestamp,
        lastUpdated: timestamp,
        approved: true,
        approvalStatus: 'Approved',
        role: role as 'admin' | 'user',
        personalInfo: {
          firstName: name.split(' ')[0] || '',
          lastName: name.split(' ').slice(1).join(' ') || '',
          email,
          age: 0,
          gender: '',
          height: '',
          weight: '',
          joinDate: timestamp,
        },
        membership: {
          type: 'Basic',
          status: 'active',
          startDate: new Date().toISOString(),
          expiryDate: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString(),
          daysRemaining: 30,
          nextLabTest: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
          nextMonthlyBatch: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
        },
        notifications: [],
        agebackScore: {
          overall: 0,
          biological: 0,
          lifestyle: 0,
          environmental: 0,
          metabolic: 0,
          improvement: 0,
          chronological: 0
        },
        agebackTrend: {
          labels: [],
          datasets: [
            {
              label: 'Ageback Score',
              data: []
            }
          ]
        },
        healthRisks: {
          shortTerm: [],
          midTerm: [],
          longTerm: []
        },
        bloodPanel: {
          hemoglobin: 0,
          whiteBloodCells: 0,
          platelets: 0,
          redBloodCells: 0,
          hematocrit: 0,
          mcv: 0,
          mch: 0,
          mchc: 0,
        },
        hormonePanel: {
          // Growth, Development, and Maturation
          growthHormone: 0,
          igf1: 0,
          thyroidHormones: 0,

          // Metabolic Regulation
          insulin: 0,
          glucagon: 0,
          leptin: 0,

          // Stress and Emergency Response
          cortisol: 0,
          adrenaline: 0,
          noradrenaline: 0,

          // Reproductive Hormones
          testosterone: 0,
          estrogen: 0,
          progesterone: 0,

          // Homeostasis and Maintenance
          parathyroidHormone: 0,
          aldosterone: 0,
          vasopressin: 0,

          // Local and Specialized Functions
          melatonin: 0,
          calcitonin: 0,
          somatostatin: 0,

          // Legacy or additional hormones
          dhea: 0
        },
        toxicityLevels: {
          hepatotoxicity: 0,
          immunotoxicity: 0,
          nephrotoxicity: 0,
          neurotoxicity: 0,
          reproductiveToxicity: 0,
          respiratoryToxicity: 0,
        },
        epigeneticsProfile: {
          methylation: 0,
          telomeres: 0,
          histones: 0,
          chromatin: 0,
        },
        metabolicSystems: {
          digestive: 0,
          immune: 0,
          nervous: 0,
          circulatory: 0,
          respiratory: 0,
          endocrine: 0,
          lymphatic: 0,
          musculoskeletal: 0
        },
        viMiAmEn: {
          vitamins: {
            A: 0,
            B: 0,
            C: 0,
            D: 0,
            E: 0,
            K: 0
          },
          minerals: {
            calcium: 0,
            magnesium: 0,
            zinc: 0,
            iron: 0,
            potassium: 0
          },
          aminoAcids: {
            leucine: 0,
            isoleucine: 0,
            valine: 0,
            lysine: 0,
            methionine: 0
          },
          enzymes: {
            digestive: 0,
            metabolic: 0,
            antioxidant: 0,
            repair: 0
          }
        },
        neurochemicals: {
          serotonin: 0,
          dopamine: 0,
          norepinephrine: 0,
          gaba: 0,
          glutamate: 0,
          acetylcholine: 0,
          endorphins: 0,
        },
        medicalHistory: {
          conditions: [],
        },
        healthGoals: [],
        agebackScores: {}, // Adding the required agebackScores field
      };

      await Promise.all([
        // Save user health data
        setDoc(doc(db, 'users', user.uid), userData),
        // Save user role
        setDoc(doc(db, 'roles', user.uid), {
          role,
          email,
          approved: true,
          approvalStatus: 'approved',
          updatedAt: timestamp
        }),
      ]);

      onSuccess();
      handleClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create user');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setEmail('');
    setPassword('');
    setName('');
    setRole('user');
    setError(null);
    onClose();
  };

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as 'user' | 'admin');
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle>Add New User</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            <TextField
              label="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <TextField
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              required
              margin="normal"
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Role</InputLabel>
              <Select value={role} label="Role" onChange={handleRoleChange}>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="admin">Admin</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading ? 'Creating...' : 'Create User'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddUserDialog;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  alpha,
} from '@mui/material';
import {
  Science as ScienceIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartTooltip,
  Filler,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartTooltip,
  Filler,
  Legend
);

interface EpigeneticsData {
  methylation: number;
  telomeres: number;
  histones: number;
  chromatin: number;
}

interface EpigeneticsAreaChartProps {
  data: EpigeneticsData;
}

const EpigeneticsAreaChart: React.FC<EpigeneticsAreaChartProps> = ({ data }) => {
  const theme = useTheme();

  // Generate time points for X-axis (e.g., last 6 months)
  const months = Array.from({ length: 6 }, (_, i) => {
    const d = new Date();
    d.setMonth(d.getMonth() - (5 - i));
    return d.toLocaleString('default', { month: 'short' });
  });

  // Simulate historical data based on current values
  const generateHistoricalData = (currentValue: number) => {
    return Array.from({ length: 6 }, (_, i) => {
      const baseValue = currentValue * (0.85 + Math.random() * 0.3);
      return Math.round(baseValue * 10) / 10;
    });
  };

  // Custom colors for each epigenetic marker
  const colors = {
    methylation: '#8E44AD', // Purple for DNA methylation (molecular modification)
    telomeres: '#27AE60',   // Green for telomeres (growth/regeneration)
    histones: '#E67E22',    // Orange for histones (protein/structural)
    chromatin: '#3498DB'    // Blue for chromatin (DNA packaging)
  };

  const chartData: ChartData<'line'> = {
    labels: months,
    datasets: [
      {
        label: 'Chromatin Structure',
        data: generateHistoricalData(data.chromatin),
        borderColor: colors.chromatin,
        backgroundColor: alpha(colors.chromatin, 0.5),
        fill: true,
        tension: 0.4,
      },
      {
        label: 'Histone Modifications',
        data: generateHistoricalData(data.histones),
        borderColor: colors.histones,
        backgroundColor: alpha(colors.histones, 0.5),
        fill: true,
        tension: 0.4,
      },
      {
        label: 'Telomere Length',
        data: generateHistoricalData(data.telomeres),
        borderColor: colors.telomeres,
        backgroundColor: alpha(colors.telomeres, 0.5),
        fill: true,
        tension: 0.4,
      },
      {
        label: 'DNA Methylation',
        data: generateHistoricalData(data.methylation),
        borderColor: colors.methylation,
        backgroundColor: alpha(colors.methylation, 0.5),
        fill: true,
        tension: 0.4,
      },
    ],
  };

  const chartOptions: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: theme.palette.text.secondary,
        },
      },
      y: {
        stacked: true,
        grid: {
          color: alpha(theme.palette.text.secondary, 0.1),
        },
        ticks: {
          callback: function(value) {
            return value + '%';
          },
          color: theme.palette.text.secondary,
        },
        min: 0,
        max: 100,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            return context.dataset.label + ': ' + context.formattedValue + '%';
          },
        },
      },
      legend: {
        position: 'top' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 15,
          color: theme.palette.text.primary,
        },
      },
    },
  };

  return (
    <Card sx={{ 
      height: '100%',
      minHeight: 480,
      borderRadius: 2,
      boxShadow: theme.shadows[3],
      '&:hover': {
        boxShadow: theme.shadows[6],
        transform: 'translateY(-2px)',
        transition: 'all 0.3s'
      }
    }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          color: theme.palette.text.primary
        }}>
          <ScienceIcon color="secondary" />
          Epigenetics Profile
        </Typography>
        <Box sx={{ height: 384 }}>
          <Line data={chartData} options={chartOptions} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EpigeneticsAreaChart;

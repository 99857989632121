import React from 'react';
import { Container } from '@mui/material';
import UserManagement from '../../components/admin/UserManagement';

const UserManagementPage: React.FC = () => {
  return (
    <Container maxWidth="xl">
      <UserManagement />
    </Container>
  );
};

export default UserManagementPage;
import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import LegalPageHeader from '../../components/legal/LegalPageHeader';

const Terms: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper sx={{ p: 4 }}>
          <LegalPageHeader title="Terms of Service" />
          <Divider sx={{ my: 4 }} />

          <Typography variant="subtitle1" color="text.secondary" paragraph>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="h5" gutterBottom>
            1. Agreement to Terms
          </Typography>
          <Typography paragraph>
            By accessing or using the Ageback Health Program, you agree to be bound by these Terms of Service and all applicable laws and regulations.
          </Typography>

          <Typography variant="h5" gutterBottom>
            2. Use License
          </Typography>
          <Typography paragraph>
            We grant you a limited, non-exclusive, non-transferable license to use the Ageback Health Program for personal, non-commercial purposes. This license is subject to these Terms of Service.
          </Typography>

          <Typography variant="h5" gutterBottom>
            3. User Accounts
          </Typography>
          <Typography paragraph>
            You are responsible for:
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>Maintaining the confidentiality of your account</li>
            <li>All activities that occur under your account</li>
            <li>Notifying us immediately of any unauthorized use</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            4. Service Modifications
          </Typography>
          <Typography paragraph>
            We reserve the right to:
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>Modify or discontinue any part of our service</li>
            <li>Change fees or charges for using our service</li>
            <li>Update these terms at any time</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            5. Limitation of Liability
          </Typography>
          <Typography paragraph>
            Ageback Health Program and its affiliates shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use the service.
          </Typography>

          <Typography variant="h5" gutterBottom>
            6. Disclaimer of Warranties
          </Typography>
          <Typography paragraph>
            The service is provided "as is" and "as available" without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability or fitness for a particular purpose.
          </Typography>

          <Typography variant="h5" gutterBottom>
            7. Governing Law
          </Typography>
          <Typography paragraph>
            These Terms shall be governed by and construed in accordance with the laws of the United States and the State of California, without regard to its conflict of law provisions.
          </Typography>

          <Typography variant="h5" gutterBottom>
            8. Contact Information
          </Typography>
          <Typography paragraph>
            For any questions about these Terms, please contact us at:
          </Typography>
          <Typography paragraph>
            Email: legal@ageback.com<br />
            Address: 123 Health Street, Suite 100<br />
            San Francisco, CA 94105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default Terms;

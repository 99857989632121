import React from 'react';
import { Container, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import HealthScoreChart from '../../components/admin/analytics/HealthScoreChart';
import UserEngagementMetrics from '../../components/admin/analytics/UserEngagementMetrics';

interface HealthScoreData {
  month: string;
  averageScore: number;
  totalUsers: number;
}

interface UserData {
  id: string;
  healthData?: {
    agebackScore: number;
    lastUpdated: string;
  };
  personalInfo?: {
    joinDate: string;
  };
  lastLogin?: string;
  appointments?: {
    date: string;
    status: string;
  }[];
  status: 'active' | 'inactive';
}

const AnalyticsPage: React.FC = () => {
  const [healthScoreData, setHealthScoreData] = React.useState<HealthScoreData[]>([]);
  const [engagementMetrics, setEngagementMetrics] = React.useState({
    activeUsers: 0,
    activeUsersChange: 0,
    averageSessionTime: 0,
    sessionTimeChange: 0,
    completionRate: 0,
    completionRateChange: 0,
    totalAppointments: 0,
    appointmentsChange: 0,
  });
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);

  React.useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        setLoading(true);
        setError(null);

        // Fetch all users
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const users: UserData[] = usersSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        } as UserData));

        console.log('Fetched users:', users); // Debug log

        // Calculate monthly averages
        const monthlyData = await calculateMonthlyAverages(users);
        console.log('Monthly data:', monthlyData); // Debug log

        setHealthScoreData(monthlyData);

        // Calculate engagement metrics
        const metrics = await calculateEngagementMetrics(users);
        console.log('Engagement metrics:', metrics); // Debug log

        setEngagementMetrics(metrics);
      } catch (error) {
        console.error('Error fetching analytics data:', error);
        setError('Failed to load analytics data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchAnalyticsData();
  }, []);

  const calculateMonthlyAverages = async (users: UserData[]): Promise<HealthScoreData[]> => {
    const now = new Date();
    const monthsData: HealthScoreData[] = [];
    
    // Calculate data for the last 6 months
    for (let i = 5; i >= 0; i--) {
      const targetMonth = new Date(now.getFullYear(), now.getMonth() - i, 1);
      const monthEnd = new Date(targetMonth.getFullYear(), targetMonth.getMonth() + 1, 0);
      
      // Filter users who joined before or during this month
      const monthUsers = users.filter(user => {
        if (!user.personalInfo?.joinDate) return false;
        const joinDate = new Date(user.personalInfo.joinDate);
        return joinDate <= monthEnd;
      });

      // Calculate average health score for the month
      const monthScores = monthUsers
        .map(user => user.healthData?.agebackScore || 0)
        .filter(score => score > 0);

      const averageScore = monthScores.length > 0
        ? Math.round(monthScores.reduce((a, b) => a + b, 0) / monthScores.length)
        : 0;

      monthsData.push({
        month: targetMonth.toLocaleString('default', { month: 'short' }),
        averageScore,
        totalUsers: monthUsers.length // Use the actual number of users for this month
      });
    }

    return monthsData;
  };

  const calculateEngagementMetrics = async (users: UserData[]) => {
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - (30 * 24 * 60 * 60 * 1000));
    const sixtyDaysAgo = new Date(now.getTime() - (60 * 24 * 60 * 60 * 1000));

    // Calculate active users (users who logged in within the last 30 days)
    const activeUsers = users.filter(user => {
      if (!user.lastLogin) return false;
      const lastLogin = new Date(user.lastLogin);
      return lastLogin >= thirtyDaysAgo;
    }).length;

    // Calculate active users from previous 30-day period for comparison
    const previousActiveUsers = users.filter(user => {
      if (!user.lastLogin) return false;
      const lastLogin = new Date(user.lastLogin);
      return lastLogin >= sixtyDaysAgo && lastLogin < thirtyDaysAgo;
    }).length;

    // Calculate active users change percentage
    const activeUsersChange = previousActiveUsers > 0
      ? Math.round(((activeUsers - previousActiveUsers) / previousActiveUsers) * 100)
      : 0;

    // Calculate appointments for current month
    const currentMonthAppointments = users.reduce((total, user) => {
      if (!user.appointments) return total;
      const monthAppointments = user.appointments.filter(apt => {
        const aptDate = new Date(apt.date);
        return aptDate >= thirtyDaysAgo && aptDate <= now;
      });
      return total + monthAppointments.length;
    }, 0);

    // Calculate appointments for previous month
    const previousMonthAppointments = users.reduce((total, user) => {
      if (!user.appointments) return total;
      const monthAppointments = user.appointments.filter(apt => {
        const aptDate = new Date(apt.date);
        return aptDate >= sixtyDaysAgo && aptDate < thirtyDaysAgo;
      });
      return total + monthAppointments.length;
    }, 0);

    const appointmentsChange = previousMonthAppointments > 0
      ? Math.round(((currentMonthAppointments - previousMonthAppointments) / previousMonthAppointments) * 100)
      : 0;

    // Calculate program completion rate (users with health score >= 80)
    const completedUsers = users.filter(user => 
      user.healthData?.agebackScore && user.healthData.agebackScore >= 80
    ).length;

    const completionRate = users.length > 0
      ? Math.round((completedUsers / users.length) * 100)
      : 0;

    // For now, use placeholder values for metrics that need additional tracking
    const completionRateChange = 0; // Would need historical completion rate data
    const averageSessionTime = 0; // Would need session duration tracking
    const sessionTimeChange = 0; // Would need historical session duration data

    return {
      activeUsers,
      activeUsersChange,
      averageSessionTime,
      sessionTimeChange,
      completionRate,
      completionRateChange,
      totalAppointments: currentMonthAppointments,
      appointmentsChange,
    };
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper sx={{ p: 3, bgcolor: 'error.light', color: 'error.contrastText' }}>
          <Typography>{error}</Typography>
        </Paper>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Analytics Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserEngagementMetrics metrics={engagementMetrics} />
        </Grid>

        <Grid item xs={12}>
          <HealthScoreChart data={healthScoreData} />
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Top Health Improvements
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Feature coming soon...
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Program Completion Rates
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Feature coming soon...
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AnalyticsPage;

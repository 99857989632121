import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Paper,
  Box,
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  People as PeopleIcon,
  TrendingUp as TrendingUpIcon,
  Assignment as AssignmentIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';

const AdminDashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [metrics, setMetrics] = useState({
    totalUsers: 0,
    activeUsers: 0,
    pendingTasks: 0,
    userChange: 0
  });

  useEffect(() => {
    const fetchMetrics = async () => {
      try {
        setLoading(true);
        
        // Fetch all users
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const totalUsers = usersSnapshot.size;

        // Calculate user change (users who joined in the last month)
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        
        const newUsersQuery = query(
          collection(db, 'users'),
          where('personalInfo.joinDate', '>=', oneMonthAgo)
        );
        const newUsersSnapshot = await getDocs(newUsersQuery);
        const newUsers = newUsersSnapshot.size;
        
        const userChange = totalUsers > 0 
          ? Math.round((newUsers / totalUsers) * 100)
          : 0;

        // Fetch active users (logged in within last week)
        const oneWeekAgo = new Date();
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        
        const activeUsersQuery = query(
          collection(db, 'users'),
          where('lastLogin', '>=', oneWeekAgo)
        );
        const activeUsersSnapshot = await getDocs(activeUsersQuery);
        const activeUsers = activeUsersSnapshot.size;

        // Fetch pending tasks (appointments or assessments)
        const tasksRef = collection(db, 'tasks');
        const pendingTasksQuery = query(
          tasksRef,
          where('status', '==', 'pending')
        );
        const pendingTasksSnapshot = await getDocs(pendingTasksQuery);
        const pendingTasks = pendingTasksSnapshot.size;

        setMetrics({
          totalUsers,
          activeUsers,
          pendingTasks,
          userChange
        });
      } catch (error) {
        console.error('Error fetching metrics:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetrics();
  }, []);

  const dashboardItems = [
    {
      title: 'Total Users',
      value: loading ? <CircularProgress size={20} /> : metrics.totalUsers.toString(),
      icon: <PeopleIcon sx={{ fontSize: 40, color: 'primary.main' }} />,
      change: `${metrics.userChange >= 0 ? '+' : ''}${metrics.userChange}% from last month`
    },
    {
      title: 'Active Sessions',
      value: loading ? <CircularProgress size={20} /> : metrics.activeUsers.toString(),
      icon: <TrendingUpIcon sx={{ fontSize: 40, color: 'success.main' }} />,
      change: 'Active this week'
    },
    {
      title: 'Pending Tasks',
      value: loading ? <CircularProgress size={20} /> : metrics.pendingTasks.toString(),
      icon: <AssignmentIcon sx={{ fontSize: 40, color: 'warning.main' }} />,
      change: 'Requires attention'
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Admin Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        {dashboardItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={item.title}
              />
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box>
                    <Typography variant="h4" component="div">
                      {item.value}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                      {item.change}
                    </Typography>
                  </Box>
                  {item.icon}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <Typography color="text.secondary">
              No recent activity to display.
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminDashboard;

import { db } from '../firebase/firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { UserHealthData } from '../types/healthData';

const users = [
  {
    userId: 'Qg2xxfSrnfXW5Y8I3XKkbd4pA1A3',
    email: 'admin@ageback.com',
    role: 'admin',
    name: 'Admin User',
  }
];

export const initializeDatabase = async () => {
  try {
    for (const user of users) {
      const nameParts = user.name.split(' ');
      const firstName = nameParts[0];
      const lastName = nameParts.slice(1).join(' ');
      
      const userData: UserHealthData = {
        userId: user.userId,
        email: user.email,
        name: user.name,
        lastUpdated: new Date().toISOString(),
        role: user.role as 'admin' | 'user',
        approved: true,  // Set to true for the initial users
        approvalStatus: 'approved',
        personalInfo: {
          firstName: firstName,
          lastName: lastName,
          email: user.email,
          age: 30,
          gender: 'Male',
          height: '180cm',
          weight: '80kg',
          joinDate: new Date().toISOString(),
        },
        membership: {
          type: 'Premium',
          status: 'active',
          startDate: new Date().toISOString(),
          expiryDate: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(),
          daysRemaining: 365,
          nextLabTest: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
          nextMonthlyBatch: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
        },
        notifications: [],
        agebackScore: {
          overall: 85,
          biological: 75,
          lifestyle: 90,
          environmental: 85,
          metabolic: 80,
          improvement: 5,
          chronological: 30
        },
        agebackScores: {
          score2023: { high: 85, low: 75 },
          score2024: { high: 88, low: 78 }
        },
        agebackTrend: {
          labels: ['2020', '2021', '2022', '2023', '2024', '2025'],
          datasets: [{
            label: 'Ageback Score',
            data: [
              { year: '2020', highest: 85, lowest: 75 },
              { year: '2021', highest: 88, lowest: 78 },
              { year: '2022', highest: 91, lowest: 82 },
              { year: '2023', highest: 94, lowest: 85 },
              { year: '2024', highest: 96, lowest: 88 },
              { year: '2025', highest: 98, lowest: 90 }
            ],
          }],
        },
        healthRisks: {
          shortTerm: [
            { 
              risk: 'High Blood Pressure', 
              severity: 'High', 
              recommendation: 'Schedule urgent consultation with cardiologist and monitor blood pressure daily' 
            },
            { 
              risk: 'Elevated Cortisol', 
              severity: 'High', 
              recommendation: 'Reduce stress levels through meditation and implement stress management techniques' 
            },
            { 
              risk: 'Vitamin D Deficiency', 
              severity: 'Medium', 
              recommendation: 'Increase sun exposure and consider supplementation after consulting healthcare provider' 
            },
            { 
              risk: 'Poor Sleep Quality', 
              severity: 'Medium', 
              recommendation: 'Establish consistent sleep schedule and optimize bedroom environment' 
            },
            { 
              risk: 'Dehydration', 
              severity: 'Low', 
              recommendation: 'Increase daily water intake to at least 2 liters and monitor urine color' 
            },
            { 
              risk: 'Irregular Exercise', 
              severity: 'Low', 
              recommendation: 'Implement regular physical activity, starting with 30 minutes of walking daily' 
            }
          ],
          midTerm: [
            { risk: 'Sleep Quality', severity: 'Medium', recommendation: 'Improve sleep hygiene' }
          ],
          longTerm: [
            { risk: 'Cardiovascular', severity: 'Low', recommendation: 'Regular exercise' }
          ]
        },
        bloodPanel: {
          hemoglobin: 15.2,
          whiteBloodCells: 7.2,
          platelets: 250,
          redBloodCells: 5.1,
          hematocrit: 45,
          mcv: 88,
          mch: 29,
          mchc: 33,
        },
        hormonePanel: {
          // Growth, Development, and Maturation
          growthHormone: 60,
          igf1: 60,
          thyroidHormones: 60,

          // Metabolic Regulation
          insulin: 60,
          glucagon: 60,
          leptin: 60,

          // Stress and Emergency Response
          cortisol: 60,
          adrenaline: 60,
          noradrenaline: 60,

          // Reproductive Hormones
          testosterone: 60,
          estrogen: 60,
          progesterone: 60,

          // Homeostasis and Maintenance
          parathyroidHormone: 60,
          aldosterone: 60,
          vasopressin: 60,

          // Local and Specialized Functions
          melatonin: 60,
          calcitonin: 60,
          somatostatin: 60,

          // Legacy or additional hormones
          dhea: 60
        },
        toxicityLevels: {
          hepatotoxicity: 0.8,
          immunotoxicity: 0.7,
          nephrotoxicity: 0.6,
          neurotoxicity: 0.5,
          reproductiveToxicity: 0.4,
          respiratoryToxicity: 0.3,
        },
        epigeneticsProfile: {
          methylation: 0.85,
          telomeres: 0.82,
          histones: 0.78,
          chromatin: 0.75,
        },
        metabolicSystems: {
          digestive: 85,
          immune: 82,
          nervous: 88,
          circulatory: 86,
          respiratory: 84,
          endocrine: 83,
          lymphatic: 81,
          musculoskeletal: 87,
        },
        viMiAmEn: {
          vitamins: {
            A: 90,
            B: 85,
            C: 95,
            D: 80,
            E: 88,
            K: 92,
          },
          minerals: {
            calcium: 88,
            magnesium: 85,
            zinc: 90,
            iron: 82,
            potassium: 87,
          },
          aminoAcids: {
            leucine: 92,
            isoleucine: 88,
            valine: 85,
            lysine: 90,
            methionine: 86,
          },
          enzymes: {
            digestive: 85,
            metabolic: 88,
            antioxidant: 90,
            repair: 87,
          },
        },
        neurochemicals: {
          serotonin: 85,
          dopamine: 82,
          norepinephrine: 78,
          gaba: 80,
          glutamate: 83,
          acetylcholine: 85,
          endorphins: 88,
        },
        medicalHistory: {
          conditions: [],
        },
        healthGoals: [
          'Improve cardiovascular health',
          'Enhance cognitive function',
          'Optimize hormonal balance',
          'Strengthen immune system',
          'Reduce stress levels',
          'Optimize sleep quality',
        ],
      };

      // Force update by setting merge to false
      await setDoc(doc(db, 'users', user.userId), userData, { merge: false });
      console.log(`Created health data for ${user.email}`);
    }
    console.log('All health data created');
  } catch (error) {
    console.error('Error initializing database:', error);
  }
};

export interface ValidationError {
  [key: string]: string;
}

export const validateProfile = (values: any): ValidationError => {
  const errors: ValidationError = {};
  
  // First Name validation
  if (!values.firstName) {
    errors.firstName = 'First name is required';
  } else if (values.firstName.length < 2) {
    errors.firstName = 'First name must be at least 2 characters';
  }

  // Last Name validation
  if (!values.lastName) {
    errors.lastName = 'Last name is required';
  } else if (values.lastName.length < 2) {
    errors.lastName = 'Last name must be at least 2 characters';
  }

  // Phone validation
  if (values.phone && !/^\+?[\d\s-()]+$/.test(values.phone)) {
    errors.phone = 'Invalid phone number format';
  }

  // Date of Birth validation
  if (values.dateOfBirth) {
    const date = new Date(values.dateOfBirth);
    const now = new Date();
    if (isNaN(date.getTime())) {
      errors.dateOfBirth = 'Invalid date format';
    } else if (date > now) {
      errors.dateOfBirth = 'Date of birth cannot be in the future';
    }
  }

  // Height validation
  if (values.height) {
    const height = parseFloat(values.height);
    if (isNaN(height) || height <= 0) {
      errors.height = 'Height must be a positive number';
    } else if (height > 300) { // assuming cm
      errors.height = 'Height value seems too large';
    }
  }

  // Weight validation
  if (values.weight) {
    const weight = parseFloat(values.weight);
    if (isNaN(weight) || weight <= 0) {
      errors.weight = 'Weight must be a positive number';
    } else if (weight > 500) { // assuming kg
      errors.weight = 'Weight value seems too large';
    }
  }

  return errors;
};

export const validateSettings = (values: any): ValidationError => {
  const errors: ValidationError = {};

  // Timezone validation
  if (values.timezone && !/^[A-Za-z/_+-]+$/.test(values.timezone)) {
    errors.timezone = 'Invalid timezone format';
  }

  // Language validation
  if (values.language && !/^[a-z]{2}(-[A-Z]{2})?$/.test(values.language)) {
    errors.language = 'Invalid language format';
  }

  return errors;
};

export const validatePassword = (password: string): string[] => {
  const errors: string[] = [];

  if (password.length < 8) {
    errors.push('Password must be at least 8 characters long');
  }
  if (!/[A-Z]/.test(password)) {
    errors.push('Password must contain at least one uppercase letter');
  }
  if (!/[a-z]/.test(password)) {
    errors.push('Password must contain at least one lowercase letter');
  }
  if (!/[0-9]/.test(password)) {
    errors.push('Password must contain at least one number');
  }
  if (!/[!@#$%^&*]/.test(password)) {
    errors.push('Password must contain at least one special character (!@#$%^&*)');
  }

  return errors;
};

export const validateEmail = (email: string): string | null => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return 'Email is required';
  }
  if (!emailRegex.test(email)) {
    return 'Invalid email format';
  }
  return null;
};

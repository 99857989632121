import React, { useState, useEffect } from 'react';
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Alert,
  SelectChangeEvent,
} from '@mui/material';
import { notificationService } from '../../services/notificationService';
import { NotificationType } from '../../types/notification';
import { useAuth } from '../../contexts/AuthContext';

interface SendNotificationProps {
  userId: string;
}

const SendNotification: React.FC<SendNotificationProps> = ({ userId }) => {
  console.log('Rendering SendNotification for userId:', userId);
  
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState<NotificationType>('info');
  const [link, setLink] = useState('');
  const [status, setStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  useEffect(() => {
    console.log('SendNotification mounted/updated with userId:', userId);
    // Reset form when userId changes
    setTitle('');
    setMessage('');
    setType('info');
    setLink('');
    setStatus({ type: null, message: '' });
  }, [userId]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitting notification form');
    
    if (!currentUser) {
      setStatus({
        type: 'error',
        message: 'You must be logged in to send notifications',
      });
      return;
    }

    if (!title.trim() || !message.trim()) {
      setStatus({
        type: 'error',
        message: 'Title and message are required',
      });
      return;
    }

    try {
      console.log('Sending notification:', {
        userId,
        adminId: currentUser.uid,
        title,
        message,
        type,
        link: link.trim() || undefined,
      });

      await notificationService.sendNotification(
        userId,
        currentUser.uid,
        title.trim(),
        message.trim(),
        type,
        link.trim() || undefined
      );

      console.log('Notification sent successfully');
      setStatus({
        type: 'success',
        message: 'Notification sent successfully!',
      });

      // Reset form
      setTitle('');
      setMessage('');
      setType('info');
      setLink('');

      // Clear success message after 3 seconds
      setTimeout(() => {
        setStatus({ type: null, message: '' });
      }, 3000);
    } catch (error) {
      console.error('Error sending notification:', error);
      const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
      setStatus({
        type: 'error',
        message: errorMessage,
      });
    }
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as NotificationType);
  };

  return (
    <Box>
      {status.type && (
        <Alert severity={status.type} sx={{ mb: 2 }}>
          {status.message}
        </Alert>
      )}

      <Box component="form" onSubmit={handleSubmit} sx={{ '& > *': { mb: 2 } }}>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />

        <TextField
          fullWidth
          label="Message"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          multiline
          rows={4}
          required
        />

        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select value={type} label="Type" onChange={handleTypeChange} required>
            <MenuItem value="info">Info</MenuItem>
            <MenuItem value="warning">Warning</MenuItem>
            <MenuItem value="success">Success</MenuItem>
            <MenuItem value="error">Error</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Link (Optional)"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="e.g., /dashboard/profile"
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
        >
          Send Notification
        </Button>
      </Box>
    </Box>
  );
};

export default SendNotification;

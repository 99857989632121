import React from 'react';
import {
  Typography,
  Box,
  LinearProgress,
  useTheme,
  Tooltip,
  alpha
} from '@mui/material';
import { HormonePanel } from '../../../types/healthData';

interface HormoneGroupsChartProps {
  data: HormonePanel;
}

interface HormoneGroup {
  name: string;
  value: number;
  hormones: {
    name: string;
    key: keyof HormonePanel;
  }[];
  description: string;
}

const HormoneGroupsChart: React.FC<HormoneGroupsChartProps> = ({ data }) => {
  const theme = useTheme();

  // Helper function to safely get hormone value
  const getHormoneValue = (key: keyof HormonePanel): number => {
    console.log(`Getting value for ${key}:`, {
      dataExists: !!data,
      rawValue: data?.[key],
      valueType: data?.[key] !== undefined ? typeof data[key] : 'undefined'
    });
    
    if (!data || data[key] === undefined || data[key] === null) {
      console.log(`No data for ${key}`);
      return 0;
    }
    
    const value = Number(data[key]); // Ensure we're working with numbers
    console.log(`Hormone ${key}:`, {
      originalValue: data[key],
      convertedValue: value,
      valueType: typeof value
    });
    
    return isNaN(value) ? 0 : value;
  };

  // Helper function to calculate group average
  const calculateGroupAverage = (group: string, hormones: Array<keyof HormonePanel>): number => {
    console.log(`\n=== Calculating average for ${group} ===`);
    let sum = 0;
    let validValues = 0;
    
    console.log('Input hormones:', hormones);
    
    for (const hormone of hormones) {
      const value = getHormoneValue(hormone);
      console.log(`${hormone}:`, {
        value,
        type: typeof value,
        isValid: value > 0
      });
      
      if (value > 0) {
        sum += value;
        validValues++;
      }
    }
    
    console.log('Calculation results:', {
      sum,
      validValues,
      average: validValues > 0 ? Math.round(sum / validValues) : 0
    });
    
    return validValues > 0 ? Math.round(sum / validValues) : 0;
  };

  console.log('\n=== HormoneGroupsChart Data ===');
  console.log('Raw hormone panel data:', data);

  const hormoneGroups: Record<string, (keyof HormonePanel)[]> = {
    Growth: ['growthHormone', 'igf1', 'thyroidHormones'],
    Metabolic: ['insulin', 'glucagon', 'leptin'],
    Stress: ['cortisol', 'adrenaline', 'noradrenaline'],
    Reproductive: ['testosterone', 'estrogen', 'progesterone'],
    Homeostasis: ['parathyroidHormone', 'aldosterone', 'vasopressin'],
    Local: ['melatonin', 'calcitonin', 'somatostatin']
  };

  // Display average of three hormones for each group
  const groups: HormoneGroup[] = [
    {
      name: 'Growth, Development, and Maturation',
      value: calculateGroupAverage('Growth', hormoneGroups.Growth),
      hormones: [
        { name: 'Growth Hormone', key: 'growthHormone' },
        { name: 'IGF-1', key: 'igf1' },
        { name: 'Thyroid Hormones', key: 'thyroidHormones' }
      ],
      description: 'Controls physical growth, tissue repair, and metabolic processes'
    },
    {
      name: 'Metabolic Regulation',
      value: calculateGroupAverage('Metabolic', hormoneGroups.Metabolic),
      hormones: [
        { name: 'Insulin', key: 'insulin' },
        { name: 'Glucagon', key: 'glucagon' },
        { name: 'Leptin', key: 'leptin' }
      ],
      description: 'Regulates blood sugar, energy storage, and appetite'
    },
    {
      name: 'Stress and Emergency Response',
      value: calculateGroupAverage('Stress', hormoneGroups.Stress),
      hormones: [
        { name: 'Cortisol', key: 'cortisol' },
        { name: 'Adrenaline', key: 'adrenaline' },
        { name: 'Noradrenaline', key: 'noradrenaline' }
      ],
      description: 'Manages stress response and adaptation to emergencies'
    },
    {
      name: 'Reproductive Hormones',
      value: calculateGroupAverage('Reproductive', hormoneGroups.Reproductive),
      hormones: [
        { name: 'Testosterone', key: 'testosterone' },
        { name: 'Estrogen', key: 'estrogen' },
        { name: 'Progesterone', key: 'progesterone' }
      ],
      description: 'Controls reproductive function and secondary sex characteristics'
    },
    {
      name: 'Homeostasis and Maintenance',
      value: calculateGroupAverage('Homeostasis', hormoneGroups.Homeostasis),
      hormones: [
        { name: 'Parathyroid Hormone', key: 'parathyroidHormone' },
        { name: 'Aldosterone', key: 'aldosterone' },
        { name: 'Vasopressin', key: 'vasopressin' }
      ],
      description: 'Maintains calcium balance and blood pressure'
    },
    {
      name: 'Local and Specialized Functions',
      value: calculateGroupAverage('Local', hormoneGroups.Local),
      hormones: [
        { name: 'Melatonin', key: 'melatonin' },
        { name: 'Calcitonin', key: 'calcitonin' },
        { name: 'Somatostatin', key: 'somatostatin' }
      ],
      description: 'Regulates sleep cycles and specialized metabolic functions'
    }
  ];

  // Colors for each group with better contrast
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  return (
    <Box>
      {groups.map((group, index) => (
        <Box key={group.name} sx={{ mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Tooltip
              title={
                <Box>
                  <Typography variant="body2" sx={{ fontWeight: 'bold', mb: 0.5 }}>
                    {group.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Key Hormones:
                  </Typography>
                  {group.hormones.map((hormone) => (
                    <Typography key={hormone.key} variant="caption" display="block" sx={{ ml: 1 }}>
                      • {hormone.name}: {getHormoneValue(hormone.key)}%
                    </Typography>
                  ))}
                </Box>
              }
              arrow
              placement="right"
            >
              <Typography variant="subtitle1" sx={{ 
                fontWeight: 'medium',
                cursor: 'help',
                '&:hover': {
                  color: colors[index]
                }
              }}>
                {group.name}
              </Typography>
            </Tooltip>
            <Typography variant="body2" sx={{ 
              color: group.value > 0 ? theme.palette.text.primary : theme.palette.text.secondary,
              fontWeight: group.value > 0 ? 'medium' : 'normal'
            }}>
              {group.value}%
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <LinearProgress
              variant="determinate"
              value={group.value}
              sx={{
                height: 8,
                borderRadius: 4,
                backgroundColor: alpha(theme.palette.grey[200], theme.palette.mode === 'dark' ? 0.2 : 1),
                '& .MuiLinearProgress-bar': {
                  backgroundColor: colors[index],
                  borderRadius: 4,
                },
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HormoneGroupsChart;

import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid } from '@mui/material';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import GeneralSettings from '../../components/admin/settings/GeneralSettings';
import NotificationSettings from '../../components/admin/settings/NotificationSettings';
import PasswordSettings from '../../components/admin/settings/PasswordSettings';

const SettingsPage: React.FC = () => {
  const [generalSettings, setGeneralSettings] = useState({
    programName: 'Ageback Health Program',
    supportEmail: 'support@ageback.com',
    allowNewRegistrations: true,
    requireEmailVerification: true,
    maintenanceMode: false,
  });

  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    pushNotifications: true,
    reminderFrequency: 'weekly' as const,
    progressUpdateFrequency: 'weekly' as const,
    customEmailTemplate: 'Dear {{user}},\n\nThis is a reminder about your {{program}} progress.\n\nBest regards,\nAgeback Team',
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        // Fetch general settings
        const generalDoc = await getDoc(doc(db, 'settings', 'general'));
        if (generalDoc.exists()) {
          setGeneralSettings(generalDoc.data() as typeof generalSettings);
        }

        // Fetch notification settings
        const notificationDoc = await getDoc(doc(db, 'settings', 'notifications'));
        if (notificationDoc.exists()) {
          setNotificationSettings(notificationDoc.data() as typeof notificationSettings);
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleGeneralUpdate = () => {
    // Refresh settings after update
    fetchSettings();
  };

  const handleNotificationUpdate = () => {
    // Refresh settings after update
    fetchSettings();
  };

  const fetchSettings = async () => {
    try {
      const generalDoc = await getDoc(doc(db, 'settings', 'general'));
      if (generalDoc.exists()) {
        setGeneralSettings(generalDoc.data() as typeof generalSettings);
      }

      const notificationDoc = await getDoc(doc(db, 'settings', 'notifications'));
      if (notificationDoc.exists()) {
        setNotificationSettings(notificationDoc.data() as typeof notificationSettings);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h4" gutterBottom>
        Settings
      </Typography>
      {loading ? (
        <Typography>Loading settings...</Typography>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <GeneralSettings 
              settings={generalSettings}
              onUpdate={handleGeneralUpdate}
            />
          </Grid>

          <Grid item xs={12}>
            <NotificationSettings
              settings={notificationSettings}
              onUpdate={handleNotificationUpdate}
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordSettings />
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default SettingsPage;

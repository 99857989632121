import React from 'react';
import { Box, useTheme, Container } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import AdminHeader from '../admin/AdminHeader';
import Footer from './Footer';
import { useAuth } from '../../contexts/AuthContext';

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { signOut } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);
  const theme = useTheme();
  const location = useLocation();

  const isAdminRoute = location.pathname.startsWith('/admin');

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      await signOut();
    } catch (error) {
      console.error('Failed to log out:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  return (
    <Box 
      sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        minHeight: '100vh',
        backgroundColor: theme.palette.mode === 'light'
          ? theme.palette.grey[50]
          : theme.palette.background.default,
      }}
    >
      {isAdminRoute ? (
        <AdminHeader onLogoutClick={handleLogout} isLoggingOut={isLoggingOut} />
      ) : (
        <Header onLogoutClick={handleLogout} isLoggingOut={isLoggingOut} />
      )}
      <Container 
        maxWidth="xl" 
        component="main"
        sx={{
          flexGrow: 1,
          py: { xs: 2, sm: 2.5 },
          px: { xs: 1, sm: 2 },
          mt: isAdminRoute ? { xs: 4, sm: 5 } : { xs: 2, sm: 2 },
          mx: 'auto',
          width: '100%',
          '& > *:not(:last-child)': {
            mb: { xs: 3, sm: 4 },
          },
        }}
      >
        <Box
          sx={{
            borderRadius: '12px',
            overflow: 'hidden',
            boxShadow: theme.palette.mode === 'light'
              ? '0 2px 12px rgba(0, 0, 0, 0.05)'
              : '0 2px 12px rgba(0, 0, 0, 0.2)',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {children}
        </Box>
      </Container>
      <Footer />
    </Box>
  );
};

export default Layout;

import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import LegalPageHeader from '../../components/legal/LegalPageHeader';

const Privacy: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper sx={{ p: 4 }}>
          <LegalPageHeader title="Privacy Policy" />
          <Divider sx={{ my: 4 }} />

          <Typography variant="subtitle1" color="text.secondary" paragraph>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="h5" gutterBottom>
            1. Introduction
          </Typography>
          <Typography paragraph>
            At Ageback Health Program, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application and services.
          </Typography>

          <Typography variant="h5" gutterBottom>
            2. Information We Collect
          </Typography>
          <Typography paragraph>
            We collect information that you provide directly to us, including:
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>Personal identification information (name, email address, phone number)</li>
            <li>Health and wellness data</li>
            <li>Usage data and preferences</li>
            <li>Device information and analytics</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            3. How We Use Your Information
          </Typography>
          <Typography paragraph>
            We use the collected information for:
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>Providing and improving our services</li>
            <li>Personalizing your experience</li>
            <li>Communicating with you about updates and changes</li>
            <li>Analytics and research purposes</li>
            <li>Legal compliance</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            4. Data Security
          </Typography>
          <Typography paragraph>
            We implement appropriate technical and organizational security measures to protect your personal information. However, no method of transmission over the Internet is 100% secure.
          </Typography>

          <Typography variant="h5" gutterBottom>
            5. Your Rights
          </Typography>
          <Typography paragraph>
            You have the right to:
          </Typography>
          <Typography component="ul" sx={{ pl: 4 }}>
            <li>Access your personal information</li>
            <li>Correct inaccurate data</li>
            <li>Request deletion of your data</li>
            <li>Opt-out of marketing communications</li>
            <li>Data portability</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            6. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about this Privacy Policy, please contact us at:
          </Typography>
          <Typography paragraph>
            Email: privacy@ageback.com<br />
            Address: 123 Health Street, Suite 100<br />
            San Francisco, CA 94105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default Privacy;

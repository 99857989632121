import { doc, deleteDoc, writeBatch, getDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { db } from '../firebase/firebaseConfig';

export const deleteUser = async (userId: string): Promise<{ success: boolean; message: string }> => {
  try {
    // Call the Cloud Function to delete the user from Authentication
    const functions = getFunctions();
    const deleteUserCallable = httpsCallable(functions, 'deleteUserCallable');
    await deleteUserCallable({ uid: userId });

    // Delete from Firestore collections using batch
    const batch = writeBatch(db);
    
    // Delete from users collection
    batch.delete(doc(db, 'users', userId));
    
    // Delete from roles collection
    batch.delete(doc(db, 'roles', userId));
    
    // Delete from any other related collections
    // Add more collections here if needed
    
    await batch.commit();

    return { 
      success: true, 
      message: 'User successfully deleted from both Authentication and Firestore' 
    };
  } catch (error: any) {
    console.error('Error deleting user:', error);
    return { 
      success: false, 
      message: error.message || 'Failed to delete user' 
    };
  }
};

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  Grid
} from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { HealthSummary, SeverityLevel } from '../../types/user';

interface EditHealthSummaryDialogProps {
  open: boolean;
  onClose: () => void;
  healthSummary: HealthSummary | undefined;
  onSave: (healthSummary: HealthSummary) => Promise<void>;
  userId: string;
}

interface CriticalPoint {
  category: string;
  description: string;
  severity: SeverityLevel;
}

const EditHealthSummaryDialog: React.FC<EditHealthSummaryDialogProps> = ({
  open,
  onClose,
  healthSummary,
  onSave,
  userId,
}) => {
  const [editedSummary, setEditedSummary] = useState<HealthSummary>({
    keyFindings: healthSummary?.keyFindings || [],
    recommendations: healthSummary?.recommendations || [],
    criticalPoints: healthSummary?.criticalPoints || [],
    lastUpdated: healthSummary?.lastUpdated || new Date().toISOString()
  });

  const [newKeyFinding, setNewKeyFinding] = useState('');
  const [newRecommendation, setNewRecommendation] = useState('');
  const [newCriticalPoint, setNewCriticalPoint] = useState<CriticalPoint>({
    category: '',
    description: '',
    severity: 'low',
  });

  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (healthSummary) {
      console.log('EditHealthSummaryDialog - Initializing with health summary:', JSON.stringify(healthSummary, null, 2));
      setEditedSummary({
        keyFindings: Array.isArray(healthSummary.keyFindings) ? [...healthSummary.keyFindings] : [],
        recommendations: Array.isArray(healthSummary.recommendations) ? [...healthSummary.recommendations] : [],
        criticalPoints: Array.isArray(healthSummary.criticalPoints) ? [...healthSummary.criticalPoints] : [],
        lastUpdated: healthSummary.lastUpdated || new Date().toISOString()
      });
    } else {
      console.log('EditHealthSummaryDialog - No health summary provided, initializing empty');
      setEditedSummary({
        keyFindings: [],
        recommendations: [],
        criticalPoints: [],
        lastUpdated: new Date().toISOString()
      });
    }
  }, [healthSummary]);

  const handleSave = async () => {
    console.log('EditHealthSummaryDialog - Current state:', {
      keyFindings: editedSummary.keyFindings,
      recommendations: editedSummary.recommendations,
      criticalPoints: editedSummary.criticalPoints
    });

    const healthSummary: HealthSummary = {
      keyFindings: editedSummary.keyFindings.filter(item => item.trim() !== ''),
      recommendations: editedSummary.recommendations.filter(item => item.trim() !== ''),
      criticalPoints: editedSummary.criticalPoints.filter(point => point.category.trim() !== '' && point.description.trim() !== ''),
      lastUpdated: new Date().toISOString()
    };

    console.log('EditHealthSummaryDialog - Saving health summary:', JSON.stringify(healthSummary, null, 2));
    try {
      await onSave(healthSummary);
      onClose();
    } catch (error) {
      console.error('Error saving health summary:', error);
      setError(error instanceof Error ? error.message : 'Failed to save health summary');
    }
  };

  const handleAddItem = (field: keyof HealthSummary, value: string) => {
    if (!value.trim()) return;
    
    setEditedSummary(prev => {
      const currentArray = Array.isArray(prev[field]) ? [...prev[field] as string[]] : [];
      const updatedField = [...currentArray, value.trim()];
      console.log(`EditHealthSummaryDialog - Adding item to ${field}:`, updatedField);
      
      return {
        ...prev,
        [field]: updatedField,
        lastUpdated: new Date().toISOString()
      };
    });

    // Clear the input field
    switch(field) {
      case 'keyFindings':
        setNewKeyFinding('');
        break;
      case 'recommendations':
        setNewRecommendation('');
        break;
      case 'criticalPoints':
        setNewCriticalPoint({ category: '', description: '', severity: 'low' });
        break;
    }
  };

  const handleRemoveItem = (field: keyof HealthSummary, index: number) => {
    setEditedSummary(prev => {
      const currentArray = Array.isArray(prev[field]) ? [...prev[field] as string[]] : [];
      const updatedField = currentArray.filter((_, i) => i !== index);
      console.log(`EditHealthSummaryDialog - Removing item from ${field}:`, updatedField);
      
      return {
        ...prev,
        [field]: updatedField,
        lastUpdated: new Date().toISOString()
      };
    });
  };

  const addCriticalPoint = () => {
    if (newCriticalPoint.description.trim() && newCriticalPoint.category.trim()) {
      setEditedSummary(prev => ({
        ...prev,
        criticalPoints: [...prev.criticalPoints, { ...newCriticalPoint }]
      }));
      setNewCriticalPoint({ category: '', description: '', severity: 'low' });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Health Summary</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}

          <Typography variant="subtitle1" gutterBottom>Key Findings</Typography>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                fullWidth
                value={newKeyFinding}
                onChange={(e) => setNewKeyFinding(e.target.value)}
                placeholder="Add a key finding"
              />
              <IconButton onClick={() => handleAddItem('keyFindings', newKeyFinding)} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {editedSummary.keyFindings.map((finding, index) => (
                <Chip
                  key={index}
                  label={finding}
                  onDelete={() => handleRemoveItem('keyFindings', index)}
                />
              ))}
            </Box>
          </Box>

          <Typography variant="subtitle1" gutterBottom>Recommendations</Typography>
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
              <TextField
                fullWidth
                value={newRecommendation}
                onChange={(e) => setNewRecommendation(e.target.value)}
                placeholder="Add a recommendation"
              />
              <IconButton onClick={() => handleAddItem('recommendations', newRecommendation)} color="primary">
                <AddIcon />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {editedSummary.recommendations.map((recommendation, index) => (
                <Chip
                  key={index}
                  label={recommendation}
                  onDelete={() => handleRemoveItem('recommendations', index)}
                />
              ))}
            </Box>
          </Box>

          <Typography variant="subtitle1" gutterBottom>Critical Points</Typography>
          <Box sx={{ mb: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Category"
                  value={newCriticalPoint.category}
                  onChange={(e) => setNewCriticalPoint(prev => ({
                    ...prev,
                    category: e.target.value
                  }))}
                  placeholder="Enter category"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Description"
                  value={newCriticalPoint.description}
                  onChange={(e) => setNewCriticalPoint(prev => ({
                    ...prev,
                    description: e.target.value
                  }))}
                  placeholder="Enter description"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={newCriticalPoint.severity}
                    label="Severity"
                    onChange={(e) => setNewCriticalPoint(prev => ({
                      ...prev,
                      severity: e.target.value as SeverityLevel
                    }))}
                  >
                    <MenuItem value="low">Low</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1} sx={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={addCriticalPoint} color="primary">
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }}>
              {editedSummary.criticalPoints?.map((point, index) => (
                <Box
                  key={index}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    mb: 1,
                    p: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {point.category}:
                  </Typography>
                  <Typography variant="body2" sx={{ flexGrow: 1 }}>
                    {point.description}
                  </Typography>
                  <Chip
                    label={point.severity}
                    size="small"
                    color={
                      point.severity === 'high' ? 'error' :
                      point.severity === 'medium' ? 'warning' : 'success'
                    }
                  />
                  <IconButton
                    size="small"
                    onClick={() => {
                      const newPoints = [...editedSummary.criticalPoints];
                      newPoints.splice(index, 1);
                      setEditedSummary({ ...editedSummary, criticalPoints: newPoints });
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditHealthSummaryDialog };

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';
import { syncUsersWithAuth } from '../../utils/syncUsersWithAuth';

interface UserSyncDialogProps {
  open: boolean;
  onClose: () => void;
}

const UserSyncDialog: React.FC<UserSyncDialogProps> = ({ open, onClose }) => {
  const [syncing, setSyncing] = useState(false);
  const [result, setResult] = useState<{
    success?: boolean;
    message?: string;
    error?: string;
    stats?: {
      total: number;
      existing: number;
      created: number;
      failed: number;
    };
  }>({});

  const handleSync = async () => {
    setSyncing(true);
    try {
      const syncResult = await syncUsersWithAuth();
      setResult(syncResult);
    } catch (error) {
      setResult({
        success: false,
        error: error instanceof Error ? error.message : 'Unknown error occurred'
      });
    } finally {
      setSyncing(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Sync Users with Authentication</DialogTitle>
      <DialogContent>
        <Typography>
          This will attempt to create authentication accounts for users that exist in the database but not in Firebase Authentication.
        </Typography>
        {syncing && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <CircularProgress />
            <Typography style={{ marginTop: 10 }}>
              Syncing users...
            </Typography>
          </div>
        )}
        {result.success !== undefined && !syncing && (
          <>
            <Alert 
              severity={result.success ? 'success' : 'error'}
              style={{ marginTop: 20 }}
            >
              {result.success ? result.message : result.error}
            </Alert>
            {result.success && result.stats && (
              <Typography variant="body2" style={{ marginTop: 10 }}>
                Total Users: {result.stats.total}<br/>
                Already in Auth: {result.stats.existing}<br/>
                Newly Created: {result.stats.created}<br/>
                Failed: {result.stats.failed}
              </Typography>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={syncing}>
          Close
        </Button>
        <Button 
          onClick={handleSync} 
          variant="contained" 
          color="primary"
          disabled={syncing}
        >
          Start Sync
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSyncDialog;

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme, alpha } from '@mui/material';
import { MetabolicSystems } from '../../../types/healthData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface MetabolicChartProps {
  data: MetabolicSystems;
}

const MetabolicChart: React.FC<MetabolicChartProps> = ({ data }) => {
  const theme = useTheme();

  const labels = [
    'Digestive',
    'Immune',
    'Nervous',
    'Circulatory',
    'Respiratory',
    'Endocrine',
    'Lymphatic',
    'Musculoskeletal',
  ];

  const values = [
    data.digestive,
    data.immune,
    data.nervous,
    data.circulatory,
    data.respiratory,
    data.endocrine,
    data.lymphatic,
    data.musculoskeletal,
  ];

  const chartData: ChartData<'bar'> = {
    labels,
    datasets: [
      {
        label: 'System Health',
        data: values,
        backgroundColor: [
          alpha(theme.palette.primary.main, 0.8),
          alpha(theme.palette.secondary.main, 0.8),
          alpha(theme.palette.error.main, 0.8),
          alpha(theme.palette.warning.main, 0.8),
          alpha(theme.palette.info.main, 0.8),
          alpha(theme.palette.success.main, 0.8),
          alpha('#9c27b0', 0.8), // purple
          alpha('#795548', 0.8), // brown
        ],
        borderColor: [
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.error.main,
          theme.palette.warning.main,
          theme.palette.info.main,
          theme.palette.success.main,
          '#9c27b0', // purple
          '#795548', // brown
        ],
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 0,
        right: 12,
        top: 0,
        bottom: 5
      }
    },
    scales: {
      x: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: alpha(theme.palette.text.secondary, 0.1),
        },
        border: {
          display: false
        },
        ticks: {
          callback: function(value) {
            return value + '%';
          },
          color: theme.palette.text.secondary,
          padding: 4
        }
      },
      y: {
        grid: {
          display: false
        },
        border: {
          display: false
        },
        ticks: {
          color: theme.palette.text.primary,
          padding: 5,
          font: {
            size: 11
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        backgroundColor: alpha(theme.palette.background.paper, 0.9),
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: 8,
        callbacks: {
          label: function(context) {
            return `${context.dataset.label}: ${context.raw}%`;
          }
        }
      }
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default MetabolicChart;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { ThemeProvider } from './contexts/ThemeContext';
import theme from './theme';
import ErrorBoundary from './components/common/ErrorBoundary';
import { initializeDatabase } from './utils/initializeDatabase';

// Components
import Login from './components/auth/Login';
import Dashboard from './components/dashboard/Dashboard';
import AdminDashboard from './pages/admin/AdminDashboard';
import UserManagementPage from './pages/admin/UserManagementPage';
import AnalyticsPage from './pages/admin/AnalyticsPage';
import SettingsPage from './pages/admin/SettingsPage';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import Layout from './components/layout/Layout';
import Profile from './components/profile/Profile';
import Settings from './components/settings/Settings';

// Legal Pages
import Privacy from './pages/legal/Privacy';
import Terms from './pages/legal/Terms';
import CookiePolicy from './pages/legal/CookiePolicy';
import Licenses from './pages/legal/Licenses';

// Protected Route Component
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useAuth();
  return currentUser ? <>{children}</> : <Navigate to="/login" />;
};

const App: React.FC = () => {
  console.log('Rendering App component');

  useEffect(() => {
    const initDB = async () => {
      try {
        await initializeDatabase();
        console.log('Database initialized successfully');
      } catch (error) {
        console.error('Error initializing database:', error);
      }
    };
    
    initDB();
  }, []);

  return (
    <ErrorBoundary>
      <AuthProvider>
        <NotificationProvider>
          <ThemeProvider>
            <CssBaseline />
            <Router>
                <Routes>
                  {/* Public Routes */}
                  <Route path="/login" element={<Login />} />

                  {/* Legal Routes - Public */}
                  <Route path="/legal/privacy" element={<Layout><Privacy /></Layout>} />
                  <Route path="/legal/terms" element={<Layout><Terms /></Layout>} />
                  <Route path="/legal/cookie-policy" element={<Layout><CookiePolicy /></Layout>} />
                  <Route path="/legal/licenses" element={<Layout><Licenses /></Layout>} />

                  {/* Protected Admin Routes */}
                  <Route
                    path="/admin/*"
                    element={
                      <AdminRoute>
                        <Layout>
                          <Routes>
                            <Route index element={<AdminDashboard />} />
                            <Route path="users" element={<UserManagementPage />} />
                            <Route path="analytics" element={<AnalyticsPage />} />
                            <Route path="settings" element={<SettingsPage />} />
                          </Routes>
                        </Layout>
                      </AdminRoute>
                    }
                  />

                  {/* Protected User Routes */}
                  <Route
                    path="/dashboard/*"
                    element={
                      <PrivateRoute>
                        <Layout>
                          <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/settings" element={<Settings />} />
                          </Routes>
                        </Layout>
                      </PrivateRoute>
                    }
                  />

                  {/* Default Route */}
                  <Route path="*" element={<Navigate to="/login" replace />} />
                </Routes>
            </Router>
          </ThemeProvider>
        </NotificationProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;

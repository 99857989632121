import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';
import { useAuth } from '../contexts/AuthContext';

interface RoleState {
  role: string | null;
  loading: boolean;
  error: Error | null;
}

export const useRole = () => {
  const { currentUser } = useAuth();
  const [state, setState] = useState<RoleState>({
    role: null,
    loading: true,
    error: null
  });

  useEffect(() => {
    console.log('useRole effect triggered with currentUser:', currentUser?.email);
    
    const fetchRole = async () => {
      if (!currentUser) {
        console.log('No current user, setting role to null');
        setState({ role: null, loading: false, error: null });
        return;
      }

      try {
        console.log('Fetching role for user:', currentUser.email);
        
        // First, try to get role from the user document
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists() && userDoc.data().role) {
          console.log('Found role in user document:', userDoc.data().role);
          setState({
            role: userDoc.data().role,
            loading: false,
            error: null
          });
          return;
        }

        // If not found in user document, try the roles collection
        const roleDoc = await getDoc(doc(db, 'roles', currentUser.uid));
        if (roleDoc.exists() && roleDoc.data().role) {
          console.log('Found role in roles collection:', roleDoc.data().role);
          setState({
            role: roleDoc.data().role,
            loading: false,
            error: null
          });
          return;
        }

        // No role found in either location
        console.error('No role found for user:', currentUser.email);
        setState({
          role: null,
          loading: false,
          error: new Error('No role found')
        });
      } catch (error) {
        console.error('Error fetching role:', error);
        setState({
          role: null,
          loading: false,
          error: error as Error
        });
      }
    };

    fetchRole();
  }, [currentUser]);

  return state;
};

export default useRole;

import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
} from '@mui/material';
import LegalPageHeader from '../../components/legal/LegalPageHeader';

const CookiePolicy: React.FC = () => {
  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper sx={{ p: 4 }}>
          <LegalPageHeader title="Cookie Policy" />
          <Divider sx={{ my: 4 }} />

          <Typography variant="subtitle1" color="text.secondary" paragraph>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>
          <Divider sx={{ my: 4 }} />

          <Typography variant="h5" gutterBottom>
            1. What Are Cookies
          </Typography>
          <Typography paragraph>
            Cookies are small text files that are placed on your computer or mobile device when you visit our website. They are widely used to make websites work more efficiently and provide a better user experience.
          </Typography>

          <Typography variant="h5" gutterBottom>
            2. How We Use Cookies
          </Typography>
          <Typography paragraph>
            We use cookies for the following purposes:
          </Typography>
          <Typography component="ul" sx={{ pl: 4, mb: 3 }}>
            <li>Essential cookies: Required for the website to function properly</li>
            <li>Analytics cookies: To understand how visitors interact with our website</li>
            <li>Preference cookies: To remember your settings and preferences</li>
            <li>Marketing cookies: To deliver more relevant advertisements</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            3. Types of Cookies We Use
          </Typography>
          <Typography paragraph>
            Our website uses the following types of cookies:
          </Typography>
          <Typography component="ul" sx={{ pl: 4, mb: 3 }}>
            <li>Session cookies (temporary)</li>
            <li>Persistent cookies (remain until deleted)</li>
            <li>First-party cookies (set by our website)</li>
            <li>Third-party cookies (set by other domains)</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            4. Cookie Management
          </Typography>
          <Typography paragraph>
            You can control and manage cookies in various ways:
          </Typography>
          <Typography component="ul" sx={{ pl: 4, mb: 3 }}>
            <li>Browser settings: Configure your browser to accept/reject cookies</li>
            <li>Privacy settings: Adjust your privacy preferences on our website</li>
            <li>Third-party tools: Use cookie management tools</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            5. Third-Party Cookies
          </Typography>
          <Typography paragraph>
            We use third-party services that may set cookies on your device. These services include:
          </Typography>
          <Typography component="ul" sx={{ pl: 4, mb: 3 }}>
            <li>Google Analytics (analytics)</li>
            <li>Firebase (authentication)</li>
            <li>Social media plugins</li>
          </Typography>

          <Typography variant="h5" gutterBottom>
            6. Updates to This Policy
          </Typography>
          <Typography paragraph>
            We may update this Cookie Policy from time to time. The latest version will always be available on our website.
          </Typography>

          <Typography variant="h5" gutterBottom>
            7. Contact Us
          </Typography>
          <Typography paragraph>
            If you have any questions about our Cookie Policy, please contact us at:
          </Typography>
          <Typography paragraph>
            Email: privacy@ageback.com<br />
            Address: 123 Health Street, Suite 100<br />
            San Francisco, CA 94105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default CookiePolicy;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  useTheme,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase/firebaseConfig';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showResetDialog, setShowResetDialog] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    if (currentUser) {
      console.log('Current user detected, navigating to dashboard');
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Login form submitted');

    try {
      setError('');
      setLoading(true);

      if (!email || !password) {
        setError('Please fill in all fields');
        return;
      }

      console.log('Attempting login with email:', email);
      await login(email, password);
      console.log('Login successful');
      navigate('/dashboard');
    } catch (error: any) {
      console.error('Login error:', error);
      let errorMessage = 'Failed to sign in';
      
      if (error.message.includes('pending approval')) {
        errorMessage = error.message;
      } else {
        switch (error.code) {
          case 'auth/invalid-credential':
            errorMessage = 'Invalid email or password';
            break;
          case 'auth/user-not-found':
            errorMessage = 'No account found with this email';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Incorrect password';
            break;
          case 'auth/invalid-email':
            errorMessage = 'Invalid email address';
            break;
          case 'auth/too-many-requests':
            errorMessage = 'Too many failed attempts. Please try again later';
            break;
          default:
            errorMessage = error.message || 'Failed to sign in';
        }
      }
      
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleResetPassword = async () => {
    try {
      if (!resetEmail) {
        setError('Please enter your email address');
        return;
      }

      setResetLoading(true);
      console.log('Attempting to send password reset email to:', resetEmail);
      await sendPasswordResetEmail(auth, resetEmail, {
        url: window.location.origin + '/login', // Add continue URL
      });
      console.log('Password reset email sent successfully');
      setResetSuccess(true);
      setError('');
    } catch (error: any) {
      console.error('Password reset error:', error);
      let errorMessage = 'Failed to send password reset email';
      
      switch (error.code) {
        case 'auth/user-not-found':
          errorMessage = 'No account found with this email';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Invalid email address';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your internet connection';
          break;
        case 'auth/too-many-requests':
          errorMessage = 'Too many attempts. Please try again later';
          break;
        default:
          errorMessage = `Error: ${error.message}`;
      }
      
      console.error('Password reset failed:', errorMessage);
      setError(errorMessage);
    } finally {
      setResetLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* Logo Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: 4,
          }}
        >
          <Box
            component="img"
            src="/bluelogo.png"
            alt="Ageback Logo"
            sx={{
              height: 150,
              maxWidth: '100%',
              width: 'auto',
              mb: 2,
              objectFit: 'contain',
              borderRadius: 2,
              p: 1,
            }}
          />
          <Typography
            component="h1"
            variant="h4"
            sx={{
              fontWeight: 600,
              letterSpacing: '-0.5px',
              mb: 0.5,
              background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Healthboard<sup style={{ fontSize: '0.5em', verticalAlign: 'super' }}>®</sup>
          </Typography>
          <Typography 
            variant="subtitle2" 
            sx={{ 
              mb: 1,
              color: theme.palette.text.secondary,
              fontStyle: 'italic'
            }}
          >
            by Ageback<sup style={{ fontSize: '0.5em', verticalAlign: 'super' }}>®</sup>
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center">
            Sign in to your account
          </Typography>
        </Box>

        <Paper
          elevation={theme.palette.mode === 'light' ? 1 : 0}
          sx={{
            p: 4,
            width: '100%',
            bgcolor: theme.palette.mode === 'light' ? 'background.paper' : 'background.paper',
            borderRadius: 2,
          }}
        >
          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, position: 'relative', height: 48 }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                  }}
                />
              ) : (
                'Sign In'
              )}
            </Button>

            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Link
                component="button"
                variant="body2"
                onClick={() => setShowResetDialog(true)}
              >
                Forgot password?
              </Link>
            </Box>
          </Box>
        </Paper>
      </Box>

      {/* Password Reset Dialog */}
      <Dialog
        open={showResetDialog}
        onClose={() => {
          setShowResetDialog(false);
          setResetSuccess(false);
          setResetEmail('');
          setError('');
        }}
      >
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {resetSuccess ? (
            <DialogContentText>
              Password reset email has been sent. Please check your inbox.
            </DialogContentText>
          ) : (
            <>
              <DialogContentText>
                Enter your email address and we'll send you a link to reset your password.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="resetEmail"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowResetDialog(false);
              setResetSuccess(false);
              setResetEmail('');
              setError('');
            }}
          >
            Cancel
          </Button>
          {!resetSuccess && (
            <Button 
              onClick={handleResetPassword} 
              variant="contained"
              disabled={resetLoading}
            >
              {resetLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Reset Password'
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
  useTheme,
  Grid,
  alpha,
  Tooltip,
} from '@mui/material';
import {
  Warning as WarningIcon,
  ErrorOutline as ErrorOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@mui/icons-material';

interface ToxicityChartProps {
  data: {
    hepatotoxicity: number;
    immunotoxicity: number;
    nephrotoxicity: number;
    neurotoxicity: number;
    reproductiveToxicity: number;
    respiratoryToxicity: number;
  };
}

const ToxicityGauge: React.FC<{
  value: number | null;
  label: string;
}> = ({ value, label }) => {
  const theme = useTheme();
  
  // Determine color based on toxicity level
  const getColor = (value: number | null) => {
    if (value === null) return theme.palette.grey[400];
    return value <= 20 ? theme.palette.success.main : theme.palette.error.main;
  };

  const getIcon = (value: number | null) => {
    if (value === null) return <ErrorOutlineIcon sx={{ color: theme.palette.grey[400] }} />;
    return value <= 20 
      ? <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
      : <WarningIcon sx={{ color: theme.palette.error.main }} />;
  };

  const color = getColor(value);
  const displayValue = value === null ? 0 : Math.min(100, value * 5); // Scale up the value for display

  return (
    <Tooltip 
      title={`${label}: ${value}% toxicity level${value !== null && value <= 20 ? ' - Good' : ' - Needs Improvement'}`}
      arrow
    >
      <Box
        sx={{
          position: 'relative',
          display: 'inline-flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
            bgcolor: alpha(color, 0.1),
            borderRadius: '50%',
            p: 1,
          }}
        >
          <CircularProgress
            variant="determinate"
            value={displayValue}
            size={80}
            thickness={6}
            sx={{
              color: color,
              '& .MuiCircularProgress-circle': {
                strokeLinecap: 'round',
              },
            }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {getIcon(value)}
          </Box>
        </Box>
        <Typography
          variant="body2"
          component="div"
          sx={{
            mt: 1,
            color: theme.palette.text.primary,
            fontWeight: 'medium',
            textAlign: 'center',
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: color,
            fontWeight: 'bold',
          }}
        >
          {value !== null ? `${value}%` : 'N/A'}
        </Typography>
      </Box>
    </Tooltip>
  );
};

const ToxicityChart: React.FC<ToxicityChartProps> = ({ data }) => {
  const theme = useTheme();

  return (
    <Card
      sx={{
        height: '100%',
        minHeight: 480,
        borderRadius: 2,
        boxShadow: theme.shadows[3],
        '&:hover': {
          boxShadow: theme.shadows[6],
          transform: 'translateY(-2px)',
          transition: 'all 0.3s',
        },
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: theme.palette.text.primary,
            mb: 3,
          }}
        >
          <WarningIcon color="warning" />
          Toxicity Levels
        </Typography>

        <Box sx={{ mt: 2 }}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.hepatotoxicity} label="Hepatotoxicity" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.immunotoxicity} label="Immunotoxicity" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.nephrotoxicity} label="Nephrotoxicity" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.neurotoxicity} label="Neurotoxicity" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.reproductiveToxicity} label="Reproductive Toxicity" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ToxicityGauge value={data.respiratoryToxicity} label="Respiratory Toxicity" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ mt: 4, px: 2 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Toxicity Level Guide:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <WarningIcon sx={{ color: theme.palette.error.main }} />
                <Typography variant="caption">Above 20%: Needs Improvement</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <CheckCircleOutlineIcon sx={{ color: theme.palette.success.main }} />
                <Typography variant="caption">20% or below: Good</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ToxicityChart;

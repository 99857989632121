import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  Box,
  Snackbar,
  Alert,
  AlertColor,
  MenuItem,
  Card,
  Stack
} from '@mui/material';
import { UserHealthData } from '../../types/healthData';
import { ExtendedUserHealthData, ExtendedHormonePanel } from '../../types/extendedHealthData';
import { HormonePanel } from '../../types/healthData';
import { calculateHealthMetrics } from '../dashboard/cards/HealthSpiderChart';

interface EditUserHealthDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (data: ExtendedUserHealthData) => Promise<void>;
  userData: ExtendedUserHealthData;
}

const EditUserHealthDialog: React.FC<EditUserHealthDialogProps> = ({
  open,
  onClose,
  onSave,
  userData,
}) => {
  const [editedData, setEditedData] = useState<ExtendedUserHealthData | null>(null);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({
    open: false,
    message: '',
    severity: 'success',
  });

  useEffect(() => {
    const initializedData: ExtendedUserHealthData = {
      userId: userData.userId,  // Required field
      email: userData.email,    // Required field
      name: userData.name,      // Required field
      lastUpdated: userData.lastUpdated, // Required field
      agebackScore: {
        overall: userData.agebackScore?.overall ?? 80,
        biological: userData.agebackScore?.biological ?? 80,
        lifestyle: userData.agebackScore?.lifestyle ?? 80,
        environmental: userData.agebackScore?.environmental ?? 80,
        metabolic: userData.agebackScore?.metabolic ?? 80,
        improvement: userData.agebackScore?.improvement ?? 0,
        chronological: userData.agebackScore?.chronological ?? 0
      },
      agebackTrend: userData.agebackTrend ?? [],
      healthRisks: userData.healthRisks ?? [],
      notifications: userData.notifications ?? [],
      personalInfo: {
        ...userData.personalInfo,
        bloodType: userData.personalInfo?.bloodType ?? '',
      },
      membership: userData.membership,
      agebackScores: userData.agebackScores,
      neurochemicals: {
        ...userData.neurochemicals,
        melatonin: userData.neurochemicals?.melatonin ?? 0,
      },
      bloodPanel: {
        ...userData.bloodPanel,
        glucose: userData.bloodPanel?.glucose ?? 0,
        cholesterol: userData.bloodPanel?.cholesterol ?? 0,
        triglycerides: userData.bloodPanel?.triglycerides ?? 0,
        hdl: userData.bloodPanel?.hdl ?? 0,
        ldl: userData.bloodPanel?.ldl ?? 0,
      },
      hormonePanel: {
        ...defaultHormonePanel,
        ...userData.hormonePanel,
      },
      toxicityLevels: {
        hepatotoxicity: userData.toxicityLevels?.hepatotoxicity ?? 0,
        immunotoxicity: userData.toxicityLevels?.immunotoxicity ?? 0,
        nephrotoxicity: userData.toxicityLevels?.nephrotoxicity ?? 0,
        neurotoxicity: userData.toxicityLevels?.neurotoxicity ?? 0,
        reproductiveToxicity: userData.toxicityLevels?.reproductiveToxicity ?? 0,
        respiratoryToxicity: userData.toxicityLevels?.respiratoryToxicity ?? 0,
      },
      epigeneticsProfile: {
        ...userData.epigeneticsProfile,
        oxidation: userData.epigeneticsProfile?.oxidation ?? 0,
        inflammation: userData.epigeneticsProfile?.inflammation ?? 0,
        detoxification: userData.epigeneticsProfile?.detoxification ?? 0,
      },
      metabolicSystems: {
        ...userData.metabolicSystems,
        excretory: userData.metabolicSystems?.excretory ?? 0,
        reproductive: userData.metabolicSystems?.reproductive ?? 0,
      },
      viMiAmEn: {
        vitamins: {
          ...userData.viMiAmEn?.vitamins,
          vitaminA: userData.viMiAmEn?.vitamins?.vitaminA ?? 0,
          vitaminB: userData.viMiAmEn?.vitamins?.vitaminB ?? 0,
          vitaminC: userData.viMiAmEn?.vitamins?.vitaminC ?? 0,
          vitaminD: userData.viMiAmEn?.vitamins?.vitaminD ?? 0,
          vitaminE: userData.viMiAmEn?.vitamins?.vitaminE ?? 0,
          vitaminK: userData.viMiAmEn?.vitamins?.vitaminK ?? 0,
        },
        minerals: {
          ...userData.viMiAmEn?.minerals,
          selenium: userData.viMiAmEn?.minerals?.selenium ?? 0,
          iodine: userData.viMiAmEn?.minerals?.iodine ?? 0,
        },
        aminoAcids: {
          ...userData.viMiAmEn?.aminoAcids,
          threonine: userData.viMiAmEn?.aminoAcids?.threonine ?? 0,
        },
        enzymes: {
          ...userData.viMiAmEn?.enzymes,
          amylase: userData.viMiAmEn?.enzymes?.amylase ?? 0,
          lipase: userData.viMiAmEn?.enzymes?.lipase ?? 0,
          protease: userData.viMiAmEn?.enzymes?.protease ?? 0,
          cellulase: userData.viMiAmEn?.enzymes?.cellulase ?? 0,
          lactase: userData.viMiAmEn?.enzymes?.lactase ?? 0,
          coq10: userData.viMiAmEn?.enzymes?.coq10 ?? 0,
        },
      },
      labResults: {
        cholesterol: userData.labResults?.cholesterol ?? 0,
        bloodPressureSystolic: userData.labResults?.bloodPressureSystolic ?? 0,
        bloodPressureDiastolic: userData.labResults?.bloodPressureDiastolic ?? 0,
        bloodSugar: userData.labResults?.bloodSugar ?? 0,
      },
    };
    setEditedData(initializedData);
  }, [userData]);

  const defaultHormonePanel: ExtendedHormonePanel = {
    // Growth, Development, and Maturation
    growthHormone: 80,
    igf1: 80,
    thyroidHormones: 80,

    // Metabolic Regulation
    insulin: 80,
    glucagon: 80,
    leptin: 80,

    // Stress and Emergency Response
    cortisol: 80,
    adrenaline: 80,
    noradrenaline: 80,

    // Reproductive Hormones
    testosterone: 80,
    estrogen: 80,
    progesterone: 80,

    // Homeostasis and Maintenance
    parathyroidHormone: 80,
    aldosterone: 80,
    vasopressin: 80,

    // Local and Specialized Functions
    melatonin: 80,
    calcitonin: 80,
    somatostatin: 80,

    // Legacy or additional hormones
    dhea: 80
  };

  const handleNumberChange = (
    category: keyof ExtendedUserHealthData,
    field: string,
    value: string
  ) => {
    const numValue = value === '' ? 0 : Number(value);
    setEditedData((prev) => {
      if (!prev) return prev;
      
      // Create a deep copy of the category
      const updatedCategory = JSON.parse(JSON.stringify(prev[category]));
      if (typeof updatedCategory === 'object') {
        updatedCategory[field] = numValue;
      }
      
      return {
        ...prev,
        [category]: updatedCategory
      };
    });
  };

  const handleViMiAmEnChange = (
    section: 'vitamins' | 'minerals' | 'aminoAcids' | 'enzymes',
    field: string,
    value: string | number
  ) => {
    setEditedData((prev) => {
      if (!prev) return prev;

      const numericValue = typeof value === 'string' ? parseFloat(value) || 0 : value;

      return {
        ...prev,
        viMiAmEn: {
          ...prev.viMiAmEn,
          [section]: {
            ...prev.viMiAmEn[section],
            [field]: numericValue,
          },
        },
      };
    });
  };

  const handleNestedChange = (
    section: keyof ExtendedUserHealthData,
    field: string,
    value: string | number
  ) => {
    setEditedData((prev) => {
      if (!prev) return prev;

      const numericValue = typeof value === 'string' ? parseFloat(value) || 0 : value;

      return {
        ...prev,
        [section]: {
          ...(prev[section] as Record<string, any>),
          [field]: numericValue,
        },
      };
    });
  };

  const handlePersonalInfoChange = (field: string, value: string) => {
    setEditedData((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        personalInfo: {
          ...(prev.personalInfo || {}),
          [field]: value,
        },
      };
    });
  };

  const handleLabResultsChange = (field: string, value: string) => {
    setEditedData((prev) => {
      if (!prev) return prev;
      return {
        ...prev,
        labResults: {
          ...(prev.labResults || {
            cholesterol: 0,
            bloodPressureSystolic: 0,
            bloodPressureDiastolic: 0,
            bloodSugar: 0,
          }),
          [field]: value,
        },
      };
    });
  };

  const handleAgebackScoreChange = (year: number, field: 'high' | 'low', value: string) => {
    const numValue = value === '' ? 0 : Number(value);
    
    if (!editedData?.agebackScores) return;
    
    type YearKeys = keyof typeof editedData.agebackScores;
    const scoreKey = `score${year}` as YearKeys;
    
    if (field === 'low') {
      const yearScore = editedData.agebackScores[scoreKey];
      const highScore = yearScore?.high ?? 0;
      if (numValue > highScore) return;
    }

    setEditedData((prev) => {
      if (!prev?.agebackScores) return prev;
      
      const currentScore = prev.agebackScores[scoreKey] || { high: 0, low: 0 };
      
      return {
        ...prev,
        agebackScores: {
          ...prev.agebackScores,
          [scoreKey]: {
            ...currentScore,
            [field]: numValue,
          },
        },
      };
    });
  };

  const adjustAllValues = (percentage: number) => {
    setEditedData((prev) => {
      if (!prev) return prev;
      const adjustValue = (value: number) => {
        const adjusted = value * (1 + percentage / 100);
        return Math.max(0, adjusted);
      };

      const adjustObject = (obj: Record<string, any>): any => {
        const result: Record<string, any> = {};
        for (const key in obj) {
          if (typeof obj[key] === 'number') {
            result[key] = adjustValue(obj[key]);
          } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            result[key] = adjustObject(obj[key]);
          } else {
            result[key] = obj[key];
          }
        }
        return result;
      };

      return adjustObject(prev) as ExtendedUserHealthData;
    });
  };

  const setAllValuesTo80 = () => {
    if (!editedData) return;

    const newData: ExtendedUserHealthData = {
      ...editedData,
      agebackScore: {
        overall: 80,
        biological: 80,
        lifestyle: 80,
        environmental: 80,
        metabolic: 80,
        improvement: 0,
        chronological: 0
      },
      agebackScores: {
        score2020: { high: 80, low: 80 },
        score2021: { high: 80, low: 80 },
        score2022: { high: 80, low: 80 },
        score2023: { high: 80, low: 80 },
        score2024: { high: 80, low: 80 },
        score2025: { high: 80, low: 80 },
        score2026: { high: 80, low: 80 }
      },
      neurochemicals: {
        ...editedData.neurochemicals,
        dopamine: 80,
        serotonin: 80,
        gaba: 80,
        acetylcholine: 80,
        norepinephrine: 80,
        endorphins: 80,
        melatonin: 80,
        glutamate: 80
      },
      bloodPanel: {
        hemoglobin: 80,
        whiteBloodCells: 80,
        platelets: 80,
        redBloodCells: 80,
        hematocrit: 80,
        mcv: 80,
        mch: 80,
        mchc: 80
      },
      hormonePanel: defaultHormonePanel,
      toxicityLevels: {
        hepatotoxicity: 80,
        immunotoxicity: 80,
        nephrotoxicity: 80,
        neurotoxicity: 80,
        reproductiveToxicity: 80,
        respiratoryToxicity: 80,
      },
      epigeneticsProfile: {
        methylation: 80,
        telomeres: 80,
        histones: 80,
        chromatin: 80
      },
      metabolicSystems: {
        digestive: 80,
        immune: 80,
        nervous: 80,
        circulatory: 80,
        respiratory: 80,
        endocrine: 80,
        lymphatic: 80,
        musculoskeletal: 80
      },
      viMiAmEn: {
        vitamins: {
          A: 80,
          B: 80,
          C: 80,
          D: 80,
          E: 80,
          K: 80
        },
        minerals: {
          calcium: 80,
          magnesium: 80,
          zinc: 80,
          iron: 80,
          potassium: 80
        },
        aminoAcids: {
          leucine: 80,
          isoleucine: 80,
          valine: 80,
          lysine: 80,
          methionine: 80
        },
        enzymes: {
          digestive: 80,
          metabolic: 80,
          antioxidant: 80,
          repair: 80
        }
      }
    };
    setEditedData(newData);
    setSnackbar({
      open: true,
      message: 'All values set to 80',
      severity: 'success',
    });
  };

  const setRandomOptimalValues = () => {
    if (!editedData) return;

    const getRandomValue = () => Math.floor(Math.random() * (95 - 85 + 1)) + 85;

    const newData: ExtendedUserHealthData = {
      ...editedData,
      agebackScore: {
        overall: getRandomValue(),
        biological: getRandomValue(),
        lifestyle: getRandomValue(),
        environmental: getRandomValue(),
        metabolic: getRandomValue(),
        improvement: 0,
        chronological: 0
      },
      agebackScores: {
        score2020: { high: getRandomValue(), low: getRandomValue() },
        score2021: { high: getRandomValue(), low: getRandomValue() },
        score2022: { high: getRandomValue(), low: getRandomValue() },
        score2023: { high: getRandomValue(), low: getRandomValue() },
        score2024: { high: getRandomValue(), low: getRandomValue() },
        score2025: { high: getRandomValue(), low: getRandomValue() }
      },
      neurochemicals: {
        ...editedData.neurochemicals,
        dopamine: getRandomValue(),
        serotonin: getRandomValue(),
        gaba: getRandomValue(),
        acetylcholine: getRandomValue(),
        norepinephrine: getRandomValue(),
        endorphins: getRandomValue(),
        melatonin: getRandomValue(),
        glutamate: getRandomValue()
      },
      bloodPanel: {
        hemoglobin: getRandomValue(),
        whiteBloodCells: getRandomValue(),
        platelets: getRandomValue(),
        redBloodCells: getRandomValue(),
        hematocrit: getRandomValue(),
        mcv: getRandomValue(),
        mch: getRandomValue(),
        mchc: getRandomValue()
      },
      hormonePanel: {
        growthHormone: getRandomValue(),
        igf1: getRandomValue(),
        thyroidHormones: getRandomValue(),
        insulin: getRandomValue(),
        glucagon: getRandomValue(),
        leptin: getRandomValue(),
        cortisol: getRandomValue(),
        adrenaline: getRandomValue(),
        noradrenaline: getRandomValue(),
        testosterone: getRandomValue(),
        estrogen: getRandomValue(),
        progesterone: getRandomValue(),
        parathyroidHormone: getRandomValue(),
        aldosterone: getRandomValue(),
        vasopressin: getRandomValue(),
        melatonin: getRandomValue(),
        calcitonin: getRandomValue(),
        somatostatin: getRandomValue(),
        dhea: getRandomValue()
      },
      toxicityLevels: {
        hepatotoxicity: getRandomValue(),
        immunotoxicity: getRandomValue(),
        nephrotoxicity: getRandomValue(),
        neurotoxicity: getRandomValue(),
        reproductiveToxicity: getRandomValue(),
        respiratoryToxicity: getRandomValue(),
      },
      epigeneticsProfile: {
        methylation: getRandomValue(),
        telomeres: getRandomValue(),
        histones: getRandomValue(),
        chromatin: getRandomValue()
      },
      metabolicSystems: {
        digestive: getRandomValue(),
        immune: getRandomValue(),
        nervous: getRandomValue(),
        circulatory: getRandomValue(),
        respiratory: getRandomValue(),
        endocrine: getRandomValue(),
        lymphatic: getRandomValue(),
        musculoskeletal: getRandomValue()
      },
      viMiAmEn: {
        vitamins: {
          A: getRandomValue(),
          B: getRandomValue(),
          C: getRandomValue(),
          D: getRandomValue(),
          E: getRandomValue(),
          K: getRandomValue()
        },
        minerals: {
          calcium: getRandomValue(),
          magnesium: getRandomValue(),
          zinc: getRandomValue(),
          iron: getRandomValue(),
          potassium: getRandomValue()
        },
        aminoAcids: {
          leucine: getRandomValue(),
          isoleucine: getRandomValue(),
          valine: getRandomValue(),
          lysine: getRandomValue(),
          methionine: getRandomValue()
        },
        enzymes: {
          digestive: getRandomValue(),
          metabolic: getRandomValue(),
          antioxidant: getRandomValue(),
          repair: getRandomValue()
        }
      }
    };
    setEditedData(newData);
    setSnackbar({
      open: true,
      message: 'All values randomly set between 85-95',
      severity: 'success',
    });
  };

  const handleSave = async () => {
    if (!editedData) return;

    // Calculate health metrics
    const healthMetrics = calculateHealthMetrics(editedData);
    
    // Update the data with calculated metrics
    const updatedData = {
      ...editedData,
      healthMetrics,
      lastUpdated: new Date().toISOString()
    };

    await onSave(updatedData);
    setSnackbar({
      open: true,
      message: 'Changes saved successfully',
      severity: 'success',
    });
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const renderHealthSection = (title: string, data: any = {}, category: keyof ExtendedUserHealthData) => (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        {title}
      </Typography>
      <Grid container spacing={2}>
        {Object.entries(data || {}).map(([key, value]) => (
          <Grid item xs={12} sm={6} md={4} key={key}>
            <TextField
              fullWidth
              label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
              type="number"
              value={value}
              onChange={(e) => handleNumberChange(category, key, e.target.value)}
              inputProps={{ step: 0.1 }}
            />
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ my: 2 }} />
    </>
  );

  const renderNestedHealthSection = (
    title: string,
    data: any = {},
    category: 'viMiAmEn'
  ) => (
    <>
      <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
        {title}
      </Typography>
      {Object.entries(data || {}).map(([section, values]) => (
        <Box key={section}>
          <Typography variant="subtitle1" gutterBottom sx={{ mt: 2 }}>
            {section.charAt(0).toUpperCase() + section.slice(1)}
          </Typography>
          <Grid container spacing={2}>
            {Object.entries(values as Record<string, number> || {}).map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <TextField
                  fullWidth
                  label={key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  type="number"
                  value={value}
                  onChange={(e) => handleViMiAmEnChange(
                    section as 'vitamins' | 'minerals' | 'aminoAcids' | 'enzymes',
                    key,
                    e.target.value
                  )}
                  inputProps={{ step: 0.1 }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
      <Divider sx={{ my: 2 }} />
    </>
  );

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle>Edit Health Data</DialogTitle>
        <DialogContent>
          <Box sx={{ p: 2 }}>
            {/* Ageback Scores Section */}
            <Typography variant="h6" gutterBottom>Current Ageback Score</Typography>
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Overall Score"
                  type="number"
                  value={editedData?.agebackScore?.overall ?? ''}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : Number(e.target.value);
                    setEditedData((prev) => prev ? {
                      ...prev,
                      agebackScore: {
                        ...prev.agebackScore,
                        overall: value
                      }
                    } : prev);
                  }}
                  inputProps={{ min: 0, max: 100 }}
                  helperText="Range: 0-100"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Previous Score"
                  type="number"
                  value={editedData?.agebackScore?.chronological ?? ''}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : Number(e.target.value);
                    setEditedData((prev) => prev ? {
                      ...prev,
                      agebackScore: {
                        ...prev.agebackScore,
                        chronological: value
                      }
                    } : prev);
                  }}
                  inputProps={{ min: 0, max: 100 }}
                  helperText="Range: 0-100"
                />
              </Grid>
            </Grid>

            <Typography variant="h6" gutterBottom>Ageback Scores</Typography>
            <Grid container spacing={2}>
              {[2020, 2021, 2022, 2023, 2024, 2025, 2026].map((year) => {
                const scoreKey = `score${year}` as keyof ExtendedUserHealthData['agebackScores'];
                const yearScores = editedData?.agebackScores?.[scoreKey] as { high: number; low: number } | undefined;
                return (
                  <Grid item xs={12} sm={6} key={year}>
                    <Box sx={{ 
                      p: 2, 
                      border: 1, 
                      borderColor: 'divider', 
                      borderRadius: 1 
                    }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Year {year}
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="High Score"
                            type="number"
                            value={yearScores?.high ?? 0}
                            onChange={(e) => handleAgebackScoreChange(year, 'high', e.target.value)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: 100,
                                step: 0.1,
                              },
                            }}
                            helperText={`Range: 0-100 (Current: ${yearScores?.high ?? 0})`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Low Score"
                            type="number"
                            value={yearScores?.low ?? 0}
                            onChange={(e) => handleAgebackScoreChange(year, 'low', e.target.value)}
                            InputProps={{
                              inputProps: {
                                min: 0,
                                max: yearScores?.high ?? 100,
                                step: 0.1,
                              },
                            }}
                            helperText={`Range: 0-${yearScores?.high ?? 100} (Current: ${yearScores?.low ?? 0})`}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
            <Divider sx={{ my: 3 }} />
            
            {/* Personal Info Section */}
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Box sx={{ 
              p: 2, 
              border: 1, 
              borderColor: 'divider', 
              borderRadius: 1,
              bgcolor: 'background.paper',
              boxShadow: 1,
              mb: 3
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Age"
                    type="number"
                    value={editedData?.personalInfo?.age ?? ''}
                    onChange={(e) => handlePersonalInfoChange('age', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 120,
                      },
                    }}
                    helperText="Range: 0-120"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Gender"
                    select
                    value={editedData?.personalInfo?.gender ?? ''}
                    onChange={(e) => handlePersonalInfoChange('gender', e.target.value)}
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Weight (kg)"
                    type="number"
                    value={editedData?.personalInfo?.weight ?? ''}
                    onChange={(e) => handlePersonalInfoChange('weight', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 300,
                        step: 0.1,
                      },
                    }}
                    helperText="Range: 0-300 kg"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Height (cm)"
                    type="number"
                    value={editedData?.personalInfo?.height ?? ''}
                    onChange={(e) => handlePersonalInfoChange('height', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 300,
                        step: 0.1,
                      },
                    }}
                    helperText="Range: 0-300 cm"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Lab Results Section */}
            <Typography variant="h6" gutterBottom>
              Lab Results
            </Typography>
            <Box sx={{ 
              p: 2, 
              border: 1, 
              borderColor: 'divider', 
              borderRadius: 1,
              bgcolor: 'background.paper',
              boxShadow: 1,
              mb: 3
            }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Cholesterol"
                    type="number"
                    value={editedData?.labResults?.cholesterol ?? ''}
                    onChange={(e) => handleLabResultsChange('cholesterol', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 500,
                        step: 0.1,
                      },
                    }}
                    helperText="Range: 0-500 mg/dL"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Blood Pressure (Systolic)"
                    type="number"
                    value={editedData?.labResults?.bloodPressureSystolic ?? ''}
                    onChange={(e) => handleLabResultsChange('bloodPressureSystolic', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 300,
                      },
                    }}
                    helperText="Range: 0-300 mmHg"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Blood Pressure (Diastolic)"
                    type="number"
                    value={editedData?.labResults?.bloodPressureDiastolic ?? ''}
                    onChange={(e) => handleLabResultsChange('bloodPressureDiastolic', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 200,
                      },
                    }}
                    helperText="Range: 0-200 mmHg"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    label="Blood Sugar"
                    type="number"
                    value={editedData?.labResults?.bloodSugar ?? ''}
                    onChange={(e) => handleLabResultsChange('bloodSugar', e.target.value)}
                    InputProps={{
                      inputProps: {
                        min: 0,
                        max: 500,
                        step: 0.1,
                      },
                    }}
                    helperText="Range: 0-500 mg/dL"
                  />
                </Grid>
              </Grid>
            </Box>

            {/* Neurochemicals */}
            {renderHealthSection('Neurochemicals', editedData?.neurochemicals, 'neurochemicals')}

            {/* Blood Panel */}
            {renderHealthSection('Blood Panel', editedData?.bloodPanel, 'bloodPanel')}

            {/* Hormone Panel */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Hormone Panel
              </Typography>
            </Grid>

            <Grid container spacing={2}>
              {/* Growth, Development, and Maturation */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Growth, Development, and Maturation
                  </Typography>
                  <Grid container spacing={2}>
                    {['growthHormone', 'igf1', 'thyroidHormones'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Metabolic Regulation */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Metabolic Regulation
                  </Typography>
                  <Grid container spacing={2}>
                    {['insulin', 'glucagon', 'leptin'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Stress and Emergency Response */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Stress and Emergency Response
                  </Typography>
                  <Grid container spacing={2}>
                    {['cortisol', 'adrenaline', 'noradrenaline'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Reproductive Hormones */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Reproductive Hormones
                  </Typography>
                  <Grid container spacing={2}>
                    {['testosterone', 'estrogen', 'progesterone'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Homeostasis and Maintenance */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Homeostasis and Maintenance
                  </Typography>
                  <Grid container spacing={2}>
                    {['parathyroidHormone', 'aldosterone', 'vasopressin'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Local and Specialized Functions */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Local and Specialized Functions
                  </Typography>
                  <Grid container spacing={2}>
                    {['melatonin', 'calcitonin', 'somatostatin'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>

              {/* Legacy or additional hormones */}
              <Grid item xs={12} md={6}>
                <Card sx={{ p: 2, height: '100%' }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Legacy or additional hormones
                  </Typography>
                  <Grid container spacing={2}>
                    {['dhea'].map((field) => (
                      <Grid item xs={12} key={field}>
                        <TextField
                          fullWidth
                          label={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                          type="number"
                          value={editedData?.hormonePanel?.[field as keyof HormonePanel] ?? 0}
                          onChange={(e) => handleNestedChange('hormonePanel', field, e.target.value)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Card>
              </Grid>
            </Grid>
            <Divider sx={{ my: 3 }} />

            {/* Toxicity Levels */}
            {renderHealthSection('Toxicity Levels', editedData?.toxicityLevels, 'toxicityLevels')}

            {/* Epigenetics Profile */}
            {renderHealthSection('Epigenetics Profile', editedData?.epigeneticsProfile, 'epigeneticsProfile')}

            {/* Metabolic Systems */}
            {renderHealthSection('Metabolic Systems', editedData?.metabolicSystems, 'metabolicSystems')}

            {/* Vitamins, Minerals, Amino Acids, and Enzymes */}
            {renderNestedHealthSection('Vitamins, Minerals, Amino Acids, and Enzymes', editedData?.viMiAmEn, 'viMiAmEn')}
          </Box>
        </DialogContent>
        <DialogActions sx={{ gap: 1, px: 3, pb: 2 }}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => adjustAllValues(-5)}
          >
            Decrease All by 5%
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => adjustAllValues(5)}
          >
            Increase All by 5%
          </Button>
          <Stack direction="row" spacing={2} justifyContent="flex-end" sx={{ mt: 3 }}>
            <Button onClick={() => setAllValuesTo80()} variant="outlined">
              Set All to 80
            </Button>
            <Button onClick={() => setRandomOptimalValues()} variant="outlined">
              Set Random 85-95
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save Changes
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditUserHealthDialog;

import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { db } from '../firebase/firebaseConfig';

interface UserData {
  id: string;
  email: string;
  password?: string;
}

export const syncUsersWithAuth = async () => {
  try {
    const auth = getAuth();
    const usersCollection = collection(db, 'users');
    const querySnapshot = await getDocs(usersCollection);
    
    const stats = {
      total: querySnapshot.size,
      existing: 0,
      created: 0,
      failed: 0
    };
    
    const inconsistentUsers: UserData[] = [];
    
    // Check each user in Firestore
    for (const docSnapshot of querySnapshot.docs) {
      const userData = docSnapshot.data() as UserData;
      
      try {
        if (userData.email) {
          // Generate a temporary password
          const tempPassword = Math.random().toString(36).slice(-8) + Math.random().toString(36).slice(-8);
          
          await createUserWithEmailAndPassword(auth, userData.email, tempPassword)
            .then(async (userCredential) => {
              console.log(`Created auth user for ${userData.email}`);
              stats.created++;
              
              // Send password reset email
              try {
                await sendPasswordResetEmail(auth, userData.email);
                console.log(`Password reset email sent to ${userData.email}`);
                
                // Update user document to mark as synced
                await updateDoc(doc(db, 'users', docSnapshot.id), {
                  authSynced: true,
                  authSyncedAt: new Date().toISOString()
                });
              } catch (resetError) {
                console.error(`Error sending password reset email to ${userData.email}:`, resetError);
              }
            })
            .catch((error) => {
              if (error.code === 'auth/email-already-in-use') {
                console.log(`User ${userData.email} already exists in Auth`);
                stats.existing++;
              } else {
                console.error(`Error creating auth user for ${userData.email}:`, error);
                inconsistentUsers.push({...userData, id: docSnapshot.id});
                stats.failed++;
              }
            });
        }
      } catch (error) {
        console.error(`Error processing user ${userData.email}:`, error);
        inconsistentUsers.push({...userData, id: docSnapshot.id});
        stats.failed++;
      }
    }
    
    const message = stats.created > 0
      ? `Sync completed. Created ${stats.created} new auth accounts, ${stats.existing} already existed, ${stats.failed} failed. Password reset emails have been sent to new users.`
      : `Sync completed. No new auth accounts needed to be created. ${stats.existing} users already existed in authentication.`;
    
    return {
      success: true,
      stats,
      inconsistentUsers,
      message
    };
  } catch (error) {
    console.error('Error syncing users:', error);
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Unknown error occurred'
    };
  }
};

import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
  ScriptableContext,
  Scale,
  Tick,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Card, CardContent, Typography, Box, useTheme, alpha } from '@mui/material';
import { Science as ScienceIcon } from '@mui/icons-material';
import { ViMiAmEn } from '../../../types/healthData';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ViMiAmEnChartProps {
  data: ViMiAmEn;
}

const defaultData: ViMiAmEn = {
  vitamins: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
    E: 0,
    K: 0
  },
  minerals: {
    calcium: 0,
    magnesium: 0,
    zinc: 0,
    iron: 0,
    potassium: 0
  },
  aminoAcids: {
    leucine: 0,
    isoleucine: 0,
    valine: 0,
    lysine: 0,
    methionine: 0
  },
  enzymes: {
    digestive: 0,
    metabolic: 0,
    antioxidant: 0,
    repair: 0
  }
};

const ViMiAmEnChart: React.FC<ViMiAmEnChartProps> = ({ data = defaultData }) => {
  const theme = useTheme();

  const calculateAverage = (obj: { [key: string]: number }) => {
    const values = Object.values(obj);
    return values.length ? values.reduce((a, b) => a + b, 0) / values.length : 0;
  };

  const averages = {
    vitamins: calculateAverage(data?.vitamins || defaultData.vitamins),
    minerals: calculateAverage(data?.minerals || defaultData.minerals),
    aminoAcids: calculateAverage(data?.aminoAcids || defaultData.aminoAcids),
    enzymes: calculateAverage(data?.enzymes || defaultData.enzymes),
  };

  const chartData: ChartData<'bar'> = {
    labels: ['Vitamins', 'Minerals', 'Amino Acids', 'Enzymes'],
    datasets: [
      {
        label: 'Average Level',
        data: [
          averages.vitamins,
          averages.minerals,
          averages.aminoAcids,
          averages.enzymes,
        ],
        backgroundColor: [
          alpha(theme.palette.primary.main, 0.8),
          alpha(theme.palette.success.main, 0.8),
          alpha(theme.palette.warning.main, 0.8),
          alpha(theme.palette.info.main, 0.8),
        ],
        borderColor: [
          theme.palette.primary.main,
          theme.palette.success.main,
          theme.palette.warning.main,
          theme.palette.info.main,
        ],
        borderWidth: 2,
        borderRadius: 8,
      },
    ],
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        backgroundColor: alpha(theme.palette.background.paper, 0.9),
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.secondary,
        borderColor: theme.palette.divider,
        borderWidth: 1,
        padding: 12,
        callbacks: {
          label: function(context) {
            const value = context.raw as number;
            return `Average Level: ${value.toFixed(1)}%`;
          },
          afterBody: function(tooltipItems) {
            const index = tooltipItems[0].dataIndex;
            const category = chartData.labels?.[index] as string;
            let details: string[] = [];
            
            switch (category) {
              case 'Vitamins':
                details = Object.entries(data?.vitamins || defaultData.vitamins).map(([key, value]) => 
                  `${key}: ${value.toFixed(1)}%`
                );
                break;
              case 'Minerals':
                details = Object.entries(data?.minerals || defaultData.minerals).map(([key, value]) => 
                  `${key}: ${value.toFixed(1)}%`
                );
                break;
              case 'Amino Acids':
                details = Object.entries(data?.aminoAcids || defaultData.aminoAcids).map(([key, value]) => 
                  `${key}: ${value.toFixed(1)}%`
                );
                break;
              case 'Enzymes':
                details = Object.entries(data?.enzymes || defaultData.enzymes).map(([key, value]) => 
                  `${key}: ${value.toFixed(1)}%`
                );
                break;
            }
            return details;
          }
        }
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        grid: {
          color: alpha(theme.palette.text.secondary, 0.1),
        },
        ticks: {
          callback: function(value) {
            return `${value}%`;
          },
          font: {
            size: 12,
          },
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
  };

  return (
    <Card sx={{ 
      height: '100%',
      minHeight: 480,
      borderRadius: 2,
      boxShadow: theme.shadows[3],
      '&:hover': {
        boxShadow: theme.shadows[6],
        transform: 'translateY(-2px)',
        transition: 'all 0.3s'
      }
    }}>
      <CardContent>
        <Typography variant="h6" gutterBottom sx={{ 
          display: 'flex', 
          alignItems: 'center',
          gap: 1,
          color: theme.palette.text.primary,
          mb: 3
        }}>
          <ScienceIcon color="primary" />
          Vi-Mi-Am-En Profile
        </Typography>
        <Box sx={{ height: 384 }}>
          <Bar data={chartData} options={options} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default ViMiAmEnChart;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  alpha,
} from '@mui/material';
import {
  Assessment as AssessmentIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip as ChartTooltip,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  ChartTooltip,
  Legend
);

interface HealthMetrics {
  toxicity: number;
  metabolic: number;
  epigenetics: number;
  hormones: number;
  neurochemicals: number;
  viMiAmEn: number;
}

interface HealthSpiderChartProps {
  metrics: HealthMetrics;
}

const calculateToxicityScore = (toxicityLevels: any): number => {
  const values = Object.values(toxicityLevels || {});
  if (values.length === 0) return 0;
  const sum = values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
  return Math.round(100 - (sum / values.length)); // Inverse of average toxicity
};

const calculateMetabolicScore = (metabolicSystems: any): number => {
  const values = Object.values(metabolicSystems || {});
  if (values.length === 0) return 0;
  const sum = values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
  return Math.round(sum / values.length);
};

const calculateEpigeneticsScore = (epigeneticsProfile: any): number => {
  const values = Object.values(epigeneticsProfile || {});
  if (values.length === 0) return 0;
  const sum = values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
  return Math.round(sum / values.length);
};

const calculateHormonesScore = (hormonePanel: any): number => {
  const values = Object.values(hormonePanel || {});
  if (values.length === 0) return 0;
  const sum = values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
  return Math.round(sum / values.length);
};

const calculateNeurochemicalsScore = (neurochemicals: any): number => {
  const values = Object.values(neurochemicals || {});
  if (values.length === 0) return 0;
  const sum = values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
  return Math.round(sum / values.length);
};

const calculateViMiAmEnScore = (viMiAmEn: any): number => {
  const categories = ['vitamins', 'minerals', 'aminoAcids', 'enzymes'];
  let totalSum = 0;
  let totalCount = 0;

  categories.forEach(category => {
    const values = Object.values(viMiAmEn?.[category] || {});
    if (values.length > 0) {
      totalSum += values.reduce((acc: number, val: any) => acc + (Number(val) || 0), 0);
      totalCount += values.length;
    }
  });

  return totalCount === 0 ? 0 : Math.round(totalSum / totalCount);
};

export const calculateHealthMetrics = (userData: any): HealthMetrics => {
  return {
    toxicity: calculateToxicityScore(userData.toxicityLevels),
    metabolic: calculateMetabolicScore(userData.metabolicSystems),
    epigenetics: calculateEpigeneticsScore(userData.epigeneticsProfile),
    hormones: calculateHormonesScore(userData.hormonePanel),
    neurochemicals: calculateNeurochemicalsScore(userData.neurochemicals),
    viMiAmEn: calculateViMiAmEnScore(userData.viMiAmEn),
  };
};

const HealthSpiderChart: React.FC<HealthSpiderChartProps> = ({ metrics }) => {
  const theme = useTheme();

  const chartData: ChartData<'radar'> = {
    labels: [
      'Toxicity Resistance',
      'Metabolic Systems',
      'Epigenetics Profile',
      'Hormone Balance',
      'Neurochemical Health',
      'Vi-Mi-Am-En Profile',
    ],
    datasets: [
      {
        label: 'Health Profile',
        data: [
          metrics.toxicity,
          metrics.metabolic,
          metrics.epigenetics,
          metrics.hormones,
          metrics.neurochemicals,
          metrics.viMiAmEn,
        ],
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
        borderColor: theme.palette.primary.main,
        borderWidth: 2,
        pointBackgroundColor: theme.palette.primary.main,
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: theme.palette.primary.main,
      },
    ],
  };

  const chartOptions: ChartOptions<'radar'> = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      r: {
        angleLines: {
          display: true
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
          callback: function(value) {
            return value + '%';
          }
        },
        pointLabels: {
          font: {
            size: 12
          }
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            return context.formattedValue + '%';
          }
        }
      }
    }
  };

  return (
    <Card sx={{
      height: '100%',
      minHeight: 400,
      borderRadius: 2,
      position: 'relative',
      overflow: 'visible',
      background: theme.palette.mode === 'dark'
        ? alpha(theme.palette.primary.main, 0.1)
        : alpha(theme.palette.primary.light, 0.1),
      '&:hover': {
        transform: 'translateY(-2px)',
        transition: 'all 0.3s'
      }
    }}>
      <CardContent sx={{ pt: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <AssessmentIcon sx={{ fontSize: 30, color: theme.palette.primary.main, mr: 1 }} />
          <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
            Health Profile Analysis
          </Typography>
          <Tooltip title="Comprehensive analysis of your health metrics across different categories">
            <IconButton size="small" sx={{ ml: 1 }}>
              <InfoIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>

        <Box sx={{ height: 300, mt: 2 }}>
          <Radar data={chartData} options={chartOptions} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default HealthSpiderChart;

import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useRole } from '../../hooks/useRole';
import { CircularProgress, Box } from '@mui/material';

interface AdminRouteProps {
  children: React.ReactNode;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ children }) => {
  console.log('Rendering AdminRoute');
  const { currentUser } = useAuth();
  const { role, loading } = useRole();

  useEffect(() => {
    console.log('AdminRoute state:', { currentUser: !!currentUser, role, loading });
  }, [currentUser, role, loading]);

  if (!currentUser) {
    console.log('No current user, redirecting to login');
    return <Navigate to="/login" />;
  }

  if (loading) {
    console.log('Loading role...');
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (role !== 'admin') {
    console.log('User is not admin, redirecting to dashboard');
    return <Navigate to="/dashboard" />;
  }

  console.log('Rendering admin content');
  return <>{children}</>;
};

export default AdminRoute;

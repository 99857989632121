import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Stack,
  Button,
  Badge,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  Notifications as NotificationsIcon,
  Info as InfoIcon,
  Close as CloseIcon,
} from '@mui/icons-material';
import { useNotifications } from '../../contexts/NotificationContext';
import { Notification } from '../../types/notification';
import { formatDistanceToNow } from 'date-fns';

const DashboardNotificationCard: React.FC = () => {
  const theme = useTheme();
  const { notifications, refreshNotifications, markAsRead } = useNotifications();
  const [selectedNotification, setSelectedNotification] = useState<Notification | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    refreshNotifications();
  }, [refreshNotifications]);

  const handleNotificationClick = (notification: Notification) => {
    setSelectedNotification(notification);
    setModalOpen(true);
    if (!notification.isRead) {
      markAsRead(notification.id);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedNotification(null);
  };

  const getNotificationColor = (type: Notification['type']) => {
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.primary.main;
    }
  };

  return (
    <>
      <Box sx={{ position: 'relative', height: '100%' }}>
        <Card 
          sx={{ 
            height: '100%',
            pt: 3,
            pb: 2,
            px: 2,
            bgcolor: theme.palette.action.hover,
            '&:hover': {
              bgcolor: theme.palette.action.hover,
              transition: 'background-color 0.3s ease'
            }
          }}
        >
          <CardContent sx={{ height: '100%', pt: 2.5, px: 2.5, pb: '16px !important' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 0.5 }}>
              <NotificationsIcon sx={{ color: theme.palette.primary.main }} />
              <Typography
                variant="h6"
                sx={{
                  fontSize: { xs: '1rem', sm: '1.1rem' },
                  fontWeight: 600,
                  color: theme.palette.text.primary,
                  lineHeight: 1.5,
                }}
              >
                Notifications
              </Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Tooltip title="Your recent notifications">
                <IconButton size="small" sx={{ color: theme.palette.primary.main }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Box>
            <Stack spacing={2} sx={{ mt: 3, height: 'calc(100% - 60px)', overflow: 'hidden' }}>
              <Box 
                sx={{ 
                  flex: 1,
                  overflow: 'auto',
                  backgroundColor: 'transparent',
                  backgroundImage: 'none',
                  boxShadow: 'none',
                }}
              >
                <Box sx={{ p: 0 }}>
                  {notifications.length === 0 ? (
                    <Box>
                      <Box sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        alignItems: 'center',
                        py: 4
                      }}>
                        <NotificationsIcon 
                          sx={{ 
                            fontSize: 48, 
                            color: theme.palette.text.secondary,
                            mb: 2
                          }} 
                        />
                        <Typography 
                          variant="body1" 
                          color="text.secondary"
                          sx={{ textAlign: 'center' }}
                        >
                          No notifications
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    notifications.slice(0, 5).map((notification) => (
                      <Box
                        key={notification.id}
                        onClick={() => handleNotificationClick(notification)}
                        sx={{
                          cursor: 'pointer',
                          borderRadius: 1,
                          mb: 1,
                          backgroundColor: notification.isRead
                            ? 'transparent'
                            : theme.palette.action.hover,
                          '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                            transform: 'translateX(4px)',
                            transition: 'all 0.2s'
                          },
                        }}
                      >
                        <Box component="div" sx={{ mb: 0.5 }}>
                          <Typography
                            component="div"
                            variant="subtitle2"
                            color={getNotificationColor(notification.type)}
                            sx={{ 
                              fontWeight: notification.isRead ? 500 : 600,
                              fontSize: '0.95rem'
                            }}
                          >
                            {notification.title}
                          </Typography>
                        </Box>
                        <Box component="div">
                          <Typography 
                            component="div"
                            variant="body2" 
                            color="text.secondary"
                            sx={{ 
                              lineHeight: 1.4,
                              fontSize: '0.875rem',
                              mb: 0.5
                            }}
                          >
                            {notification.message}
                          </Typography>
                          <Typography 
                            component="div"
                            variant="caption" 
                            color="text.secondary"
                            sx={{ 
                              display: 'block',
                              fontStyle: 'italic',
                              fontSize: '0.75rem'
                            }}
                          >
                            {formatDistanceToNow(notification.createdAt)} ago
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  )}
                </Box>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ 
          m: 0, 
          p: 2, 
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: `1px solid ${theme.palette.divider}`
        }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography variant="h6" component="div" sx={{ 
              color: selectedNotification ? getNotificationColor(selectedNotification.type) : theme.palette.text.primary
            }}>
              {selectedNotification?.title}
            </Typography>
          </Box>
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          {selectedNotification && (
            <Stack spacing={2}>
              <Typography variant="body1">
                {selectedNotification.message}
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                alignItems: 'center',
                gap: 1,
                color: theme.palette.text.secondary,
                mt: 2 
              }}>
                <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                  Received {formatDistanceToNow(selectedNotification.createdAt)} ago
                </Typography>
              </Box>
            </Stack>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
          <Button 
            onClick={handleCloseModal}
            variant="contained"
            sx={{ 
              minWidth: 100,
              bgcolor: theme.palette.primary.main,
              '&:hover': {
                bgcolor: theme.palette.primary.dark,
              }
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardNotificationCard;

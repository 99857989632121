import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LegalPageHeaderProps {
  title: string;
}

const LegalPageHeader: React.FC<LegalPageHeaderProps> = ({ title }) => {
  const theme = useTheme();

  return (
    <>
      {/* Header with Logo */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
        <Box
          component="img"
          src="/assets/images/logo.png"
          alt="Ageback Logo"
          sx={{
            height: 40,
            width: 'auto',
            mr: 2,
          }}
        />
        <Box>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            Healthboard<sup style={{ fontSize: '0.5em', verticalAlign: 'super' }}>®</sup>
          </Typography>
          <Typography
            variant="caption"
            sx={{
              color: theme.palette.text.secondary,
              fontWeight: 500,
            }}
          >
            by Ageback<sup style={{ fontSize: '0.7em', verticalAlign: 'super' }}>®</sup>
          </Typography>
        </Box>
      </Box>

      <Typography variant="h3" component="h1" gutterBottom>
        {title}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" paragraph>
        Last updated: {new Date().toLocaleDateString()}
      </Typography>
    </>
  );
};

export default LegalPageHeader;

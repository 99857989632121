import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
  Box,
  useTheme,
  alpha,
  IconButton,
  Badge,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AnalyticsIcon from '@mui/icons-material/Analytics';

export interface AdminHeaderProps {
  onLogoutClick: () => Promise<void>;
  isLoggingOut: boolean;
}

const AdminHeader: React.FC<AdminHeaderProps> = ({ onLogoutClick, isLoggingOut }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const isActive = (path: string) => location.pathname === path;

  const navigationItems = [
    { path: '/admin', label: 'Dashboard', icon: DashboardIcon },
    { path: '/admin/users', label: 'User Management', icon: PeopleIcon },
    { path: '/admin/analytics', label: 'Analytics', icon: AnalyticsIcon },
    { path: '/admin/settings', label: 'Settings', icon: SettingsIcon },
  ];

  return (
    <AppBar 
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: theme.palette.mode === 'light' 
          ? alpha(theme.palette.background.default, 0.95)
          : alpha(theme.palette.background.paper, 0.95),
        backdropFilter: 'blur(8px)',
        borderBottom: `1px solid ${theme.palette.divider}`,
        color: theme.palette.text.primary,
      }}
    >
      <Toolbar sx={{ minHeight: { xs: 64, sm: 70 } }}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            '&:hover': {
              '& .MuiTypography-root': {
                color: theme.palette.primary.main,
              },
            },
          }}
          onClick={() => navigate('/admin')}
        >
          <Box
            component="img"
            src="/bluelogo.png"
            alt="Ageback Logo"
            sx={{
              height: 40,
              width: 'auto',
              mr: 2,
              objectFit: 'contain',
            }}
          />
          <Box>
            <Typography
              variant="h5"
              component="div"
              sx={{
                fontWeight: 600,
                letterSpacing: '-0.5px',
                background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                transition: 'all 0.2s',
              }}
            >
              Admin Panel
            </Typography>
          </Box>
        </Box>

        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          gap: 1,
          ml: 4,
          '& .MuiButton-root': {
            minWidth: 120,
            transition: 'all 0.2s ease',
          }
        }}>
          {navigationItems.map((item) => {
            const Icon = item.icon;
            return (
              <Button
                key={item.path}
                onClick={() => navigate(item.path)}
                startIcon={<Icon />}
                sx={{
                  textTransform: 'none',
                  color: isActive(item.path) 
                    ? theme.palette.primary.main 
                    : theme.palette.text.secondary,
                  backgroundColor: isActive(item.path)
                    ? alpha(theme.palette.primary.main, 0.08)
                    : 'transparent',
                  fontWeight: isActive(item.path) ? 600 : 400,
                  borderRadius: '8px',
                  px: 2,
                  py: 1,
                  '&:hover': {
                    backgroundColor: isActive(item.path)
                      ? alpha(theme.palette.primary.main, 0.12)
                      : alpha(theme.palette.primary.main, 0.04),
                  },
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <IconButton
            color="inherit"
            sx={{
              backgroundColor: theme.palette.mode === 'light' 
                ? alpha(theme.palette.grey[500], 0.04)
                : alpha(theme.palette.grey[100], 0.04),
              '&:hover': {
                backgroundColor: theme.palette.mode === 'light'
                  ? alpha(theme.palette.grey[500], 0.08)
                  : alpha(theme.palette.grey[100], 0.08),
              },
            }}
          >
            <Badge badgeContent={4} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button 
            color="primary"
            variant={theme.palette.mode === 'light' ? 'contained' : 'outlined'}
            onClick={onLogoutClick}
            disabled={isLoggingOut}
            startIcon={isLoggingOut ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <LogoutIcon />
            )}
            sx={{
              borderRadius: '8px',
              textTransform: 'none',
              px: 3,
              py: 1,
              '&.Mui-disabled': {
                backgroundColor: theme.palette.mode === 'light' 
                  ? alpha(theme.palette.primary.main, 0.5)
                  : 'transparent',
              },
            }}
          >
            {isLoggingOut ? 'Logging out...' : 'Logout'}
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AdminHeader;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Chip,
  useTheme,
  alpha,
} from '@mui/material';
import {
  CardMembership as CardMembershipIcon,
  Info as InfoIcon,
  CalendarToday as CalendarIcon,
  Science as ScienceIcon,
  Inventory as InventoryIcon,
} from '@mui/icons-material';
import { format, isPast, differenceInDays } from 'date-fns';
import { Membership } from '../../../types/user';

interface MembershipCardProps {
  membership?: Membership;
}

const MembershipCard: React.FC<MembershipCardProps> = ({
  membership = {
    status: 'pending',
    startDate: new Date().toISOString(),
    expiryDate: new Date().toISOString(),
    nextLabTest: new Date().toISOString(),
    nextMonthlyBatch: new Date().toISOString(),
    type: 'basic',
    daysRemaining: 0
  }
}) => {
  const theme = useTheme();

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'active':
        return theme.palette.success.main;
      case 'expired':
        return theme.palette.error.main;
      case 'pending':
        return theme.palette.warning.main;
      default:
        return theme.palette.info.main;
    }
  };

  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), 'MMM dd, yyyy');
    } catch (error) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }
  };

  const getDaysUntil = (dateString: string) => {
    try {
      const days = differenceInDays(new Date(dateString), new Date());
      return days >= 0 ? days : 0;
    } catch (error) {
      return 0;
    }
  };

  const isExpired = isPast(new Date(membership.expiryDate));
  const displayStatus = isExpired ? 'expired' : membership.status;
  const daysUntilExpiry = getDaysUntil(membership.expiryDate);
  const statusColor = getStatusColor(displayStatus);

  return (
    <Card
      sx={{
        height: '100%',
        minHeight: 240,
        borderRadius: 2,
        background: theme.palette.mode === 'dark'
          ? alpha(theme.palette.primary.main, 0.1)
          : alpha(theme.palette.primary.light, 0.1),
        '&:hover': {
          transform: 'translateY(-2px)',
          transition: 'all 0.3s'
        }
      }}
    >
      <CardContent sx={{ height: '100%', pt: 2.5, px: 2.5, pb: '16px !important' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 0.5 }}>
          <CardMembershipIcon sx={{ color: theme.palette.primary.main }} />
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: '1rem', sm: '1.1rem' },
              fontWeight: 600,
              color: theme.palette.text.primary,
              lineHeight: 1.5,
            }}
          >
            Membership Status
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Your membership details">
            <IconButton size="small" sx={{ color: theme.palette.primary.main }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Status Badge */}
        <Box sx={{ mb: 4 }}>
          <Chip
            label={`${displayStatus.toUpperCase()} ${membership.type ? `(${membership.type})` : ''}`}
            sx={{
              bgcolor: alpha(statusColor, 0.1),
              color: statusColor,
              fontWeight: 'bold',
              borderRadius: '16px',
              px: 2
            }}
          />
          {!isExpired && daysUntilExpiry > 0 && (
            <Typography variant="caption" sx={{ ml: 1, color: 'success.main' }}>
              ({daysUntilExpiry} days left)
            </Typography>
          )}
        </Box>

        {/* Membership Details */}
        <Stack spacing={2.5}>
          {/* Start Date */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CalendarIcon sx={{ color: 'primary.main', opacity: 0.7 }} fontSize="small" />
            <Box>
              <Typography variant="body2" color="text.secondary">
                Start Date
              </Typography>
              <Typography variant="body1">
                {formatDate(membership.startDate)}
              </Typography>
            </Box>
          </Box>

          {/* Expiry Date */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <CalendarIcon sx={{ color: 'primary.main', opacity: 0.7 }} fontSize="small" />
            <Box>
              <Typography variant="body2" color="text.secondary">
                Expiry Date
              </Typography>
              <Typography variant="body1">
                {formatDate(membership.expiryDate)}
              </Typography>
            </Box>
          </Box>

          {/* Next Lab Test */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <ScienceIcon sx={{ color: 'primary.main', opacity: 0.7 }} fontSize="small" />
            <Box>
              <Typography variant="body2" color="text.secondary">
                Next Lab Test
              </Typography>
              <Typography variant="body1">
                {formatDate(membership.nextLabTest)}
              </Typography>
            </Box>
          </Box>

          {/* Next Monthly Batch */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <InventoryIcon sx={{ color: 'primary.main', opacity: 0.7 }} fontSize="small" />
            <Box>
              <Typography variant="body2" color="text.secondary">
                Next Monthly Batch
              </Typography>
              <Typography variant="body1">
                {formatDate(membership.nextMonthlyBatch)}
              </Typography>
            </Box>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default MembershipCard;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { validateProfile, ValidationError } from '../../utils/validation';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { countryCodes } from '../../utils/countryCodes';

const Profile: React.FC = () => {
  const { currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationError>({});

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: currentUser?.email || '',
    memberId: '',
    phoneCountryCode: '+1',
    phone: '',
    dateOfBirth: '',
    gender: '',
    address: '',
    country: '',
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData(prev => ({
            ...prev,
            firstName: userData.personalInfo?.firstName || '',
            lastName: userData.personalInfo?.lastName || '',
            email: userData.email || currentUser.email || '',
            memberId: userData.memberId || '',
            phoneCountryCode: userData.personalInfo?.phoneCountryCode || '+1',
            phone: userData.personalInfo?.phone || '',
            dateOfBirth: userData.personalInfo?.dateOfBirth || '',
            gender: userData.personalInfo?.gender || '',
            address: userData.personalInfo?.address || '',
            country: userData.personalInfo?.country || '',
          }));
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load profile data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any
  ) => {
    const { name, value } = e.target;
    if (!name) return;
    
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    if (validationErrors[name]) {
      setValidationErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const handleCancel = () => {
    setValidationErrors({});
    setError(null);
    setSuccess(null);
    
    // Reset form data to original user data
    const fetchUserData = async () => {
      if (!currentUser) return;
      
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setFormData({
            firstName: userData.personalInfo?.firstName || '',
            lastName: userData.personalInfo?.lastName || '',
            email: userData.email || currentUser.email || '',
            memberId: userData.memberId || '',
            phoneCountryCode: userData.personalInfo?.phoneCountryCode || '+1',
            phone: userData.personalInfo?.phone || '',
            dateOfBirth: userData.personalInfo?.dateOfBirth || '',
            gender: userData.personalInfo?.gender || '',
            address: userData.personalInfo?.address || '',
            country: userData.personalInfo?.country || '',
          });
        }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to load profile data');
      }
    };

    fetchUserData();
    setIsEditing(false);
  };

  const handleSave = async () => {
    const errors = validateProfile(formData);
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsSaving(true);
    setError(null);
    setSuccess(null);
    setValidationErrors({});

    try {
      const userRef = doc(db, 'users', currentUser!.uid);
      await updateDoc(userRef, {
        personalInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phoneCountryCode: formData.phoneCountryCode,
          phone: formData.phone,
          dateOfBirth: formData.dateOfBirth,
          gender: formData.gender,
          address: formData.address,
          country: formData.country,
        },
      });

      setSuccess('Profile updated successfully');
      setIsEditing(false);
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to update profile');
    } finally {
      setIsSaving(false);
    }
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={3}>
          <Typography variant="h5" component="h2" sx={{ flexGrow: 1 }}>
            Personal Information
          </Typography>
          {isEditing ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={() => setIsEditing(true)}
            >
              Edit Profile
            </Button>
          )}
        </Box>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              error={!!validationErrors.firstName}
              helperText={validationErrors.firstName}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              error={!!validationErrors.lastName}
              helperText={validationErrors.lastName}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Member ID"
              name="memberId"
              value={formData.memberId}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Code</InputLabel>
                  <Select
                    value={formData.phoneCountryCode}
                    name="phoneCountryCode"
                    onChange={handleInputChange}
                    disabled={!isEditing}
                    label="Code"
                  >
                    {countryCodes.map((code) => (
                      <MenuItem key={code.code} value={code.code}>
                        {code.code} ({code.country})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  error={!!validationErrors.phone}
                  helperText={validationErrors.phone}
                  disabled={!isEditing}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              value={formData.dateOfBirth}
              onChange={handleInputChange}
              error={!!validationErrors.dateOfBirth}
              helperText={validationErrors.dateOfBirth}
              disabled={!isEditing}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Gender"
              name="gender"
              select
              value={formData.gender}
              onChange={handleInputChange}
              error={!!validationErrors.gender}
              helperText={validationErrors.gender}
              disabled={!isEditing}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
              <MenuItem value="prefer-not-to-say">Prefer not to say</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="address"
              multiline
              rows={3}
              value={formData.address}
              onChange={handleInputChange}
              error={!!validationErrors.address}
              helperText={validationErrors.address}
              disabled={!isEditing}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              error={!!validationErrors.country}
              helperText={validationErrors.country}
              disabled={!isEditing}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Profile;

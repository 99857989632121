import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Alert,
} from '@mui/material';
import { Membership } from '../../types/user';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface EditMembershipDialogProps {
  open: boolean;
  onClose: () => void;
  membership: Membership | undefined;
  onSave: (membership: Membership) => Promise<void>;
  userId: string;
}

const EditMembershipDialog: React.FC<EditMembershipDialogProps> = ({
  open,
  onClose,
  membership,
  onSave,
  userId,
}) => {
  const [editedMembership, setEditedMembership] = useState<Membership>(
    membership || {
      startDate: new Date().toISOString(),
      expiryDate: new Date().toISOString(),
      nextLabTest: new Date().toISOString(),
      nextMonthlyBatch: new Date().toISOString(),
      status: 'pending',
    }
  );
  const [error, setError] = useState<string>('');

  const handleSave = async () => {
    try {
      await onSave(editedMembership);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save membership details');
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Edit Membership Details</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {error && <Alert severity="error">{error}</Alert>}
          
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Start Date"
              value={new Date(editedMembership.startDate)}
              onChange={(newValue) => {
                if (newValue) {
                  setEditedMembership({
                    ...editedMembership,
                    startDate: newValue.toISOString(),
                  });
                }
              }}
              sx={{ width: '100%', mb: 2 }}
            />

            <DatePicker
              label="Expiry Date"
              value={new Date(editedMembership.expiryDate)}
              onChange={(newValue) => {
                if (newValue) {
                  setEditedMembership({
                    ...editedMembership,
                    expiryDate: newValue.toISOString(),
                  });
                }
              }}
              sx={{ width: '100%', mb: 2 }}
            />

            <DatePicker
              label="Next Lab Test"
              value={new Date(editedMembership.nextLabTest)}
              onChange={(newValue) => {
                if (newValue) {
                  setEditedMembership({
                    ...editedMembership,
                    nextLabTest: newValue.toISOString(),
                  });
                }
              }}
              sx={{ width: '100%', mb: 2 }}
            />

            <DatePicker
              label="Next Monthly Batch"
              value={new Date(editedMembership.nextMonthlyBatch)}
              onChange={(newValue) => {
                if (newValue) {
                  setEditedMembership({
                    ...editedMembership,
                    nextMonthlyBatch: newValue.toISOString(),
                  });
                }
              }}
              sx={{ width: '100%', mb: 2 }}
            />
          </LocalizationProvider>

          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={editedMembership.status}
              label="Status"
              onChange={(e) => setEditedMembership({
                ...editedMembership,
                status: e.target.value as Membership['status'],
              })}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { EditMembershipDialog };

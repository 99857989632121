import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { User, PersonalInfo } from '../../types/user';
import { countryCodes } from '../../utils/countryCodes';

interface EditUserDialogProps {
  open: boolean;
  onClose: () => void;
  user: User | null;
  onSave: (updatedData: Partial<User>) => Promise<void>;
}

export const EditUserDialog: React.FC<EditUserDialogProps> = ({
  open,
  onClose,
  user,
  onSave,
}) => {
  const [formData, setFormData] = useState<Partial<User>>({
    personalInfo: {
      firstName: '',
      lastName: '',
      phone: '',
      phoneCountryCode: '',
      dateOfBirth: '',
      gender: '',
      address: '',
      country: '',
    } as PersonalInfo,
    email: '',
    memberId: '',
    approved: false,
    displayName: '',
  });

  useEffect(() => {
    if (user) {
      const personalInfo: PersonalInfo = {
        firstName: user.personalInfo?.firstName || '',
        lastName: user.personalInfo?.lastName || '',
        phone: user.personalInfo?.phone || '',
        phoneCountryCode: user.personalInfo?.phoneCountryCode || '',
        dateOfBirth: user.personalInfo?.dateOfBirth || '',
        gender: user.personalInfo?.gender || '',
        address: user.personalInfo?.address || '',
        country: user.personalInfo?.country || '',
      };

      setFormData({
        personalInfo,
        email: user.email || '',
        memberId: user.memberId || '',
        approved: user.approved || false,
        displayName: user.displayName || '',
      });
    }
  }, [user]);

  const handleChange = (field: string, value: any) => {
    if (field.startsWith('personalInfo.')) {
      const personalInfoField = field.split('.')[1] as keyof PersonalInfo;
      setFormData(prev => {
        const updatedPersonalInfo: PersonalInfo = {
          ...(prev.personalInfo || {
            firstName: '',
            lastName: '',
            phone: '',
            phoneCountryCode: '',
            dateOfBirth: '',
            gender: '',
            address: '',
            country: '',
          }),
          [personalInfoField]: value
        } as PersonalInfo;

        return {
          ...prev,
          personalInfo: updatedPersonalInfo
        };
      });
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value
      }));
    }
  };

  const handleSave = async () => {
    try {
      await onSave({
        ...formData,
        personalInfo: {
          ...formData.personalInfo,
          firstName: formData.personalInfo?.firstName || '',
          lastName: formData.personalInfo?.lastName || '',
          phone: formData.personalInfo?.phone || '',
          phoneCountryCode: formData.personalInfo?.phoneCountryCode || '',
          dateOfBirth: formData.personalInfo?.dateOfBirth || '',
          gender: formData.personalInfo?.gender || '',
          address: formData.personalInfo?.address || '',
          country: formData.personalInfo?.country || '',
        },
      });
      onClose();
    } catch (error) {
      console.error('Error saving user:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit User</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="First Name"
              name="personalInfo.firstName"
              value={formData.personalInfo?.firstName}
              onChange={(e) => handleChange('personalInfo.firstName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="personalInfo.lastName"
              value={formData.personalInfo?.lastName}
              onChange={(e) => handleChange('personalInfo.lastName', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              value={formData.email}
              onChange={(e) => handleChange('email', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Member ID"
              name="memberId"
              value={formData.memberId}
              onChange={(e) => handleChange('memberId', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel>Code</InputLabel>
                  <Select
                    value={formData.personalInfo?.phoneCountryCode}
                    name="personalInfo.phoneCountryCode"
                    onChange={(e) => handleChange('personalInfo.phoneCountryCode', e.target.value)}
                    label="Code"
                  >
                    {countryCodes.map((code) => (
                      <MenuItem key={code.code} value={code.code}>
                        {code.code}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  label="Phone"
                  name="personalInfo.phone"
                  value={formData.personalInfo?.phone}
                  onChange={(e) => handleChange('personalInfo.phone', e.target.value)}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="personalInfo.dateOfBirth"
              type="date"
              value={formData.personalInfo?.dateOfBirth}
              onChange={(e) => handleChange('personalInfo.dateOfBirth', e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Gender</InputLabel>
              <Select
                value={formData.personalInfo?.gender}
                name="personalInfo.gender"
                onChange={(e) => handleChange('personalInfo.gender', e.target.value)}
                label="Gender"
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Address"
              name="personalInfo.address"
              multiline
              rows={3}
              value={formData.personalInfo?.address}
              onChange={(e) => handleChange('personalInfo.address', e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Country"
              name="personalInfo.country"
              value={formData.personalInfo?.country}
              onChange={(e) => handleChange('personalInfo.country', e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.approved}
                  onChange={(e) => handleChange('approved', e.target.checked)}
                  name="approved"
                />
              }
              label="Approved"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  User,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase/firebaseConfig';

interface AuthContextType {
  currentUser: User | null;
  isAdmin: boolean;
  isApproved: boolean;
  loading: boolean;
  login: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      console.log('Auth state changed:', user?.email);
      setCurrentUser(user);
      
      if (user) {
        try {
          console.log('Checking user role and approval status for:', user.email);
          const roleDocRef = doc(db, 'roles', user.uid);
          const userDocRef = doc(db, 'users', user.uid);
          
          const [roleDoc, userDoc] = await Promise.all([
            getDoc(roleDocRef),
            getDoc(userDocRef)
          ]);
          
          console.log('Role document exists:', roleDoc.exists(), 'at path:', roleDocRef.path);
          console.log('User document exists:', userDoc.exists(), 'at path:', userDocRef.path);
          
          if (roleDoc.exists() && userDoc.exists()) {
            const roleData = roleDoc.data();
            const userData = userDoc.data();
            
            console.log('Role data:', roleData);
            console.log('User data:', userData);
            
            const isUserAdmin = roleData.role === 'admin';
            const isUserApproved = userData.approved === true;
            
            setIsAdmin(isUserAdmin);
            setIsApproved(isUserApproved);
            
            console.log('User authorization status:', {
              email: user.email,
              isAdmin: isUserAdmin,
              isApproved: isUserApproved,
              roleType: roleData.role
            });
          } else {
            console.warn('Missing documents for user:', user.email, {
              roleExists: roleDoc.exists(),
              userExists: userDoc.exists()
            });
            setIsAdmin(false);
            setIsApproved(false);
          }
        } catch (error) {
          console.error('Error checking user authorization:', error);
          setIsAdmin(false);
          setIsApproved(false);
        }
      } else {
        console.log('No user, resetting authorization states');
        setIsAdmin(false);
        setIsApproved(false);
      }
      
      setLoading(false);
    });

    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
  }, []);

  const login = async (email: string, password: string) => {
    console.log('Attempting login for:', email);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Firebase authentication successful for:', email);
      
      const userDocRef = doc(db, 'users', userCredential.user.uid);
      const userDoc = await getDoc(userDocRef);
      
      console.log('User document exists:', userDoc.exists(), 'at path:', userDocRef.path);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log('User data after login:', userData);
        
        if (!userData.approved) {
          console.warn('Login rejected - user not approved:', email);
          await firebaseSignOut(auth);
          throw new Error('Your account is pending approval. Please wait for admin approval.');
        }
        console.log('Login successful - user is approved:', email);
      } else {
        console.error('Login rejected - user document not found:', email);
        await firebaseSignOut(auth);
        throw new Error('User profile not found. Please contact support.');
      }
    } catch (error: any) {
      console.error('Login error:', {
        email,
        errorCode: error.code,
        errorMessage: error.message
      });
      throw error;
    }
  };

  const signOut = async () => {
    try {
      const email = currentUser?.email;
      console.log('Signing out user:', email);
      await firebaseSignOut(auth);
      console.log('Sign out successful for:', email);
    } catch (error) {
      console.error('Sign out error:', error);
      throw error;
    }
  };

  const value = {
    currentUser,
    isAdmin,
    isApproved,
    loading,
    login,
    signOut
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

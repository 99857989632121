import React, { useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
  Alert,
  Divider,
  Stack,
  LinearProgress,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  useTheme,
  alpha,
  Container,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  Notifications as NotificationsIcon,
  Star as StarIcon,
  ErrorOutline as ErrorOutlineIcon,
  Warning as WarningIcon,
  Timeline as TimelineIcon,
  Info as InfoIcon,
} from '@mui/icons-material';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useAuth } from '../../contexts/AuthContext';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import { UserHealthData } from '../../types/healthData';
import { EnhancedUserHealthData, ScoreMetric } from '../../types/dashboardTypes';
import { Membership } from '../../types/user';

import MetricsCard from './MetricsCard';
import ToxicityChart from './charts/ToxicityChart';
import MetabolicChart from './charts/MetabolicChart';
import BloodPanelChart from './charts/BloodPanelChart';
import HormonePanelChart from './charts/HormonePanelChart';
import HormoneGroupsChart from './charts/HormoneGroupsChart';
import NeurochemicalsTable from './charts/NeurochemicalsTable';
import ViMiAmEnChart from './charts/ViMiAmEnChart';
import ProfileSection from './ProfileSection';
import SettingsSection from './SettingsSection';
import HealthSpiderChart, { calculateHealthMetrics } from './cards/HealthSpiderChart';
import DashboardNotificationCard from '../notifications/DashboardNotificationCard';
import AgebackScoreCard from './cards/AgebackScoreCard';
import SummaryCard from './cards/SummaryCard';
import MembershipCard from './cards/MembershipCard';
import EpigeneticsAreaChart from './cards/EpigeneticsAreaChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

const calculateLifestyleScore = (userData: any): number => {
  // Simplified calculation based on available data
  const viMiAmEn = userData.viMiAmEn || {};
  const vitaminsScore = Object.values(viMiAmEn.vitamins || {}).reduce((sum: number, val: any) => sum + (Number(val) || 0), 0) / 6;
  const mineralsScore = Object.values(viMiAmEn.minerals || {}).reduce((sum: number, val: any) => sum + (Number(val) || 0), 0) / 5;
  return Math.round((vitaminsScore + mineralsScore) / 2);
};

const calculateEnvironmentalScore = (userData: any): number => {
  // Simplified calculation based on toxicity levels
  const toxicity = userData.toxicityLevels || {};
  const toxicityScore = Object.values(toxicity).reduce((sum: number, val: any) => sum + (Number(val) || 0), 0);
  return Math.round(100 - (toxicityScore / 6)); // Inverse of average toxicity
};

const calculateMetabolicScore = (userData: any): number => {
  // Simplified calculation based on metabolic systems
  const metabolic = userData.metabolicSystems || {};
  const metabolicScore = Object.values(metabolic).reduce((sum: number, val: any) => sum + (Number(val) || 0), 0);
  return Math.round(metabolicScore / 8); // Average of all systems
};

const calculateMetricWithTrend = (userData: any, metric: string): ScoreMetric => {
  const currentYear = new Date().getFullYear();
  const currentYearScore = userData.agebackScores?.[`score${currentYear}`] || { high: 0, low: 0 };
  const previousYearScore = userData.agebackScores?.[`score${currentYear - 1}`] || { high: 0, low: 0 };

  const currentOverall = (currentYearScore.high + currentYearScore.low) / 2;
  const previousOverall = (previousYearScore.high + previousYearScore.low) / 2;
  const improvement = currentOverall - previousOverall;

  let value = 0;
  switch (metric) {
    case 'lifestyle':
      value = userData.agebackScore?.lifestyle || calculateLifestyleScore(userData);
      break;
    case 'environmental':
      value = userData.agebackScore?.environmental || calculateEnvironmentalScore(userData);
      break;
    case 'metabolic':
      value = userData.agebackScore?.metabolic || calculateMetabolicScore(userData);
      break;
    default:
      break;
  }

  let trend: 'up' | 'down' | 'stable';
  if (improvement > 0) {
    trend = 'up';
  } else if (improvement < 0) {
    trend = 'down';
  } else {
    trend = 'stable';
  }

  return {
    value,
    trend,
    change: Math.round(improvement),
  };
};

const calculateHighestEverScore = (agebackScores: any): number | null => {
  if (!agebackScores || typeof agebackScores !== 'object') return null;
  
  // Extract all high scores from the agebackScores object
  const highScores = Object.values(agebackScores)
    .map((score: any) => score?.high)
    .filter((score): score is number => score !== null && !isNaN(score));
  
  // Find the maximum score
  return highScores.length > 0 ? Math.max(...highScores) : null;
};

const Dashboard: React.FC = () => {
  const theme = useTheme();
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserHealthData | null>(null);
  const [enhancedData, setEnhancedData] = useState<EnhancedUserHealthData | null>(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser) {
        console.log('No current user, setting loading to false');
        setLoading(false);
        return;
      }

      try {
        console.log('Starting to fetch user data for:', currentUser.uid);
        // Set up real-time listener for user document
        const unsubscribe = onSnapshot(doc(db, 'users', currentUser.uid), (snapshot) => {
          console.log('Snapshot received:', snapshot.exists());
          
          if (snapshot.exists()) {
            const data = snapshot.data() as any;
            console.log('Raw data received:', data);
            
            try {
              // Debug logging for health summary and membership
              console.log('Health Summary from healthData:', data.healthData?.healthSummary);
              console.log('Health Summary direct:', data.healthData?.healthSummary);

              // Create the UserHealthData object with all required fields
              const updatedUserData: UserHealthData = {
                userId: currentUser.uid,
                email: data.email || currentUser.email || '',
                name: data.name || '',
                lastUpdated: data.lastUpdated || new Date().toISOString(),
                notifications: Array.isArray(data.notifications) ? data.notifications : [],
                agebackScore: {
                  overall: data.healthData?.agebackScore?.overall ?? null,
                  biological: data.healthData?.agebackScore?.biological ?? null,
                  lifestyle: data.healthData?.agebackScore?.lifestyle ?? null,
                  environmental: data.healthData?.agebackScore?.environmental ?? null,
                  metabolic: data.healthData?.agebackScore?.metabolic ?? null,
                  improvement: data.healthData?.agebackScore?.improvement ?? null,
                  chronological: data.healthData?.agebackScore?.chronological ?? null
                },
                personalInfo: data.healthData?.personalInfo || {
                  firstName: '',
                  lastName: '',
                  email: data.email || '',
                  age: null,
                  gender: '',
                  height: '',
                  weight: '',
                  joinDate: new Date().toISOString(),
                },
                healthSummary: data.healthData?.healthSummary || {
                  keyFindings: [],
                  recommendations: [],
                  criticalPoints: [],
                  lastUpdated: new Date().toISOString()
                },
                agebackScores: data.healthData?.agebackScores || {},
                agebackTrend: data.healthData?.agebackTrend || {
                  labels: [],
                  datasets: [],
                },
                healthRisks: data.healthData?.healthRisks || {
                  shortTerm: [],
                  midTerm: [],
                  longTerm: [],
                },
                bloodPanel: data.healthData?.bloodPanel || {
                  hemoglobin: 0,
                  whiteBloodCells: 0,
                  platelets: 0,
                  redBloodCells: 0,
                  hematocrit: 0,
                  mcv: 0,
                  mch: 0,
                  mchc: 0,
                },
                hormonePanel: data.healthData?.hormonePanel || {
                  growthHormone: 0,
                  igf1: 0,
                  thyroidHormones: 0,
                  insulin: 0,
                  glucagon: 0,
                  leptin: 0,
                  cortisol: 0,
                  adrenaline: 0,
                  noradrenaline: 0,
                  testosterone: 0,
                  estrogen: 0,
                  progesterone: 0,
                  parathyroidHormone: 0,
                  aldosterone: 0,
                  vasopressin: 0,
                  melatonin: 0,
                  calcitonin: 0,
                  somatostatin: 0,
                  dhea: 0,
                },
                toxicityLevels: data.healthData?.toxicityLevels || {
                  hepatotoxicity: null,
                  immunotoxicity: null,
                  nephrotoxicity: null,
                  neurotoxicity: null,
                  reproductiveToxicity: null,
                  respiratoryToxicity: null,
                },
                epigeneticsProfile: data.healthData?.epigeneticsProfile || {
                  methylation: 0,
                  telomeres: 0,
                  histones: 0,
                  chromatin: 0,
                },
                metabolicSystems: data.healthData?.metabolicSystems || {
                  digestive: null,
                  immune: null,
                  nervous: null,
                  circulatory: null,
                  respiratory: null,
                  endocrine: null,
                  lymphatic: null,
                  musculoskeletal: null,
                },
                viMiAmEn: data.healthData?.viMiAmEn || {
                  vitamins: {
                    A: 0,
                    B: 0,
                    C: 0,
                    D: 0,
                    E: 0,
                    K: 0,
                  },
                  minerals: {
                    calcium: 0,
                    magnesium: 0,
                    zinc: 0,
                    iron: 0,
                    potassium: 0,
                  },
                  aminoAcids: {
                    leucine: 0,
                    isoleucine: 0,
                    valine: 0,
                    lysine: 0,
                    methionine: 0,
                  },
                  enzymes: {
                    digestive: 0,
                    metabolic: 0,
                    antioxidant: 0,
                    repair: 0,
                  },
                },
                neurochemicals: data.healthData?.neurochemicals || {
                  serotonin: 0,
                  dopamine: 0,
                  norepinephrine: 0,
                  gaba: 0,
                  glutamate: 0,
                  acetylcholine: 0,
                  endorphins: 0,
                },
                membership: data.healthData?.membership || {
                  status: 'pending',
                  startDate: new Date().toISOString(),
                  expiryDate: new Date().toISOString(),
                  nextLabTest: new Date().toISOString(),
                  nextMonthlyBatch: new Date().toISOString(),
                  type: 'basic',
                  daysRemaining: 0,
                },
                healthGoals: Array.isArray(data.healthData?.healthGoals) ? data.healthData.healthGoals : [],
              };

              console.log('UserData transformed successfully');
              setUserData(updatedUserData);
              
              const enhancedData: EnhancedUserHealthData = {
                ...updatedUserData,
                metrics: {
                  lifestyle: calculateMetricWithTrend(data, 'lifestyle'),
                  environmental: calculateMetricWithTrend(data, 'environmental'),
                  metabolic: calculateMetricWithTrend(data, 'metabolic'),
                },
                epigenetics: data.healthData?.epigeneticsProfile || {},
                epigeneticsProfile: data.healthData?.epigeneticsProfile || {},
              };
              
              console.log('EnhancedData created successfully');
              setEnhancedData(enhancedData);
              
            } catch (transformError) {
              console.error('Error transforming data:', transformError);
              setError('Error processing user data');
            }
          } else {
            console.log('No user document found');
            setError('User data not found');
          }
          
          console.log('Setting loading to false');
          setLoading(false);
        }, (snapshotError) => {
          console.error('Snapshot error:', snapshotError);
          setError('Error listening to user data');
          setLoading(false);
        });

        return () => {
          console.log('Cleaning up snapshot listener');
          unsubscribe();
        };
      } catch (err) {
        console.error('Error in fetchUserData:', err);
        setError('Failed to fetch user data');
        setLoading(false);
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleSettingsSave = () => {
    setRefreshTrigger((prev) => prev + 1);
  };

  const getMembershipStatus = (membership: Membership): 'active' | 'expired' | 'pending' => {
    if (!membership?.status) return 'pending';
    switch (membership.status.toLowerCase()) {
      case 'active':
        return 'active';
      case 'expired':
        return 'expired';
      case 'pending':
        return 'pending';
      default:
        return 'pending';
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!userData || !enhancedData) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="warning">No user data available</Alert>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: { xs: 0, sm: 0 },
        pt: 0,
        mt: 0,
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="xl" sx={{ pb: { xs: 8, sm: 3 }, pt: { xs: 0.5, sm: 0.75 }, mt: 0 }}>
        <Grid container spacing={{ xs: 1, sm: 1.5 }} sx={{ mt: 0 }}>
          {/* Main Content */}
          <Grid item xs={12}>
            <Grid container spacing={{ xs: 1, sm: 1.5 }}>
              {/* Ageback Score Card */}
              <Grid item xs={12} sm={6} md={3}>
                <AgebackScoreCard
                  overall={enhancedData.agebackScore.overall}
                  chronological={enhancedData.agebackScore.chronological}
                  highestEver={calculateHighestEverScore(userData.agebackScores)}
                />
              </Grid>

              {/* Summary Card */}
              <Grid item xs={12} sm={6} md={3}>
                {userData.healthSummary ? (
                  <SummaryCard healthSummary={userData.healthSummary} />
                ) : (
                  <Card
                    sx={{
                      height: '100%',
                      minHeight: 200,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography>No health summary available</Typography>
                  </Card>
                )}
              </Grid>

              {/* Membership Card */}
              <Grid item xs={12} sm={6} md={3}>
                {userData?.membership && (
                  <MembershipCard membership={userData.membership} />
                )}
              </Grid>

              {/* Notifications Card */}
              <Grid item xs={12} sm={6} md={3}>
                <DashboardNotificationCard />
              </Grid>
            </Grid>
          </Grid>

          {/* Charts Section */}
          <Grid item xs={12} sx={{ mt: { xs: 2, sm: 3 } }}>
            <Grid container spacing={{ xs: 2, sm: 3 }}>
              {/* Score Trend */}
              <Grid item xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: '100%',
                    minHeight: 480,
                    borderRadius: 2,
                    boxShadow: theme.shadows[3],
                    '&:hover': {
                      boxShadow: theme.shadows[6],
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s',
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      <TimelineIcon color="primary" />
                      Score Trend
                    </Typography>
                    <Box sx={{ height: 384 }}>
                      <Line
                        data={{
                          labels: ['2020', '2021', '2022', '2023', '2024', '2025', '2026'].filter(year => {
                            const yearData = userData.agebackScores?.[`score${year}`];
                            const highScore = yearData?.high ?? 0;
                            const lowScore = yearData?.low ?? 0;
                            return highScore > 0 || lowScore > 0;
                          }),
                          datasets: [
                            {
                              label: 'Highest Score',
                              data: ['2020', '2021', '2022', '2023', '2024', '2025', '2026']
                                .filter(year => {
                                  const yearData = userData.agebackScores?.[`score${year}`];
                                  const highScore = yearData?.high ?? 0;
                                  const lowScore = yearData?.low ?? 0;
                                  return highScore > 0 || lowScore > 0;
                                })
                                .map((year) => userData.agebackScores?.[`score${year}`]?.high ?? 0),
                              borderColor: theme.palette.success.main,
                              backgroundColor: alpha(theme.palette.success.main, 0.1),
                              borderWidth: 2,
                              tension: 0.4,
                              fill: false,
                              pointRadius: 4,
                              pointBackgroundColor: theme.palette.success.main,
                            },
                            {
                              label: 'Lowest Score',
                              data: ['2020', '2021', '2022', '2023', '2024', '2025', '2026']
                                .filter(year => {
                                  const yearData = userData.agebackScores?.[`score${year}`];
                                  const highScore = yearData?.high ?? 0;
                                  const lowScore = yearData?.low ?? 0;
                                  return highScore > 0 || lowScore > 0;
                                })
                                .map((year) => userData.agebackScores?.[`score${year}`]?.low ?? 0),
                              borderColor: theme.palette.warning.main,
                              backgroundColor: alpha(theme.palette.warning.main, 0.1),
                              borderWidth: 2,
                              tension: 0.4,
                              fill: false,
                              pointRadius: 4,
                              pointBackgroundColor: theme.palette.warning.main,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          plugins: {
                            legend: {
                              display: true,
                              position: 'top' as const,
                              labels: {
                                boxWidth: 10,
                                usePointStyle: true,
                                pointStyle: 'circle',
                              },
                            },
                            tooltip: {
                              mode: 'index' as const,
                              intersect: false,
                              callbacks: {
                                label: (context: any) => `${context.dataset.label}: ${context.parsed.y}`,
                                title: (tooltipItems: any) => `Year: ${tooltipItems[0].label}`,
                              },
                              backgroundColor: alpha(theme.palette.background.paper, 0.9),
                              titleColor: theme.palette.text.primary,
                              bodyColor: theme.palette.text.secondary,
                              borderColor: theme.palette.divider,
                              borderWidth: 1,
                              padding: 12,
                              titleFont: {
                                size: 14,
                                weight: 'bold' as const,
                              },
                              bodyFont: {
                                size: 13,
                              },
                            },
                          },
                          scales: {
                            y: {
                              min: 50,
                              max: 100,
                              grid: {
                                color: alpha(theme.palette.text.secondary, 0.1),
                              },
                              ticks: {
                                stepSize: 1,
                              },
                            },
                            x: {
                              grid: {
                                display: false,
                              },
                              ticks: {
                                font: {
                                  size: 12,
                                },
                              },
                            },
                          },
                          interaction: {
                            mode: 'index' as const,
                            intersect: false,
                          },
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Health Risks */}
              <Grid item xs={12} sm={6} md={6}>
                <HealthSpiderChart metrics={calculateHealthMetrics(userData)} />
              </Grid>

              {/* Toxicity Levels */}
              <Grid item xs={12} sm={6} md={6}>
                <ToxicityChart data={userData.toxicityLevels} />
              </Grid>

              {/* Metabolic Systems */}
              <Grid item xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: '100%',
                    minHeight: 480,
                    borderRadius: 2,
                    boxShadow: theme.shadows[3],
                    '&:hover': {
                      boxShadow: theme.shadows[6],
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s',
                    },
                  }}
                >
                  <CardContent sx={{ pb: '8px !important', height: '100%' }}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: theme.palette.text.primary,
                        mb: 1,
                      }}
                    >
                      <TimelineIcon color="success" />
                      Metabolic Systems
                    </Typography>
                    <Box
                      sx={{
                        height: 'calc(100% - 40px)',
                        '& canvas': {
                          height: '100% !important',
                        },
                      }}
                    >
                      <MetabolicChart data={userData.metabolicSystems} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Epigenetics Area Chart */}
              <Grid item xs={12} sm={6} md={6}>
                <EpigeneticsAreaChart
                  data={enhancedData?.epigeneticsProfile || {
                    methylation: 0,
                    telomeres: 0,
                    histones: 0,
                    chromatin: 0,
                  }}
                />
              </Grid>

              {/* Hormone Groups */}
              <Grid item xs={12} sm={6} md={6}>
                <Card
                  sx={{
                    height: '100%',
                    minHeight: 480,
                    borderRadius: 2,
                    boxShadow: theme.shadows[3],
                    '&:hover': {
                      boxShadow: theme.shadows[6],
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s',
                    },
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        color: theme.palette.text.primary,
                      }}
                    >
                      <TimelineIcon color="secondary" />
                      Hormone Groups
                    </Typography>
                    <Box sx={{ height: 384 }}>
                      <HormoneGroupsChart data={userData.hormonePanel} />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>

              {/* Neurochemicals Table */}
              <Grid item xs={12} sm={6} md={6}>
                <NeurochemicalsTable data={userData.neurochemicals} />
              </Grid>

              {/* Vi-Mi-Am-En Profile */}
              <Grid item xs={12} sm={6} md={6}>
                <ViMiAmEnChart data={userData.viMiAmEn} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;

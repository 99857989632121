import React, { createContext, useContext, useState, useEffect } from 'react';
import { Notification } from '../types/notification';
import { notificationService } from '../services/notificationService';
import { useAuth } from './AuthContext';

interface NotificationContextType {
  notifications: Notification[];
  unreadCount: number;
  markAsRead: (notificationId: string) => Promise<void>;
  refreshNotifications: () => Promise<void>;
}

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (!currentUser) {
      setNotifications([]);
      return;
    }

    console.log('Setting up notifications for user:', currentUser.uid);

    // Initial fetch
    notificationService.getUserNotifications(currentUser.uid)
      .then(initialNotifications => {
        console.log('Initial notifications:', initialNotifications);
        setNotifications(initialNotifications);
      })
      .catch(error => {
        console.error('Error fetching initial notifications:', error);
      });

    // Subscribe to notifications
    const unsubscribe = notificationService.subscribeToUserNotifications(
      currentUser.uid,
      (updatedNotifications) => {
        console.log('Received notification update:', updatedNotifications);
        setNotifications(updatedNotifications);
      }
    );

    return () => {
      console.log('Cleaning up notification subscription');
      unsubscribe();
    };
  }, [currentUser]);

  const markAsRead = async (notificationId: string) => {
    await notificationService.markAsRead(notificationId);
  };

  const refreshNotifications = async () => {
    if (!currentUser) return;
    const userNotifications = await notificationService.getUserNotifications(currentUser.uid);
    setNotifications(userNotifications);
  };

  const unreadCount = notifications.filter((n) => !n.isRead).length;

  const value = {
    notifications,
    unreadCount,
    markAsRead,
    refreshNotifications,
  };

  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const context = useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotifications must be used within a NotificationProvider');
  }
  return context;
};

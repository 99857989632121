import React, { useEffect } from 'react';
import {
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  useTheme,
  Typography,
  Box,
  alpha,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { formatDistanceToNow } from '../../utils/dateUtils';
import { useNotifications } from '../../contexts/NotificationContext';
import { Notification } from '../../types/notification';
import { useNavigate } from 'react-router-dom';

const NotificationCard: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { notifications, unreadCount, markAsRead, refreshNotifications } = useNotifications();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    // Refresh notifications when component mounts
    refreshNotifications();
  }, [refreshNotifications]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('Opening notifications popover');
    console.log('Current notifications:', notifications);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = async (notification: Notification) => {
    console.log('Clicked notification:', notification);
    if (!notification.isRead) {
      await markAsRead(notification.id);
    }
    if (notification.link) {
      navigate(notification.link);
    }
    handleClose();
  };

  const getNotificationColor = (type: Notification['type']) => {
    switch (type) {
      case 'error':
        return theme.palette.error.main;
      case 'warning':
        return theme.palette.warning.main;
      case 'success':
        return theme.palette.success.main;
      default:
        return theme.palette.info.main;
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <>
      <IconButton 
        onClick={handleClick} 
        color="inherit"
        sx={{
          position: 'relative',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'light'
              ? 'rgba(0, 0, 0, 0.04)'
              : 'rgba(255, 255, 255, 0.08)',
          },
        }}
      >
        <Badge 
          badgeContent={unreadCount} 
          color="error"
          sx={{
            '& .MuiBadge-badge': {
              right: -3,
              top: 3,
            },
          }}
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            mt: 1,
            boxShadow: theme.shadows[8],
            borderRadius: '12px',
          },
        }}
      >
        <List sx={{ 
          width: 320, 
          maxHeight: 400, 
          overflow: 'auto',
          p: 0,
        }}>
          {notifications.length === 0 ? (
            <ListItem>
              <ListItemText 
                primary={
                  <Typography 
                    variant="body1" 
                    color="text.secondary"
                    sx={{ textAlign: 'center', py: 2 }}
                  >
                    No notifications
                  </Typography>
                } 
              />
            </ListItem>
          ) : (
            notifications.map((notification) => (
              <ListItem
                key={notification.id}
                onClick={() => handleNotificationClick(notification)}
                sx={{
                  cursor: 'pointer',
                  borderBottom: `1px solid ${theme.palette.divider}`,
                  backgroundColor: notification.isRead
                    ? 'transparent'
                    : alpha(theme.palette.primary.main, 0.04),
                  '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                  },
                  '&:last-child': {
                    borderBottom: 'none',
                  },
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ mb: 0.5 }}>
                      <Typography
                        variant="subtitle2"
                        color={getNotificationColor(notification.type)}
                        sx={{ fontWeight: notification.isRead ? 400 : 600 }}
                      >
                        {notification.title}
                      </Typography>
                    </Box>
                  }
                  secondary={
                    <>
                      <Typography 
                        variant="body2" 
                        color="text.secondary"
                        sx={{ mb: 0.5 }}
                      >
                        {notification.message}
                      </Typography>
                      <Typography 
                        variant="caption" 
                        color="text.secondary"
                        sx={{ 
                          display: 'block',
                          fontStyle: 'italic'
                        }}
                      >
                        {formatDistanceToNow(notification.createdAt)} ago
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))
          )}
        </List>
      </Popover>
    </>
  );
};

export default NotificationCard;

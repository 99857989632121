import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Switch,
  FormControlLabel,
  IconButton,
  Snackbar,
  Alert,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AssessmentIcon from '@mui/icons-material/Assessment';
import LockResetIcon from '@mui/icons-material/LockReset';
import { Add as AddIcon } from '@mui/icons-material';

import { collection, getDocs, doc, deleteDoc, updateDoc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/firebaseConfig';
import SendNotification from './SendNotification';
import { User, UserHealthData, Membership, HealthSummary } from '../../types/user';
import { ExtendedUserHealthData } from '../../types/extendedHealthData';
import EditUserHealthDialog from './EditUserHealthDialog';
import ManageUserAppointments from './ManageUserAppointments';
import { EditMembershipDialog } from './EditMembershipDialog';
import { EditHealthSummaryDialog } from './EditHealthSummaryDialog';
import { UserActionButtons } from './UserActionButtons';
import ResetPasswordDialog from './ResetPasswordDialog';
import UserSyncDialog from './UserSyncDialog';
import AddUserDialog from './AddUserDialog';
import { deleteUser } from '../../utils/deleteUser';
import { EditUserDialog } from './EditUserDialog';

interface EditUserData {
  name: string;
  email: string;
  approved: boolean;
}

const UserManagement: React.FC = () => {
  console.error('DEBUG: UserManagement component rendering START');

  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editHealthDialogOpen, setEditHealthDialogOpen] = useState(false);
  const [membershipDialogOpen, setMembershipDialogOpen] = useState(false);
  const [healthSummaryDialogOpen, setHealthSummaryDialogOpen] = useState(false);
  const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);
  const [resetPasswordDialogOpen, setResetPasswordDialogOpen] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [userHealthData, setUserHealthData] = useState<ExtendedUserHealthData | null>(null);
  const [editData, setEditData] = useState<EditUserData>({
    name: '',
    email: '',
    approved: false
  });
  const [selectedUserEmail, setSelectedUserEmail] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error' | 'info' | 'warning',
  });
  const [forceUpdate, setForceUpdate] = useState(0);
  const [showSyncDialog, setShowSyncDialog] = useState(false);
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  useEffect(() => {
    console.error('UserManagement mounted');
  }, []);

  useEffect(() => {
    setForceUpdate(prev => prev + 1);
  }, []);

  const fetchUsers = async () => {
    console.error('DEBUG: Starting to fetch users...');
    try {
      console.error('DEBUG: Inside fetchUsers function');
      const fetchedUsers: User[] = [];
      const usersRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersRef);
      
      querySnapshot.forEach((doc) => {
        const userData = doc.data();
        console.error('Processing user document:', doc.id, userData);
        
        const isApproved = userData.approved === true;
        const displayName = userData.personalInfo?.firstName 
          ? `${userData.personalInfo.firstName} ${userData.personalInfo.lastName || ''}`.trim()
          : userData.name || '';
          
        fetchedUsers.push({
          id: doc.id,  
          email: userData.email || '',
          displayName: displayName,
          role: userData.role || 'user',
          status: isApproved ? 'active' : 'inactive',
          approved: isApproved,
          createdAt: userData.personalInfo?.joinDate 
            ? new Date(userData.personalInfo.joinDate)
            : new Date(),
          personalInfo: userData.personalInfo || {},
          memberId: userData.memberId || '',
          healthSummary: userData.healthData?.healthSummary || {
            keyFindings: [],
            recommendations: [],
            criticalPoints: []
          }
        });
      });
      
      console.error('DEBUG: Users fetched successfully:', fetchedUsers.length);
      setUsers(fetchedUsers);
      setError(null);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError('Failed to fetch users. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleUserSelect = (userId: string) => {
    console.error('handleUserSelect called with userId:', userId);
    setSelectedUserId(prevId => prevId === userId ? null : userId);
  };

  const handleDeleteUser = async () => {
    if (userToDelete) {
      try {
        console.error('Deleting user:', userToDelete.id);
        const result = await deleteUser(userToDelete.id);
        
        if (result.success) {
          await fetchUsers();
          setDeleteDialogOpen(false);
          setUserToDelete(null);
          setError(null);
          setSnackbar({
            open: true,
            message: 'User deleted successfully',
            severity: 'success'
          });
        } else {
          throw new Error(result.message);
        }
      } catch (err: any) {
        console.error('Error deleting user:', err);
        setError(err.message || 'Failed to delete user. Please try again later.');
        setSnackbar({
          open: true,
          message: err.message || 'Failed to delete user',
          severity: 'error'
        });
      }
    }
  };

  const handleEditClick = (user: User) => {
    setUserToEdit(user);
    setEditData({
      name: user.displayName || '',
      email: user.email,
      approved: user.approved || false
    });
    setEditDialogOpen(true);
  };

  const handleEditHealthClick = async (user: User) => {
    try {
      const userRef = doc(db, 'users', user.id);
      const userDoc = await getDocs(collection(db, 'users'));
      const userData = userDoc.docs.find(doc => doc.id === user.id)?.data();
      
      if (userData && userData.healthData) {
        setUserToEdit(user);
        setUserHealthData(userData.healthData);
        setEditHealthDialogOpen(true);
      } else {
        // Initialize empty health data structure
        const emptyHealthData: ExtendedUserHealthData = {
          userId: user.id,
          email: user.email,
          name: user.displayName || '',
          lastUpdated: new Date().toISOString(),
          personalInfo: {
            firstName: '',
            lastName: '',
            age: 0,
            gender: '',
            height: '0',
            weight: '0',
            bloodType: '',
            joinDate: new Date().toISOString()
          },
          membership: {
            type: '',
            status: 'active',
            startDate: new Date().toISOString(),
            expiryDate: new Date().toISOString(),
            daysRemaining: 30,
            nextLabTest: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
            nextMonthlyBatch: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
          },
          notifications: [],
          agebackScore: {
            overall: 0,
            biological: 0,
            lifestyle: 0,
            environmental: 0,
            metabolic: 0,
            improvement: 0,
            chronological: 0
          },
          agebackScores: {
            score2020: { high: 0, low: 0 },
            score2021: { high: 0, low: 0 },
            score2022: { high: 0, low: 0 },
            score2023: { high: 0, low: 0 },
            score2024: { high: 0, low: 0 },
            score2025: { high: 0, low: 0 },
            score2026: { high: 0, low: 0 }
          },
          agebackTrend: {
            labels: [],
            datasets: []
          },
          healthRisks: {
            shortTerm: [],
            midTerm: [],
            longTerm: []
          },
          neurochemicals: {
            dopamine: 60,
            serotonin: 60,
            gaba: 60,
            acetylcholine: 60,
            norepinephrine: 60,
            endorphins: 60,
            melatonin: 60,
            glutamate: 60
          },
          bloodPanel: {
            hemoglobin: 60,
            whiteBloodCells: 60,
            platelets: 60,
            redBloodCells: 60,
            hematocrit: 60,
            mcv: 60,
            mch: 60,
            mchc: 60
          },
          hormonePanel: {
            // Growth, Development, and Maturation
            growthHormone: 60,
            igf1: 60,
            thyroidHormones: 60,

            // Metabolic Regulation
            insulin: 60,
            glucagon: 60,
            leptin: 60,

            // Stress and Emergency Response
            cortisol: 60,
            adrenaline: 60,
            noradrenaline: 60,

            // Reproductive Hormones
            testosterone: 60,
            estrogen: 60,
            progesterone: 60,

            // Homeostasis and Maintenance
            parathyroidHormone: 60,
            aldosterone: 60,
            vasopressin: 60,

            // Local and Specialized Functions
            melatonin: 60,
            calcitonin: 60,
            somatostatin: 60,

            // Legacy or additional hormones
            dhea: 60
          },
          toxicityLevels: {
            hepatotoxicity: 60,
            immunotoxicity: 60,
            nephrotoxicity: 60,
            neurotoxicity: 60,
            reproductiveToxicity: 60,
            respiratoryToxicity: 60
          },
          epigeneticsProfile: {
            methylation: 60,
            telomeres: 60,
            histones: 60,
            chromatin: 60
          },
          metabolicSystems: {
            digestive: 60,
            immune: 60,
            nervous: 60,
            circulatory: 60,
            respiratory: 60,
            endocrine: 60,
            lymphatic: 60,
            musculoskeletal: 60
          },
          viMiAmEn: {
            vitamins: {
              A: 60,
              B: 60,
              C: 60,
              D: 60,
              E: 60,
              K: 60
            },
            minerals: {
              calcium: 60,
              magnesium: 60,
              zinc: 60,
              iron: 60,
              potassium: 60
            },
            aminoAcids: {
              leucine: 60,
              isoleucine: 60,
              valine: 60,
              lysine: 60,
              methionine: 60
            },
            enzymes: {
              digestive: 60,
              metabolic: 60,
              antioxidant: 60,
              repair: 60
            }
          },
          labResults: {
            cholesterol: 0,
            bloodPressureSystolic: 0,
            bloodPressureDiastolic: 0,
            bloodSugar: 0,
          },
        };
        setUserToEdit(user);
        setUserHealthData(emptyHealthData);
        setEditHealthDialogOpen(true);
      }
    } catch (err) {
      console.error('Error fetching user health data:', err);
      setError('Failed to fetch user health data. Please try again later.');
    }
  };

  const handleEditSave = async (updatedData: Partial<User>) => {
    try {
      if (!userToEdit) return;

      const userRef = doc(db, 'users', userToEdit.id);
      await updateDoc(userRef, {
        personalInfo: updatedData.personalInfo,
        email: updatedData.email,
        memberId: updatedData.memberId,
        approved: updatedData.approved,
        displayName: updatedData.displayName,
        lastUpdated: serverTimestamp(),
      });

      await fetchUsers();
      setEditDialogOpen(false);
      setUserToEdit(null);
      setSnackbar({
        open: true,
        message: 'User updated successfully',
        severity: 'success'
      });
    } catch (err) {
      console.error('Error updating user:', err);
      setSnackbar({
        open: true,
        message: 'Failed to update user',
        severity: 'error'
      });
    }
  };

  const handleHealthDataSave = async (updatedData: ExtendedUserHealthData) => {
    if (!userToEdit) return;

    try {
      const userRef = doc(db, 'users', userToEdit.id);
      
      // Merge the updated data with required fields
      const completeHealthData = {
        ...updatedData,
        lastUpdated: new Date().toISOString(),
      };
      
      // Structure the data properly for Firestore update
      const firestoreData = {
        'healthData': completeHealthData
      };
      
      await updateDoc(userRef, firestoreData);
      setUserHealthData(updatedData);
      setEditHealthDialogOpen(false);
      setError(null);
    } catch (err) {
      console.error('Error updating user health data:', err);
      setError('Failed to update user health data. Please try again later.');
      throw err;
    }
  };

  const handleMembershipClick = (user: User) => {
    console.log('Opening membership dialog for user:', user);
    setSelectedUserId(user.id);
    setMembershipDialogOpen(true);
  };

  const handleMembershipUpdate = async (membership: Membership) => {
    if (!selectedUserId) {
      console.error('No user selected');
      return;
    }

    try {
      console.log('Updating membership for user:', selectedUserId);
      const userRef = doc(db, 'users', selectedUserId);
      
      // Calculate days remaining
      const today = new Date();
      const expiryDate = new Date(membership.expiryDate);
      const daysRemaining = Math.max(0, Math.ceil((expiryDate.getTime() - today.getTime()) / (1000 * 60 * 60 * 24)));
      
      // Create the membership update
      const membershipUpdate = {
        status: membership.status || 'pending',
        startDate: new Date(membership.startDate).toISOString(),
        expiryDate: new Date(membership.expiryDate).toISOString(),
        nextLabTest: new Date(membership.nextLabTest).toISOString(),
        nextMonthlyBatch: new Date(membership.nextMonthlyBatch).toISOString(),
        type: membership.type || 'Basic',
        daysRemaining: daysRemaining
      };
      
      console.log('Updating membership with:', membershipUpdate);
      
      // Update Firestore with the new membership data directly at the top level
      await updateDoc(userRef, {
        'membership': membershipUpdate,
        'updatedAt': serverTimestamp()
      });
      
      console.log('Membership updated successfully:', membershipUpdate);
      setMembershipDialogOpen(false);
      
      // Show success message
      setSnackbar({
        open: true,
        message: 'Membership updated successfully',
        severity: 'success'
      });
      
      // Refresh the users list
      await fetchUsers();
    } catch (error) {
      console.error('Error updating membership:', error);
      setSnackbar({
        open: true,
        message: `Failed to update membership: ${error instanceof Error ? error.message : 'Unknown error'}`,
        severity: 'error'
      });
    }
  };

  const handleHealthSummaryClick = async (user: User) => {
    if (!user || !user.id) {
      console.error('Invalid user data');
      setSnackbar({
        open: true,
        message: 'Cannot open health summary: Invalid user data',
        severity: 'error'
      });
      return;
    }
    
    console.log('Health summary click - userId:', user.id);
    setSelectedUserId(user.id);
    setHealthSummaryDialogOpen(true);
  };

  const handleHealthSummaryUpdate = async (healthSummary: HealthSummary) => {
    if (!selectedUserId) {
      console.error('Missing userId');
      setSnackbar({
        open: true,
        message: 'Failed to update health summary: Missing user ID',
        severity: 'error',
      });
      return;
    }

    try {
      console.log('UserManagement - Input health summary:', JSON.stringify(healthSummary, null, 2));

      // Create a reference to the specific user document
      const userRef = doc(db, 'users', selectedUserId);

      // Get current user data to verify the update
      const userDoc = await getDoc(userRef);
      if (!userDoc.exists()) {
        throw new Error('User document not found');
      }

      // Get the current health summary if it exists
      const currentData = userDoc.data();
      const currentHealthSummary = currentData?.healthData?.healthSummary || {};

      // Merge the new health summary with existing data
      const mergedHealthSummary = {
        ...currentHealthSummary,
        keyFindings: healthSummary.keyFindings,
        recommendations: healthSummary.recommendations,
        criticalPoints: healthSummary.criticalPoints,
        lastUpdated: new Date().toISOString()
      };

      // Create the update data
      const updateData = {
        'healthData.healthSummary': mergedHealthSummary
      };

      console.log('UserManagement - Sending update to Firestore:', JSON.stringify(updateData, null, 2));

      // Update the document with the merged health summary
      await updateDoc(userRef, updateData);

      // Verify the update was successful
      const updatedDoc = await getDoc(userRef);
      const updatedData = updatedDoc.data();
      console.log('UserManagement - Updated document:', JSON.stringify(updatedData, null, 2));

      if (!updatedData?.healthData?.healthSummary) {
        throw new Error('Health summary update failed - data not saved');
      }

      setHealthSummaryDialogOpen(false);
      
      setSnackbar({
        open: true,
        message: 'Health summary updated successfully',
        severity: 'success',
      });

      // Refresh the users list
      await fetchUsers();
    } catch (error) {
      console.error('Error updating health summary:', error);
      setSnackbar({
        open: true,
        message: `Failed to update health summary: ${error instanceof Error ? error.message : 'Unknown error'}`,
        severity: 'error',
      });
      throw error;
    }
  };

  const handleNotificationClick = (user: User) => {
    console.error('Opening notification dialog for user:', user.id);
    setSelectedUserId(user.id);
    setUserToEdit(user);
    setNotificationDialogOpen(true);
  };

  const handleResetPassword = (email: string) => {
    setSelectedUserEmail(email);
    setResetPasswordDialogOpen(true);
  };

  const handlePasswordReset = () => {
    setResetPasswordDialogOpen(false);
    setSnackbar({
      open: true,
      message: 'Password reset email sent successfully',
      severity: 'success',
    });
  };

  const renderActionButtons = (user: User) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: 1,
          '& .MuiButton-root': {
            minWidth: 'auto',
            px: 1.5,
            py: 0.5,
            fontSize: '0.75rem',
            textTransform: 'none',
          }
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleEditClick(user)}
        >
          Edit Profile
        </Button>

        <Button
          variant="contained"
          color="info"
          onClick={() => handleEditHealthClick(user)}
        >
          Health Data
        </Button>

        <Button
          variant="contained"
          color="warning"
          onClick={() => handleNotificationClick(user)}
        >
          Notify
        </Button>

        <Button
          variant="contained"
          color="success"
          onClick={() => handleMembershipClick(user)}
        >
          Membership
        </Button>

        <Button
          variant="contained"
          sx={{ 
            bgcolor: '#9c27b0', 
            '&:hover': { bgcolor: '#7b1fa2' } 
          }}
          onClick={() => handleHealthSummaryClick(user)}
        >
          Summary
        </Button>

        <Button
          variant="contained"
          sx={{ 
            bgcolor: '#f57c00', 
            '&:hover': { bgcolor: '#ef6c00' },
            display: 'inline-flex',
            alignItems: 'center',
            gap: '4px'
          }}
          onClick={() => handleResetPassword(user.email)}
        >
          <LockResetIcon sx={{ fontSize: 16 }} />
          Reset Password
        </Button>

        <Button
          variant="contained"
          color="error"
          onClick={() => { setUserToDelete(user); setDeleteDialogOpen(true); }}
        >
          Delete
        </Button>
      </Box>
    );
  };

  const renderTable = () => {
    console.error('DEBUG: Rendering table with users:', users.length);
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow
                key={`${user.id}-${forceUpdate}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{user.displayName || 'N/A'}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                  <Chip
                    label={user.status}
                    color={user.status === 'active' ? 'success' : 'default'}
                  />
                </TableCell>
                <TableCell sx={{ 
                  padding: '16px',
                  minWidth: '500px',
                  position: 'relative',
                  overflow: 'visible',
                  zIndex: 1
                }}>
                  {renderActionButtons(user)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  console.error('DEBUG: UserManagement component rendering END');
  
  if (loading) {
    console.error('DEBUG: Showing loading state');
    return (
      <Box display="flex" justifyContent="center" alignItems="center" p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box p={3}>
      {error && (
        <Box sx={{ mb: 2 }}>
          <Chip label={error} color="error" />
        </Box>
      )}
      
      <Stack 
        direction="row" 
        spacing={2} 
        sx={{ 
          mb: 3,
          justifyContent: 'flex-end',
          '& .MuiButton-root': {
            minWidth: 'fit-content'
          }
        }}
      >
        <Button
          variant="contained"
          color="info"
          onClick={() => setShowSyncDialog(true)}
          startIcon={<LockResetIcon />}
        >
          Sync with Auth
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowAddUserDialog(true)}
          startIcon={<AddIcon />}
        >
          Add User
        </Button>
      </Stack>

      <Typography variant="h5" sx={{ mb: 3 }}>
        User Management
      </Typography>

      {renderTable()}

      {/* Notification Dialog */}
      {selectedUserId && (
        <Dialog
          open={!!selectedUserId && notificationDialogOpen}
          onClose={() => setSelectedUserId(null)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Send Notification</DialogTitle>
          <DialogContent>
            <SendNotification userId={selectedUserId} />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSelectedUserId(null)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Delete Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteUser} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reset Password Dialog */}
      <ResetPasswordDialog
        open={resetPasswordDialogOpen}
        onClose={() => setResetPasswordDialogOpen(false)}
        userEmail={selectedUserEmail}
        onPasswordReset={handlePasswordReset}
      />

      {/* Edit User Dialog */}
      <EditUserDialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        onSave={handleEditSave}
        user={userToEdit}
      />

      {/* Edit Health Data Dialog */}
      {userHealthData && (
        <EditUserHealthDialog
          open={editHealthDialogOpen}
          onClose={() => {
            setEditHealthDialogOpen(false);
            setUserHealthData(null);
          }}
          onSave={handleHealthDataSave}
          userData={userHealthData}
        />
      )}

      {/* Membership Dialog */}
      <EditMembershipDialog
        open={membershipDialogOpen}
        onClose={() => setMembershipDialogOpen(false)}
        membership={users.find(u => u.id === selectedUserId)?.membership}
        onSave={handleMembershipUpdate}
        userId={selectedUserId || ''}
      />

      {/* Health Summary Dialog */}
      <EditHealthSummaryDialog
        open={healthSummaryDialogOpen}
        onClose={() => setHealthSummaryDialogOpen(false)}
        healthSummary={users.find(u => u.id === selectedUserId)?.healthSummary}
        onSave={async (healthSummary) => {
          if (!selectedUserId) {
            setSnackbar({
              open: true,
              message: 'Failed to update health summary: No user selected',
              severity: 'error',
            });
            return Promise.reject(new Error('No user selected'));
          }
          return handleHealthSummaryUpdate(healthSummary);
        }}
        userId={selectedUserId || ''}
      />

      <AddUserDialog
        open={showAddUserDialog}
        onClose={() => setShowAddUserDialog(false)}
        onSuccess={() => {
          setShowAddUserDialog(false);
          fetchUsers();
        }}
      />

      <UserSyncDialog 
        open={showSyncDialog} 
        onClose={() => {
          setShowSyncDialog(false);
          fetchUsers(); // Refresh the user list after sync
        }}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default UserManagement;

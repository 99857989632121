import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Paper,
  alpha,
  CircularProgress,
  useMediaQuery,
} from '@mui/material';
import {
  TrendingUp as TrendingUpIcon,
  TrendingDown as TrendingDownIcon,
  Info as InfoIcon,
  Timeline as TimelineIcon,
  Speed as SpeedIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

interface AgebackScoreProps {
  overall: number | null;
  chronological: number | null;
  highestEver: number | null;
}

const AgebackScoreCard: React.FC<AgebackScoreProps> = ({
  overall,
  chronological,
  highestEver,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Debug logging
  console.log('==== DEBUG: AgebackScoreCard Props ====');
  console.log('Overall Score:', overall);
  console.log('Chronological Score:', chronological);
  console.log('Overall Score Type:', typeof overall);
  console.log('Overall Score Value:', overall === null ? 'null' : overall);
  console.log('Chronological Score Value:', chronological === null ? 'null' : chronological);
  console.log('===================================');

  const improvement = overall !== null && chronological !== null 
    ? overall - chronological
    : null;
  const isImprovement = improvement !== null && improvement > 0;
  const scorePercentage = overall !== null ? Math.min(Math.max((overall / 100) * 100, 0), 100) : 0;

  const getScoreColor = (score: number | null) => {
    if (score === null) return theme.palette.grey[400];
    if (score >= 80) return theme.palette.success.main;
    if (score >= 60) return theme.palette.warning.main;
    return theme.palette.error.main;
  };

  const scoreColor = getScoreColor(overall);

  return (
    <Card
      sx={{
        height: '100%',
        borderRadius: 2,
        p: { xs: 2, sm: 3 },
        boxShadow: theme.shadows[3],
        '&:hover': {
          boxShadow: theme.shadows[6],
          transform: 'translateY(-2px)',
          transition: 'all 0.3s',
        },
      }}
    >
      <CardContent sx={{ height: '100%', pt: 2.5, px: 2.5, pb: '16px !important' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 0.5 }}>
          <TimelineIcon sx={{ color: theme.palette.primary.main }} />
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: '1rem', sm: '1.1rem' },
              fontWeight: 600,
              color: theme.palette.text.primary,
              lineHeight: 1.5,
            }}
          >
            Ageback Score
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Your Ageback® score represents the overall valuation of your health metrics">
            <IconButton size="small" sx={{ color: theme.palette.primary.main }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        {/* Score Circle */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            mb: { xs: 2, sm: 3 },
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 2,
            }}
          >
            <CircularProgress
              variant="determinate"
              value={scorePercentage}
              size={isMobile ? 120 : 160}
              thickness={4}
              sx={{
                color: scoreColor,
                '& .MuiCircularProgress-circle': {
                  strokeLinecap: 'round',
                },
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant={isMobile ? "h4" : "h3"}
                component="div"
                color="text.primary"
                sx={{ fontWeight: 'bold', lineHeight: 1 }}
              >
                {overall !== null ? overall : '-'}
              </Typography>
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                sx={{ mt: 0.5 }}
              >
                out of 100
              </Typography>
            </Box>
          </Box>

          {/* Score Change Indicator */}
          {improvement !== null && (
            <Box
              sx={{
                display: 'inline-flex',
                alignItems: 'center',
                bgcolor: alpha(
                  isImprovement ? theme.palette.success.main : theme.palette.error.main,
                  0.1
                ),
                color: isImprovement ? theme.palette.success.main : theme.palette.error.main,
                px: 1.5,
                py: 0.5,
                borderRadius: 8,
              }}
            >
              {isImprovement ? (
                <TrendingUpIcon sx={{ fontSize: isMobile ? '1rem' : '1.25rem', mr: 0.5 }} />
              ) : (
                <TrendingDownIcon sx={{ fontSize: isMobile ? '1rem' : '1.25rem', mr: 0.5 }} />
              )}
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 'medium',
                  fontSize: isMobile ? '0.75rem' : '0.875rem',
                }}
              >
                {`${isImprovement ? '+' : ''}${Math.abs(improvement)} pts`}
              </Typography>
            </Box>
          )}
        </Box>

        {/* Additional Scores */}
        <Stack spacing={2}>
          {/* Chronological Score */}
          <Paper
            sx={{
              p: { xs: 1.5, sm: 2 },
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
              borderRadius: 2,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <TimelineIcon sx={{ 
                  color: theme.palette.text.secondary,
                  fontSize: isMobile ? '1.25rem' : '1.5rem',
                }} />
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
                >
                  Chronological Score
                </Typography>
              </Stack>
              <Typography 
                variant={isMobile ? "h6" : "h5"}
                sx={{ fontWeight: 'medium' }}
              >
                {chronological !== null ? chronological : '-'}
              </Typography>
            </Stack>
          </Paper>

          {/* Highest Ever Score */}
          <Paper
            sx={{
              p: { xs: 1.5, sm: 2 },
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
              borderRadius: 2,
            }}
          >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" spacing={1} alignItems="center">
                <StarIcon sx={{ 
                  color: theme.palette.warning.main,
                  fontSize: isMobile ? '1.25rem' : '1.5rem',
                }} />
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ fontSize: isMobile ? '0.875rem' : '1rem' }}
                >
                  Highest Ever
                </Typography>
              </Stack>
              <Typography 
                variant={isMobile ? "h6" : "h5"}
                sx={{ fontWeight: 'medium' }}
              >
                {highestEver !== null ? highestEver : '-'}
              </Typography>
            </Stack>
          </Paper>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AgebackScoreCard;

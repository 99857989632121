import React from 'react';
import {
  Box,
  Container,
  Typography,
  Paper,
  Divider,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import LegalPageHeader from '../../components/legal/LegalPageHeader';

const Licenses: React.FC = () => {
  const licenses = [
    {
      package: '@mui/material',
      version: '5.x',
      license: 'MIT',
      link: 'https://github.com/mui/material-ui/blob/master/LICENSE',
    },
    {
      package: 'React',
      version: '18.x',
      license: 'MIT',
      link: 'https://github.com/facebook/react/blob/main/LICENSE',
    },
    {
      package: 'Firebase',
      version: '9.x',
      license: 'Apache-2.0',
      link: 'https://github.com/firebase/firebase-js-sdk/blob/master/LICENSE',
    },
    {
      package: 'TypeScript',
      version: '4.x',
      license: 'Apache-2.0',
      link: 'https://github.com/microsoft/TypeScript/blob/main/LICENSE.txt',
    },
  ];

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper sx={{ p: 4 }}>
          <LegalPageHeader title="Licenses" />
          <Divider sx={{ my: 4 }} />

          <Typography variant="h3" component="h1" gutterBottom>
            Licenses
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" paragraph>
            Last updated: {new Date().toLocaleDateString()}
          </Typography>

          <Typography variant="h5" gutterBottom>
            Third-Party Software Licenses
          </Typography>
          <Typography paragraph>
            The Ageback Health Program uses various open-source software packages. We acknowledge and are grateful to these developers for their contributions to open source.
          </Typography>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Package</TableCell>
                  <TableCell>Version</TableCell>
                  <TableCell>License</TableCell>
                  <TableCell>License Text</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {licenses.map((item) => (
                  <TableRow key={item.package}>
                    <TableCell>{item.package}</TableCell>
                    <TableCell>{item.version}</TableCell>
                    <TableCell>{item.license}</TableCell>
                    <TableCell>
                      <Link href={item.link} target="_blank" rel="noopener noreferrer">
                        View License
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              Ageback Health Program License
            </Typography>
            <Typography paragraph>
              {new Date().getFullYear()} Ageback Health Program. All rights reserved.
            </Typography>
            <Typography paragraph>
              The Ageback Health Program software is proprietary and confidential. Unauthorized copying, modification, distribution, or use of this software is strictly prohibited.
            </Typography>
          </Box>

          <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
            Contact Information
          </Typography>
          <Typography paragraph>
            For any questions about licensing, please contact us at:
          </Typography>
          <Typography paragraph>
            Email: legal@ageback.com<br />
            Address: 123 Health Street, Suite 100<br />
            San Francisco, CA 94105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default Licenses;

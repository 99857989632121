import React from 'react';
import {
  Box,
  Container,
  Typography,
  useTheme,
  Grid,
  Stack,
  Divider,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Footer: React.FC = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  const linkStyle = {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
  };

  const footerSections = [
    {
      title: 'Company',
      links: [
        { name: 'About Us', href: '#' },
        { name: 'Contact', href: '#' },
      ],
    },
    {
      title: 'Legal',
      links: [
        { name: 'Privacy', href: '/legal/privacy' },
        { name: 'Terms', href: '/legal/terms' },
        { name: 'Cookie Policy', href: '/legal/cookie-policy' },
        { name: 'Licenses', href: '/legal/licenses' },
      ],
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.mode === 'light'
          ? theme.palette.grey[50]
          : theme.palette.background.paper,
        borderTop: `1px solid ${theme.palette.divider}`,
        mt: 'auto',
        py: { xs: 4, sm: 6 },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Logo and Description */}
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Box
                component="img"
                src="/bluelogo.png"
                alt="Ageback Logo"
                sx={{
                  height: 40,
                  width: 'auto',
                  mr: 2,
                  objectFit: 'contain',
                }}
              />
              <Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    background: `linear-gradient(120deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  Healthboard<sup style={{ fontSize: '0.5em', verticalAlign: 'super' }}>®</sup>
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.text.secondary,
                    fontWeight: 500,
                  }}
                >
                  by Ageback<sup style={{ fontSize: '0.7em', verticalAlign: 'super' }}>®</sup>
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mb: 2, maxWidth: 300 }}
            >
              Empowering individuals to take control of their health through
              comprehensive tracking and personalized insights.
            </Typography>
          </Grid>

          {/* Footer Sections */}
          {footerSections.map((section) => (
            <Grid item xs={12} sm={6} md={2} key={section.title}>
              <Typography
                variant="subtitle2"
                color="text.primary"
                sx={{ fontWeight: 600, mb: 2 }}
              >
                {section.title}
              </Typography>
              <Stack spacing={1}>
                {section.links.map((link) => (
                  <Box
                    key={link.name}
                    sx={{
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <RouterLink
                      to={link.href}
                      style={linkStyle}
                    >
                      <Typography variant="body2">
                        {link.name}
                      </Typography>
                    </RouterLink>
                  </Box>
                ))}
              </Stack>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ my: 4 }} />

        {/* Bottom Section */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: { xs: 'center', sm: 'left' } }}
          >
            {currentYear} Healthboard® by Ageback®. All rights reserved.
          </Typography>
          <Stack
            direction="row"
            spacing={3}
            divider={
              <Box
                component="span"
                sx={{
                  width: '4px',
                  height: '4px',
                  borderRadius: '50%',
                  backgroundColor: 'text.disabled',
                  alignSelf: 'center',
                }}
              />
            }
          >
            <Box sx={{ '&:hover': { color: 'primary.main' } }}>
              <RouterLink to="#" style={linkStyle}>
                <Typography variant="body2">Status</Typography>
              </RouterLink>
            </Box>
            <Box sx={{ '&:hover': { color: 'primary.main' } }}>
              <RouterLink to="#" style={linkStyle}>
                <Typography variant="body2">Security</Typography>
              </RouterLink>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;

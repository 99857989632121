import React from 'react';
import { Grid, Card, CardContent, Typography, Box, CircularProgress, useTheme } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import PeopleIcon from '@mui/icons-material/People';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EventIcon from '@mui/icons-material/Event';
import { alpha } from '@mui/material/styles';

interface MetricCardProps {
  title: string;
  value: string | number;
  change: number;
  icon: React.ReactNode;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, change, icon }) => {
  const theme = useTheme();
  const isPositive = change >= 0;

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <Box>
            <Typography color="textSecondary" gutterBottom variant="body2">
              {title}
            </Typography>
            <Typography variant="h4">{value}</Typography>
          </Box>
          <Box 
            sx={{ 
              backgroundColor: theme.palette.mode === 'dark' 
                ? alpha(theme.palette.primary.main, 0.1) 
                : alpha(theme.palette.primary.main, 0.1),
              borderRadius: 1,
              p: 1,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {icon}
          </Box>
        </Box>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          {isPositive ? (
            <TrendingUpIcon color="success" fontSize="small" />
          ) : (
            <TrendingDownIcon color="error" fontSize="small" />
          )}
          <Typography
            variant="body2"
            sx={{
              color: isPositive ? 'success.main' : 'error.main',
              ml: 1
            }}
          >
            {Math.abs(change)}%
          </Typography>
          <Typography variant="caption" sx={{ ml: 1 }}>
            Since last month
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

interface UserEngagementMetricsProps {
  metrics: {
    activeUsers: number;
    activeUsersChange: number;
    averageSessionTime: number;
    sessionTimeChange: number;
    completionRate: number;
    completionRateChange: number;
    totalAppointments: number;
    appointmentsChange: number;
  };
}

const UserEngagementMetrics: React.FC<UserEngagementMetricsProps> = ({ metrics }) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Active Users"
          value={metrics.activeUsers}
          change={metrics.activeUsersChange}
          icon={<PeopleIcon color="primary" />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Avg. Session Time"
          value={`${metrics.averageSessionTime} min`}
          change={metrics.sessionTimeChange}
          icon={<AccessTimeIcon color="primary" />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Program Completion"
          value={`${metrics.completionRate}%`}
          change={metrics.completionRateChange}
          icon={<TrendingUpIcon color="primary" />}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <MetricCard
          title="Total Appointments"
          value={metrics.totalAppointments}
          change={metrics.appointmentsChange}
          icon={<EventIcon color="primary" />}
        />
      </Grid>
    </Grid>
  );
};

export default UserEngagementMetrics;

import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Stack,
  Tooltip,
  IconButton,
  Chip,
  Paper,
  Divider,
} from '@mui/material';
import {
  Assessment as AssessmentIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
  Lightbulb as LightbulbIcon,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { alpha, useMediaQuery } from '@mui/material';
import { HealthSummary } from '../../../types/user';

interface SummaryCardProps {
  healthSummary: HealthSummary;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ healthSummary }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('SummaryCard rendering with data:', healthSummary);

  return (
    <Card sx={{
      height: '100%',
      minHeight: 240,
      borderRadius: 2,
      position: 'relative',
      overflow: 'visible',
      background: theme.palette.mode === 'dark'
        ? alpha(theme.palette.success.main, 0.1)
        : alpha(theme.palette.success.light, 0.1),
      '&:hover': {
        transform: 'translateY(-2px)',
        transition: 'all 0.3s'
      }
    }}>
      <CardContent sx={{ height: '100%', pt: 2.5, px: 2.5, pb: '16px !important' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 0.5 }}>
          <AssessmentIcon sx={{ color: theme.palette.primary.main }} />
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: '1rem', sm: '1.1rem' },
              fontWeight: 600,
              color: theme.palette.text.primary,
              lineHeight: 1.5,
            }}
          >
            Health Summary
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="Your health summary overview">
            <IconButton size="small" sx={{ color: theme.palette.primary.main }}>
              <InfoIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>

        <Stack spacing={2}>
          {/* Key Findings Section */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2,
              bgcolor: alpha(theme.palette.success.main, 0.05),
              borderRadius: 2
            }}
          >
            <Stack spacing={1}>
              <Box display="flex" alignItems="center">
                <LightbulbIcon sx={{ color: theme.palette.success.main, mr: 1 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Key Findings
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {healthSummary.keyFindings && healthSummary.keyFindings.length > 0 ? (
                  healthSummary.keyFindings.map((finding, index) => (
                    <Chip
                      key={index}
                      label={finding}
                      size="small"
                      sx={{
                        bgcolor: alpha(theme.palette.success.main, 0.1),
                        color: theme.palette.success.main,
                        '&:hover': { bgcolor: alpha(theme.palette.success.main, 0.2) }
                      }}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">No key findings available</Typography>
                )}
              </Box>
            </Stack>
          </Paper>

          {/* Recommendations Section */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2,
              bgcolor: alpha(theme.palette.info.main, 0.05),
              borderRadius: 2
            }}
          >
            <Stack spacing={1}>
              <Box display="flex" alignItems="center">
                <InfoIcon sx={{ color: theme.palette.info.main, mr: 1 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Recommendations
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {healthSummary.recommendations && healthSummary.recommendations.length > 0 ? (
                  healthSummary.recommendations.map((rec, index) => (
                    <Typography key={index} variant="body2" color="text.secondary">
                      • {rec}
                    </Typography>
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">No recommendations available</Typography>
                )}
              </Box>
            </Stack>
          </Paper>

          {/* Critical Points Section */}
          <Paper 
            elevation={0} 
            sx={{ 
              p: 2,
              bgcolor: alpha(theme.palette.warning.main, 0.05),
              borderRadius: 2
            }}
          >
            <Stack spacing={1}>
              <Box display="flex" alignItems="center">
                <WarningIcon sx={{ color: theme.palette.warning.main, mr: 1 }} />
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  Critical Points
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                {healthSummary.criticalPoints && healthSummary.criticalPoints.length > 0 ? (
                  healthSummary.criticalPoints.map((point, index) => (
                    <Chip
                      key={index}
                      label={`${point.category}: ${point.description}`}
                      size="small"
                      sx={{
                        bgcolor: point.severity === 'high' 
                          ? alpha(theme.palette.error.main, 0.1)
                          : point.severity === 'medium'
                          ? alpha(theme.palette.warning.main, 0.1)
                          : alpha(theme.palette.success.main, 0.1),
                        color: point.severity === 'high' 
                          ? theme.palette.error.main
                          : point.severity === 'medium'
                          ? theme.palette.warning.main
                          : theme.palette.success.main,
                        '&:hover': { 
                          bgcolor: point.severity === 'high' 
                            ? alpha(theme.palette.error.main, 0.2)
                            : point.severity === 'medium'
                            ? alpha(theme.palette.warning.main, 0.2)
                            : alpha(theme.palette.success.main, 0.2)
                        }
                      }}
                    />
                  ))
                ) : (
                  <Typography variant="body2" color="text.secondary">No critical points available</Typography>
                )}
              </Box>
            </Stack>
          </Paper>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default SummaryCard;
